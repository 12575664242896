import { Alert, AlertIcon, Flex, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import _ from 'lodash';
import { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useSearchParams } from 'react-router-dom';
import { useReservation } from '../contexts/ReservationContext';
import { formatTimestamp } from '../helpers/helpers';

import { getAgreements } from "../helpers/agreementService";

import { StaticDogAgreement } from "../components/confirmation/StaticDogAgreement";
import { StaticGuestAgreement } from "../components/confirmation/StaticGuestAgreement";

const Agreements = () => {
  const { reservationData, updateReservationData, setReservationContextValid, setReservationContextLoaded } = useReservation();
  const { pathname } = useLocation();
  const [isLoadingAgreements, setIsLoadingAgreements] = useState(false);
  const [agreements, setAgreements] = useState(null);
  const [resNumber, setResNumber] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const agreementApiKey = searchParams.get('apikey');


  useEffect(() => {
    if (pathname.includes("/agreements")) {
      const splitPath = pathname.split("/agreements/");
      if (splitPath.length === 2) {
        const reservationNumber = splitPath[1];

        setResNumber(reservationNumber);
        updateReservationData(reservationNumber);
  
        setIsLoadingAgreements(true);
  
        getAgreements(reservationNumber, searchParams.get('apikey'))
          .then(fetchedAgreements => {
            if (fetchedAgreements && fetchedAgreements.length > 0) {
              setAgreements(fetchedAgreements);
              //console.log("fetched agreements", fetchedAgreements);
            } else {
              //console.log('No agreements found for this reservation number.');
              //console.log(agreements.length)
              setAgreements([]);
            }
          })
          .catch(error => {
            console.error('Error fetching agreements:', error);
            setAgreements(null);
          })
          .finally(() => {
            setIsLoadingAgreements(false);
            
          });
      } else {
        setReservationContextValid(false);
        setReservationContextLoaded(true);
      }
    }
    
  }, [pathname, agreementApiKey]); // Only pathname is a dependency now

  const renderAgreementsTabs = (categoryAgreements) => (
    <Tabs variant="enclosed-colored" isLazy>
      <TabList>
        {categoryAgreements.map((agreement, index) => (
          <Tab key={index} fontSize={{base:'xs',md:'md'}} p={4}>
            {`${_.startCase(_.toLower(agreement.type))} Agreement ${formatTimestamp(agreement.signedAt)}`}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {categoryAgreements.map((agreement, index) => (
          <TabPanel key={index} px={0}>
            {agreement.type === 'guest' ?
              <StaticGuestAgreement reservationNumber={resNumber} agreementData={agreement} rebook={reservationData.rebook} />
              :
              <StaticDogAgreement reservationNumber={resNumber} agreementData={agreement} />
            }
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );

  
  // Render helper function to decide what to render
  const renderContent = () => {
    if (isLoadingAgreements) return <Spinner />;
  
    if (!resNumber || agreements === null) {
      return (
        <Flex py={8} mb={'100px'} fontSize={'20px'} w={'100%'}>
          <Alert w={'100%'} status='warning'>
            <AlertIcon />
            No Agreements Found. Please double check that you have the correct link and reservation number.
          </Alert>
        </Flex>
      );
    }
  
    if (Array.isArray(agreements) && agreements.length === 0 && reservationData) {
      return (
        <Flex py={8} mb={'100px'} fontSize={'20px'} w={'100%'}>
          <Alert w={'100%'} status='info'>
            <AlertIcon />
            No agreements have been signed for this reservation.
          </Alert>
        </Flex>
      );
    }
  
    // Find the most recent guest and dog agreements
    const mostRecentGuestAgreement = agreements.reduce((latest, agreement) => {
      return (agreement.type === 'guest' && (!latest || agreement.signedAt > latest.signedAt)) ? agreement : latest;
    }, null);
  
    const mostRecentDogAgreement = agreements.reduce((latest, agreement) => {
      return (agreement.type === 'dog' && (!latest || agreement.signedAt > latest.signedAt)) ? agreement : latest;
    }, null);
  
    const currentAgreements = [mostRecentGuestAgreement, mostRecentDogAgreement].filter(Boolean);
    const archivedAgreements = agreements.filter(a => a !== mostRecentGuestAgreement);
  
    
    return (reservationData &&
      <Tabs isFitted variant='soft' colorScheme={'dmNavy'} maxW={'calc(100vw - 32px)'}>
        <TabList>
          <Tab _selected={{ color: 'white', bg: 'dmOrange.500' }} 
            border='solid 1px' borderLeftRadius='8px' borderRightRadius={archivedAgreements.length > 0 ? '0px' : '8px'}
            borderColor={'dmOrange.500'} color={'dmOrange.500'}
            w={'50%'} maxW={'400px'}
          >
            Current Agreements
          </Tab>
          {archivedAgreements.length > 0 &&
            <Tab _selected={{ color: 'black', bg: 'dmNavy.100',  }} 
              border='solid 1px' borderRightRadius='8px' 
              borderColor={'dmNavy.100'}
              w={'50%'} maxW={'400px'}
            >
              Archived Agreements
            </Tab>
          }
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            {currentAgreements.length > 0 ? renderAgreementsTabs(currentAgreements) : <p>No current agreements.</p>}
          </TabPanel>
          {archivedAgreements.length > 0 &&
            <TabPanel px={0}>
              {archivedAgreements.length > 0 ? renderAgreementsTabs(archivedAgreements) : <p>No archived agreements.</p>}
            </TabPanel>
          }
        </TabPanels>
      </Tabs>
    );
  };

  return (
    <Flex
      position={"relative"}
      width={"full"}
      data-id={"listing"}
      p={{ base: 2, md: 5 }}
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      {/* Top Bar */}
      <Flex maxWidth='1124px' width={'100%'} p={2} textAlign={'left'} alignItems={'start'} flexDirection={'column'}>
        {renderContent()}
      </Flex>
    </Flex>
  );
  
}

export { Agreements };

