const config = {
  development: {
    apiEndpoint: "http://localhost:8080/rest/v1",
    clerkKey: "pk_test_Zmx5aW5nLXJvYmluLTkyLmNsZXJrLmFjY291bnRzLmRldiQ",
    homePage: "http://localhost:3000/confirmation/",
    signInUrl: "https://flying-robin-92.accounts.dev/sign-in",
    baseUrl: "http://localhost:3000",
    stripeKey:
      "pk_test_51OL4bOFuga4gi7gX3kbZ0P2kLHpZJz1TMkClES4AUvcQ0q7v3BP692SjflRTY9jZKiIhN7GcImDQuxZlOniVn1Cd00Jti8Um5Y",
    stripeSecret: "sk_test_51OL4bOFuga4gi7gXvrcqU86LTjZMFlE57KGrG8b7MGc6aQqIilNnXF1QC1dKqxDWTPeP2yVsnYzvAomCM4aXzH7B00SDeMdI6x",
    mapKey: "AIzaSyAH2xQVEEEVPM_w1G6IgqOHsmbJF0L-ihE",
  },
  staging: {
    apiEndpoint:
      "https://delmar-guest-backend-staging-w4wczl5jqa-ue.a.run.app/rest/v1",
    clerkKey: "pk_test_Zmx5aW5nLXJvYmluLTkyLmNsZXJrLmFjY291bnRzLmRldiQ",
    homePage: "http://book-staging.thisisdelmar.com/confirmation/",
    signInUrl: "https://flying-robin-92.accounts.dev/sign-in",
    baseUrl: "http://book-staging.thisisdelmar.com",
    stripeKey:
      "pk_test_51OL4bOFuga4gi7gX3kbZ0P2kLHpZJz1TMkClES4AUvcQ0q7v3BP692SjflRTY9jZKiIhN7GcImDQuxZlOniVn1Cd00Jti8Um5Y",
    mapKey: "AIzaSyAH2xQVEEEVPM_w1G6IgqOHsmbJF0L-ihE",
  },
  production: {
    apiEndpoint: "https://delmar-guest-backend-w4wczl5jqa-ue.a.run.app/rest/v1",
    clerkKey: "pk_live_Y2xlcmsudGhpc2lzZGVsbWFyLmNvbSQ",
    homePage: "http://book.thisisdelmar.com/confirmation/",
    signInUrl: "https://accounts.thisisdelmar.com/sign-in",
    baseUrl: "http://book.thisisdelmar.com",
    stripeKey:
      "pk_live_51OL4bOFuga4gi7gXAgIc1ppEgkxFIGkfN9WkNTnJFIOK5QNJhMudEAXTQRPomUS2Go4E3wCPHUOdc7TOQudtXmyv00w8BkaaQN",
    mapKey: "AIzaSyAH2xQVEEEVPM_w1G6IgqOHsmbJF0L-ihE",
  },
};

export default config[process.env.REACT_APP_ENV || "development"];
