import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import {
  Alert, AlertIcon, Box, Flex,
  IconButton, Image, Link,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
  Text, useDisclosure
} from "@chakra-ui/react";
import { differenceInHours } from "date-fns";
import { zonedTimeToUtc } from 'date-fns-tz';
import * as React from "react";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { formatTime, reformatDateString } from '../../helpers/helpers';
import pdfImage from '../../media/pdf.png';

type Props = {
  reservationData: any;
};

const CheckInInfo: React.FC<Props> = ({
  reservationData
}) => {

  console.log("CheckInInfo reservationData", reservationData);

  // Set Time Zone To Eastern Time
  const ET = 'America/New_York';
  // Calculation of Time To Check In for Display of Door Code / Lockbox Directions
  const checkinAt = zonedTimeToUtc(new Date(`${reservationData.checkinDate} ${reservationData.checkinTime}`), ET);
  const hoursToCheckIn = differenceInHours(checkinAt, new Date());
  // Formatting of Checkin and Checkout Times for Display on Reservation Widget
  let checkInTimeFormatted = formatTime(reservationData.checkinTime);
  let checkOutTimeFormatted = formatTime(reservationData.checkoutTime);

  // Formatting of Checkin and Checkout Dates for Display on Reservation Widget
  const formattedCheckinDateNoTimeZone = reformatDateString(reservationData.checkinDate);
  const formattedCheckoutDateNoTimeZone = reformatDateString(reservationData.checkoutDate);

  const houseData = reservationData['house']

  const [showDoorCode, setDoorCodeVisibility] = React.useState(hoursToCheckIn < 48); // Door Code Visibility

  const [modalTitle, setModalTitle] = React.useState("");

  function convertToArray(data: string | null): string[] {
    if (data === null || data === "") return [];
    return data.split(',');
  }
  
  let convertedHouseData = {
    parkingPhotos: convertToArray(houseData.parkingPhotos),
    accessPhotos: convertToArray(houseData.accessPhotos),
  }

  const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose } = useDisclosure();

  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
  // Filtering out PDFs while creating allImages array
  const allImages = [...convertedHouseData.parkingPhotos, ...convertedHouseData.accessPhotos].filter(image => !image.endsWith('.pdf'));

  const handleClickImage = (photo: string, title:string) => {
    const index = allImages.indexOf(photo);
    if (index !== -1) {
      setSelectedImageIndex(index);
      setModalTitle(title);
    }
    onImageOpen();
  }
  const handleNextImage = () => {
    setSelectedImageIndex((selectedImageIndex + 1) % allImages.length);
  }

  const handlePreviousImage = () => {
    setSelectedImageIndex((selectedImageIndex + allImages.length - 1) % allImages.length);
  }

  return (
    <Flex direction='column' alignItems={'center'} gap={3}>

      <Text fontSize={20} w={'100%'} maxW={{base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}>
        <b>Check-In:</b> {formattedCheckinDateNoTimeZone} {checkInTimeFormatted}
      </Text>
      <Text fontSize={20} w={'100%'} maxW={{base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}>
        <b>Check-Out:</b> {formattedCheckoutDateNoTimeZone} {checkOutTimeFormatted}
      </Text>

      {hoursToCheckIn > 0 &&
        <Alert status='info' fontSize={18} my={2} mx={3} mr={3} maxW={{base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}>
          <AlertIcon />
          Without exception we cannot support early check-ins, but we will call you if the home is ready prior to 4 PM.
        </Alert>
      }

      {/* Content Group */}
      <Flex direction='column' pb={2} w="100%" maxW={{base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}>
        <Box>
          <Text fontSize={20} fontWeight={'700'} py={1} textAlign={'left'}>
            Address
          </Text>
          <Flex alignItems="center" mb={6} gap={2}>
            <Box flex="1" textAlign="left">
              {houseData["address"] + ", MA"}
            </Box>
            <Flex alignItems={"center"}>
              <FaMapMarkerAlt color={"#e95037"} />
              <Link
                target={"_blank"}
                className="check-in-info-directions"
                href={
                  "https://www.google.com/maps/dir/?api=1&destination=" +
                  houseData["address"] +
                  ", MA"
                }
              >
                <Box pl={1} fontSize={15} color={"#e95037"} className="check-in-info-directions">
                  View Directions
                </Box>
              </Link>
            </Flex>
          </Flex>
        </Box>

        <Box>
          <Text fontSize={20} fontWeight={'700'} pb={1} textAlign={'left'}>
            Door Code / Lockbox Directions
          </Text>
          <Flex direction='column' mb={6}>
            <Box>
              {showDoorCode
                ? reservationData["guestAccessInstructions"] === 'null' ? ' ' :
                reservationData["guestAccessInstructions"]
                : "Exact entry instructions including door code will be provided 48 hours before check-in."}
            </Box>
            <Flex justifyContent={'flex-start'} w={'100%'} flexWrap={'wrap'}>
              {
                convertedHouseData.accessPhotos.map((photo, index) => {
                  if (photo.endsWith(".pdf")) {
                    return (
                      <Link key={index} href={photo} isExternal display="flex" alignItems="center" width={"100%"}>
                        <Image src={pdfImage} height="50px" mt={3} mr={3} />
                        <Text as="span" mt={3} mr={3} fontWeight={'bold'}>Access Instructions</Text>
                      </Link>
                    );
                  } else {
                    return (
                      <Image
                        key={index}
                        src={photo}
                        onClick={() => handleClickImage(photo, "Access Photos")}
                        boxSize="150px"
                        objectFit="cover"
                        mr={3}
                        mt={3}
                        cursor={'pointer'}
                      />
                    );
                  }
                })
              }
            </Flex>
          </Flex>
        </Box>

        <Box>
          <Text fontSize={20} fontWeight={'700'} pb={1} textAlign={'left'}>
            WiFi Instructions
          </Text>
          <Flex direction='column' mb={6}>
            <Box>{houseData["wifiTv"]}</Box>
          </Flex>
        </Box>

        <Box>
          <Text fontSize={20} fontWeight={'700'} pb={1} textAlign={'left'}>
            Parking Directions
          </Text>
          <Flex direction='column' mb={6}>
            <Box>{houseData["parking"]}</Box>
            <Flex justifyContent={'flex-start'} w={'100%'} flexWrap={'wrap'}>
              {
                convertedHouseData.parkingPhotos.map((photo, index) => {
                  if (photo.endsWith(".pdf")) {
                    return (
                      <Link key={index} href={photo} isExternal display="flex" alignItems="center" width={'100%'}>
                        <Image src={pdfImage} height="50px" mt={3} mr={3} />
                        <Text as="span" mt={3} mr={3} fontWeight={'bold'}>Parking Instructions</Text>
                      </Link>
                    );
                  } else {
                    return (
                      <Image
                        key={index}
                        src={photo}
                        onClick={() => handleClickImage(photo, "Parking Photos")}
                        boxSize="150px"
                        objectFit="cover"
                        mr={3}
                        mt={3}
                        cursor={'pointer'}
                      />
                    );
                  }
                })
              }
            </Flex>
          </Flex>
        </Box>



        

      </Flex>
      {/* End Content Group */}

      <Modal isOpen={isImageOpen} onClose={onImageClose}>
        <ModalOverlay />
        <ModalContent pb={{base:0,md:3}} maxW={{base:'100vw',md:'90vw'}} maxH={{base:'100vh',md:'90vh'}}>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody w={'auto'} maxW={{base:'100vw',md:'90vw'}} maxH={{base:'100vh',md:'90vh'}} px={{base:"1vw"}} pb={{base:"1vw"}}>

            <Image src={allImages[selectedImageIndex]} m={"0 auto"} maxW={{base:'98vw',md:'80vw'}} maxH={{base:'90vh',md:'80vh'}} />

            <Flex position="absolute" top="0" bottom="0" left="0" right="0" h={{base:"calc(100% - 40px)", md:"calc(100% - 72px)"}} my={"36px"} alignItems="center" justifyContent="space-between">
              <Flex direction="column" justify="center" alignItems='center' onClick={handlePreviousImage} h={{base:"90%"}} w={"60px"} _hover={{backgroundColor:'#00000010'}} cursor={'pointer'} my={"36px"}>
                <IconButton
                  aria-label="Next image"
                  icon={<ArrowLeftIcon />}
                  size="lg"
                  isRound
                  color={{base:'white',md:'black'}}
                  bgColor={'#00000000'}
                  _hover={{backgroundColor:'#00000000'}}
                />
              </Flex>
              <Flex direction="column" justify="center" alignItems='center' onClick={handleNextImage} h={{base:"90%"}} w={"60px"} _hover={{backgroundColor:'#00000010'}} cursor={'pointer'} my={"36px"}>
                <IconButton
                  aria-label="Next image"
                  icon={<ArrowRightIcon />}
                  size="2xl"
                  color={{base:'white',md:'black'}}
                  bgColor={'#00000000'}
                  _hover={{backgroundColor:'#00000000'}}
                />
              </Flex>
            </Flex>

          </ModalBody>
        </ModalContent>
      </Modal>

    </Flex>
  );
};

export { CheckInInfo };

