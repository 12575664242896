import {
  Pagination as ArkPagination,
  PaginationProps as ArkPaginationProps,
  PaginationNextTrigger,
  PaginationPrevTrigger,
} from '@ark-ui/react';
import {
  Button,
  Center,
  List,
  ListItem,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { SearchResultsContext } from '../contexts/SearchResultsContext';

export type PaginationProps = Omit<ArkPaginationProps, 'children'> & {
  designtype?: string; // Assuming designType is a string, adjust as necessary
};

const Pagination = (props: PaginationProps) => {
  const { bufferLoading } = React.useContext(SearchResultsContext);

  const { designtype } = props;

  return (
    designtype === "Bottom" ?
    <ArkPagination {...props}>
      {({ pages, page }) => (
        <List gap="1" display="flex" justifyContent="space-between" pt={2}>
          <ListItem>
            <PaginationPrevTrigger asChild>
              <Button variant="primary">
                <FiArrowLeft />
              </Button>
            </PaginationPrevTrigger>
          </ListItem>
          <ListItem as={Center}>
            <Text fontWeight="medium" color="fg.emphasized" fontSize={'md'}>
              Page {page} of {Math.ceil(props.count / props.pageSize)}
            </Text>
          </ListItem>
          <ListItem>
            <PaginationNextTrigger asChild>
              <Button isLoading={bufferLoading} variant="secondary">
                <FiArrowRight />
              </Button>
            </PaginationNextTrigger>
          </ListItem>
        </List>
      )}
    </ArkPagination>
    :
    <ArkPagination {...props}>
      {({ pages, page }) => (
        <List gap="0" display="flex" justifyContent={'center'}>
          <ListItem>
            <PaginationPrevTrigger asChild>
              <Button variant="primary" px={0}>
                <FiArrowLeft size={'20px'} />
              </Button>
            </PaginationPrevTrigger>
          </ListItem>
          <ListItem as={Center}>
            <Text fontWeight="medium" color="fg.emphasized" fontSize={'md'} whiteSpace={'nowrap'}>
            {page} / {Math.ceil(props.count / props.pageSize)}
            </Text>
          </ListItem>
          <ListItem>
            <PaginationNextTrigger asChild>
              <Button isLoading={bufferLoading} variant="secondary" px={0}>
                <FiArrowRight size={'20px'} />
              </Button>
            </PaginationNextTrigger>
          </ListItem>
        </List>
      )}
    </ArkPagination>
  )
}

export default Pagination;
