import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  colors: {
    dmOrange: {
      50: '#FDEEEB', // Lightest shade
      100: '#FBDCD7',
      200: '#F6B9AF',
      300: '#F29687',
      400: '#ED735F',
      500: '#e95037',
      600: '#BA402C',
      700: '#8C3021',
      800: '#5D2016',
      900: '#2F100B', // Darkest shade
      dmOrange: "#e95037",
    },
    dmNavy: {
      50: '#E7E9EB', // Lightest shade
      100: '#CFD3D7',
      200: '#9FA7AF',
      300: '#6F7A88',
      400: '#3F4E60',
      500: '#0f2238',
      600: '#0C1B2D',
      700: '#091422',
      800: '#060E16',
      900: '#03070B', // Darkest shade
      dmNavy: "#0f2238",
    },
  },
  shadows: {
    dmShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
  },
  breakpoints: {
    base: '0em', // 0px
    sm: '30em', // ~480px. em is a relative unit and is dependant on the font size.
    md: '48em', // ~768px
    lg: '62em', // ~992px
    xl: '80em', // ~1280px
    '2xl': '96em', // ~1536px
    '3xl': '140em', // ~2240px
    '4xl': '176em', // ~2816px
  }
  // You can also extend other parts of the theme here
});

export default customTheme;
