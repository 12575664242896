import {
  Box,
  Button,
  Flex,
  Link,
  SimpleGrid,
  Text,
  Textarea, useToast
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as React from "react";
import { eventBus } from "../../helpers/EventBus";
import { handleServiceRequest } from "../../helpers/guestportalfetch";

type Props = {
  contactOwner: any;
  userEmail: string;
  onPopoverClose: Function;
  houseNumber?: string;
  reservationNumber?: string;
  onReportedDamage: () => void;
};

const ReportDamage: React.FC<Props> = ({
  contactOwner,
  userEmail,
  onPopoverClose,
  houseNumber: propHouseNumber = "",
  reservationNumber: propReservationNumber = "",
  onReportedDamage
}) => {

  const [formType, setFormType] = React.useState("damage");
  const [houseNumber, setHouseNumber] = React.useState(propHouseNumber);
  const [reservationNumber, setReservationNumber] = React.useState(propReservationNumber);
  const [placeholderText, setPlaceholderText] = React.useState("EX: The back door has a large dent.");
  const [submitted, setSubmitted] = React.useState(false);

  const toast = useToast()

  eventBus.on("houseData", (data) => {
    setHouseNumber(data.detail.houseNumber);
    setReservationNumber(data.detail.reservationNumber)
    //console.log("Event Bus Reservation Number:",data.detail.reservationNumber)
  });

  function closeForm() {
    onReportedDamage();
  }
  
  const rebuildData = (values) => {
    let formData = new FormData();
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });
    return formData;
  };

  return (
    <Box>
        <Box
          id="services-form-open"
          className="services-form"
          borderRadius={"8px"}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"center"}
            width={"100%"}
            justifyContent={"center"}
            pt={0}
            textColor="black"
          >
            <SimpleGrid
              w={{ base: "100%" }}
              columns={{ base: 1 }}
              bg={"white"}
              className="boxy"
            >
                <Flex
                  flexDirection={{ base: "column" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  bg={{ base: "white" }}
                  py={{ base: 0 }}
                  my={{ base: 4 }}
                >
                  <Flex
                    width={"100%"}
                    alignSelf={"left"}
                    alignItems={"center"}
                    pt={0}
                  >
                    <Link onClick={closeForm}>
                      <Box textAlign={"left"} fontSize={{ base: "2xl" }}>
                        Report Damage
                      </Box>
                    </Link>
                  </Flex>
                  <Box
                    textAlign={"left"}
                    pt={{ base: 4 }}
                    pb={{ base: 0 }}
                    fontSize={{ base: "md" }}
                    width="100%"
                  >
                    Provide a description of the damage below.
                  </Box>
                </Flex>

              <Flex py={{ base: 0 }} bg={"white"}>
                <Formik
                  initialValues={{
                    description: "",
                    urgency: "",
                    file: "",
                    formType: formType,
                    houseNumber: houseNumber,
                    reservationNumber: reservationNumber
                  }}
                  validate={values => {
                    const errors:any = {};
                    if (!values.description) {
                      errors.description = 'Required field.';
                    }
                    if (!values.urgency && formType === 'service') {
                      errors.urgency = 'Required field.';
                    }
                    // Add more validation logic here if needed
                    return errors;
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    values.formType = formType;
                    values.houseNumber = houseNumber;
                    values.reservationNumber = reservationNumber;
                    const data = rebuildData(values);
                    //console.log("Data",values)
                    handleServiceRequest(data).then((response) => {
                      setSubmitted(true);
                      toast({
                        title: 'Thank you for submitting.',
                        description: "We'll get back to you shortly.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                      });
                      onPopoverClose();
                      closeForm();
                      resetForm();
                    });
                  }}
                >
                  {({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                    <form onSubmit={handleSubmit} className="guest-form">
                        <Flex
                          flexDirection={"column"}
                          justifyContent={"space-between"}
                          minH={"300px"}
                        >
                          <Textarea
                            id="description"
                            name="description"
                            variant="outline"
                            bg={"whiteAlpha.700"}
                            size="lg"
                            height={{ base: "100px" }}
                            maxLength={300}
                            placeholder={placeholderText}
                            onChange={handleChange}
                            value={values.description}
                          />
                          <Text color='red'>{errors.description && touched.description && errors.description}</Text>
                          
                          <Text color='red' pt={1}>{errors.urgency && touched.urgency && errors.urgency}</Text>
                          <Flex alignItems={"left "} pt={0} direction={'column'}>
                            <Box fontSize={14} pr={3} textAlign={"left"}>
                              Please upload a photo or a 5-second video. Maximum
                              file size is 32MB.
                            </Box>
                            <Box pt={2}>
                              <input
                                type="file"
                                accept="image/*,video/*"
                                name="file"
                                id="file"
                                className="input-blue-button"
                                onChange={(event) => {
                                  //console.log(event.currentTarget.files)
                                  setFieldValue("file", event.currentTarget.files[0]);
                                }}
                              />
                            </Box>

                          </Flex>

                          <Flex
                            justifyContent={{base:"start",md:"end"}}
                            pt={{ base: 8 }}
                            pb={0}
                            w={"100%"}
                            flexDirection={{base:"row-reverse",md:"row"}}
                          >
                            <Button
                              className="dm-button-orange-outline"
                              type="button"
                              width="90px"
                              mr={{base:0,md:2}}
                              onClick={closeForm}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              width="90px"
                              className="dm-button-orange-solid check-out-damage-submit"
                              color={"white"}
                              mr={{base:2,md:0}}
                            >
                              Send
                            </Button>
                          </Flex>
                        </Flex>
                    </form>
                  )}
                </Formik>
              </Flex>
            </SimpleGrid>
          </Flex>
        </Box>
    </Box>
  );
};

export { ReportDamage };

