import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { useState } from 'react';

interface ReservationVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reservationNumber: string, checkinDate: string) => void;
}

export const ReservationVerificationModal: React.FC<ReservationVerificationModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [reservationNum, setReservationNum] = useState('');
  const [checkInDate, setCheckInDate] = useState('');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter Reservation Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Reservation Number</FormLabel>
            <Input value={reservationNum} onChange={(e) => setReservationNum(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Check-in Date</FormLabel>
            <Input type="date" value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => onSubmit(reservationNum, checkInDate)}>
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
