import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from 'react';
import { fetchSupportManager } from '../../helpers/guestportalfetch';

interface Manager {
  name: string;
  title: string;
  avatar: string;
}

const SupportManager: React.FC = () => {
  const [manager, setManager] = useState<Manager | null>(null);
  const { user } = useUser();
  const userEmail = user.primaryEmailAddress.emailAddress;

  //console.log("Support Manager Component");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSupportManager({ tenantEmail: userEmail });
        const managerInfo = await response.json();

        setManager(managerInfo[0]);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <pre>
      {JSON.stringify(manager, null, 2)}
    </pre>
  );
};

export default SupportManager;
