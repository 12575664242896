import { Box, Divider, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";
import { CollapsibleText } from "../../display/ListingHelpers";
import IconText from "../../IconText";

const excludedAmenities = ["hair dryer", "hot water"];
const includedAmenities = [
  "free parking on premises",
  "air conditioning",
  "heating",
  "indoor fireplace",
  "wireless internet",
  "kitchen",
  "washer",
  "dryer",
  "iron",
  "cable tv",
  "tv",
  "essentials",
  "hangers",
  "smoke detector",
  "carbon monoxide detector",
  "fire extinguisher",
  "dogs allowed w/ fee",
  "bed linens",
  "microwave",
  "coffee maker",
  "refrigerator",
  "dishwasher",
  "dishes and silverware",
  "cookware",
  "oven",
  "stove",
  "bbq grill",
  "garden or backyard",
  "hot water",
  "hot tub",
  "extra pillows and blankets",
];

const ListingDescriptionText = ({ houseData }) => {
  if (houseData && houseData.description) {
    // Determine the key for splitting
    const splitKey = houseData.description.includes("Home Details:")
      ? "Home Details:"
      : "House Details:";
    // Split the description based on the determined key
    const descriptionParts = houseData.description.split(splitKey);

    // Cleaned description before the split key
    const cleanedDescriptionBeforeSplit = descriptionParts[0]
      // eslint-disable-next-line no-control-regex
      .replace(/\u0019/g, "'")
      .replace(/\\r\\n|<br\s*\/?>/gi, "\n") // Replace \\r\\n and <br>, <br/>, <br /> with actual newlines
      .replace(/\uFFFD/g, (match, offset, string) => {
        // Handle the replacement character
        if (
          offset > 0 &&
          /[a-zA-Z]/.test(string[offset - 1]) &&
          offset < string.length - 1 &&
          /[a-zA-Z]/.test(string[offset + 1])
        ) {
          return "'";
        } else {
          return "";
        }
      });

    // Optionally clean the second part if it exists
    const cleanedDescriptionAfterSplit =
      descriptionParts.length > 1
        ? descriptionParts[1]
            // eslint-disable-next-line no-control-regex
            .replace(/\u0019/g, "'")
            .replace(/\\r\\n|<br\s*\/?>/gi, "\n") // Replace \\r\\n and <br>, <br/>, <br /> with actual newlines
            .replace(/^\n+/, "") // Remove leading newlines
            .replace(/-/g, "• ")
            .replace(/\uFFFD/g, "")
        : "";

    return (
      <Flex pt={{ base: 3, md: 6 }} flexDirection={"column"}>
        <>
          <CollapsibleText text={cleanedDescriptionBeforeSplit} maxChar={525} />
          {cleanedDescriptionAfterSplit && (
            <Flex pt={8} flexDirection={"column"}>
              <Box fontWeight={500} fontSize={20}>
                {splitKey}
              </Box>
              <Box whiteSpace="pre-wrap" ml={2} mt={2}>
                {cleanedDescriptionAfterSplit}
              </Box>
            </Flex>
          )}
        </>
      </Flex>
    );
  }

  return null; // Return null if there's no houseData or description
};

export const Details = () => {
  const { houseData } = useContext(SearchResultsContext);

  return (
    <>
      {/* Occupancy, Bedroom, Bathroom Icons */}
      <Flex
        className="listing-details"
        fontSize={"12px"}
        alignItems={"flex-start"}
        mt={{ base: 0, md: 4 }}
        px={1}
      >
        <Flex
          flexDirection={"column"}
          className="listing-occupancy"
          alignItems={"center"}
          width={"max-content"}
          me={{ base: 4, md: 8 }}
        >
          <Image
            src={"../media/occupancy.png"}
            alt="Max Occupancy"
            objectFit={"cover"}
            align={"left"}
            width={{ base: "35px", md: "50px" }}
            height={{ base: "35px", md: "50px" }}
            className="listing-occupancy-image"
          />
          <Text className="listing-occupancy-text">
            {!isNaN(houseData.occupancy) ? (houseData.occupancy as number) : 0}{" "}
            Guests
          </Text>
        </Flex>
        <Flex
          flexDirection={"column"}
          className="listing-bedrooms"
          alignItems={"center"}
          width={"max-content"}
          me={{ base: 4, md: 8 }}
        >
          <Image
            src={"../media/bedrooms.png"}
            alt="Bedrooms"
            objectFit={"cover"}
            align={"left"}
            width={{ base: "35px", md: "50px" }}
            height={{ base: "35px", md: "50px" }}
            className="listing-bedrooms-image"
          />
          <Text className="listing-bedrooms-text">
            {!isNaN(houseData.bedrooms) ? (houseData.bedrooms as number) : 0}{" "}
            Bedrooms
          </Text>
        </Flex>
        <Flex
          flexDirection={"column"}
          className="listing-bathrooms"
          alignItems={"center"}
          width={"max-content"}
          me={{ base: 4, md: 8 }}
        >
          <Image
            src={"../media/bathrooms.png"}
            alt="Bathrooms"
            objectFit={"cover"}
            align={"left"}
            width={{ base: "35px", md: "50px" }}
            height={{ base: "35px", md: "50px" }}
            className="listing-bathrooms-image"
          />
          <Text className="listing-bathrooms-text">
            {!isNaN(houseData.bathrooms) ? (houseData.bathrooms as number) : 0}{" "}
            Bathrooms
          </Text>
        </Flex>

        {houseData.tags.includes("Dog Friendly") && (
          <Flex
            flexDirection={"column"}
            className="listing-bathrooms"
            alignItems={"center"}
            width={"max-content"}
            me={{ base: 4, md: 8 }}
          >
            <Image
              src={"../media/pawprint.png"}
              alt="Dog Friendly"
              objectFit={"cover"}
              align={"left"}
              width={{ base: "35px", md: "50px" }}
              height={{ base: "35px", md: "50px" }}
              className="listing-dog-friendly-icon"
              p={1}
            />
            <Text className="listing-dog-friendly-text">Dog Friendly</Text>
          </Flex>
        )}
      </Flex>

      {/* Full Details */}
      <ListingDescriptionText houseData={houseData} />

      <Divider my={8} color={"black"} />

      {/* Amenities */}
      <Flex flexDirection="column">
        <Box fontWeight="500" pb={2} fontSize="lg">
          Amenities:
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={0}>
          {houseData.amenities
            .filter(
              (amenity: string) =>
                includedAmenities.includes(amenity.toLowerCase()) &&
                !excludedAmenities.includes(amenity.toLowerCase())
            )
            .map((amenity: string, index) => (
              <Flex key={index + amenity} align="center">
                <IconText iconName={amenity} iconText={amenity} />
              </Flex>
            ))}
        </SimpleGrid>
      </Flex>

      <Divider my={8} color={"black"} />
    </>
  );
};
