import { Alert, AlertIcon, Button, Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { SignIn, SignUp } from "@clerk/clerk-react";
import { useEffect, useState } from 'react';
import { FaArrowRight } from "react-icons/fa";
import config from "../../config";
import { useReservation } from '../../contexts/ReservationContext';
import { fetchConfirmationSignIn, fetchGuestsByUserIdAndReservationId } from "../../helpers/fetch";
import { SignInMagicLink } from "./ClerkMagicLink";
import ClerkSignInEmail from "./ClerkSignInEmail";
import ClerkSignInOauth from "./ClerkSignInOauth";
import { ReservationSummary } from "./ReservationSummary";

// Confirmation Home Page for Redirect
const homePage = config.homePage;
const baseUrl = config.baseUrl;

// Returns Clerk User Object
async function getConfirmationSignIn(clerkSearchQuery) {
  const clerkDataRaw = await fetchConfirmationSignIn(clerkSearchQuery)
  return clerkDataRaw
}
  
async function getRowsByUserIdAndReservationId(data) {

  const guestsRaw = await fetchGuestsByUserIdAndReservationId(data)
  const guestsJson = await guestsRaw.json();
  //console.log("guestsJson", guestsJson)

  return guestsJson
}
  

const CustomLogin = ({ user }) => {
  const { reservationData } = useReservation();

  const [clerkData, setClerkData] = useState([]);
  const [clerkUserId, setClerkUserId] = useState(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [defaultMagicLink, setDefaultMagicLink] = useState(true);
  const [useSignIn, setUseSignIn] = useState(false);

  const switchLoginMethod = () => setDefaultMagicLink(!defaultMagicLink);
  const toggleUseSignIn = () => setUseSignIn(!useSignIn);

  


    // --- Step 2:
    // Find out if the user is signed in
  
    useEffect(() => {

      // * If they are signed in, then set the variables (clerkUserId, userEmail)
      // * If they are NOT signed in, but there is reservation data for the confirmation ID,
      // then get their email from the reservation data, and clerk user info to determine which sign in/sign up to show them
      // and display that sign in/sign up component (this is handled in the ClerkConfirmationDisplay component)

      setIsLoading(true);
  
      const fetchUserData = async () => {
        // Assuming user and reservationData are objects that might contain the email information
        let userEmail = user?.primaryEmailAddress?.emailAddress || reservationData?.agreementEmail || reservationData?.tenantEmail;

        // Regex for channel placeholder emails
        var emailRegex = /(@guest.airbnb)|(@host.airbnb)|(@messages.homeaway)|(@mchat.booking)|(@reply.airbnb)|(https:)|(@e.tr)|(@e.tripadvisor)|(\.SYNTAX-ERROR)|(@message.weneedavacation)/;

        // Check if userEmail matches any of the patterns in emailRegex
        if(emailRegex.test(userEmail)) {
            // If it matches, set userEmail to an empty string
            userEmail = '';
        }

        //console.log('userEmail',userEmail)
  
        if (userEmail) {
          const clerkDataRaw = await getConfirmationSignIn(userEmail);
          setClerkData(clerkDataRaw);
  
          if (clerkDataRaw?.[0]?.emailAddresses?.[0]?.emailAddress) {
            setEmail(clerkDataRaw[0].emailAddresses[0].emailAddress);
          } else {
            setEmail(userEmail);
          }
  
          if (user) {
            setClerkUserId(user.id);
          }
        }
  
        setIsLoading(false);
      };
  
      fetchUserData();

      }, [user, reservationData]);
  

  
    // --- Step 3: 
    // Once the user is signed in, we check to see if a primary guest record exists for that user and the current reservation ID
  
    useEffect(() => {
      if (!user || !email || !clerkUserId) return;
  
      setIsLoading(true);

      // Query the Guest table
      // * If a row with userId = user's ID, guestType = Primary, and reservationNumber = confirmation number, do nothing
      // * If that row does NOT exist, insert a record into the guest table with the user ID, guestType = primary, and reservation ID
  
      // When creating the guest row, if there is no first name and no last name in user, then fall back to the reservation's first/last name.
      // If reservation's first/last name are blank, default to null

      const handleUserSignIn = async () => {

        let firstname = null
        if (user.firstName) {
          firstname = user.firstName
        } else if (reservationData.firstName) {
          firstname = reservationData.firstName
        }
    
        let lastname = null
        if (user.lastName) {
          lastname = user.lastName
        } else if (reservationData.lastName) {
          lastname = reservationData.lastName
        }

        let loginMethod = null
        if (user.primaryEmailAddress.verification.strategy && user.primaryEmailAddress.verification.strategy.includes('google')) {
          loginMethod = 'Google'
        } else if (user.primaryEmailAddress.verification.strategy && user.primaryEmailAddress.verification.strategy.includes('facebook')) {
          loginMethod = 'Facebook'
        } else {
          loginMethod = 'Email'
        }
    
        const data = {
          email: email,
          reservationNumber: reservationData.reservationNumber,
          userId: clerkUserId,
          guestType: 'Primary',
          firstname: firstname,
          lastname: lastname,
          loginMethod: loginMethod
        }  
  
        await getRowsByUserIdAndReservationId(data);
  
        setIsLoading(false);
      };
  
      handleUserSignIn();

      setIsLoading(false);
  
    }, [user, email, clerkUserId, reservationData])
    
    const ClerkConfirmationDisplay = () => {
        //console.log("USER", user)
        /*
        1a. If clerkData is NOT null (not empty array), then the email from the reservation belongs to an existing Clerk user.
        
        1b. If clerkData IS null (empty array), then the email from the reservation DOES NOT belong to an existing Clerk user.
        In that case, display the SignUp component for the user to create a Clerk account. (End)
    
        2. If clerkData is NOT null, find out what the user's auth type is (email/password, Google, Facebook)
    
        3. Give the user two options to sign in:
          - Magic link (if their auth type is email)
          - Sign in through Google/FB (if their auth type is Google/FB)
        */
    
        // If the user is already logged in, no need to ask them to sign up/sign in

        if (isLoading) { return (
          <Flex w={'100%'} justifyContent={'center'}>
            <Spinner />
          </Flex>
        ) }

        if (user) {
          return (
            <Flex flexDirection={'column'} w={'100%'} alignItems={'center'}>
              {reservationData &&
                <>
                  <Link href={`${baseUrl}/trips/`}>
                    <Button colorScheme="dmOrange" w={'100%'} maxW={'300px'} mx={1} mb={{base:4,md:7}}
                      rightIcon={<FaArrowRight />} size="lg"
                    >
                      Continue to My Trips
                    </Button>
                    </Link>
                  <ReservationSummary />
                </>
              }
            </Flex>
          )
    
          // Display SIGN IN (when the email IS found)
        } else if (clerkData && clerkData.length > 0) {
    
          // Find out how the user originally signed up (Google/FB/email)
          let signedUpType = null
    
          if (clerkData[0].emailAddresses?.[0].linkedTo && clerkData[0].emailAddresses?.[0].linkedTo.length > 0) {
            signedUpType = clerkData[0].emailAddresses?.[0].linkedTo?.[0].type
          } else if (clerkData[0].emailAddresses?.[0].linkedTo && clerkData[0].emailAddresses?.[0].linkedTo.length === 0) {
            signedUpType = "email"
          }
    
          if (signedUpType === "oauth_google" || signedUpType === "oauth_facebook") {
            // If they signed up via Google/FB, show Google/FB sign in
    
            return (
              <>
                <ClerkSignInOauth oauthType={signedUpType} reservationId={reservationData.reservationNumber} />
                <ReservationSummary />
              </>
            )
    
          } else if (signedUpType === "email") {
            // If they signed up via email, show magic link/email and password to sign in
    
            return (
              <>
                {defaultMagicLink ?
                <Flex flexDirection={'column'} w={'100%'} alignItems={'center'}>
                  
                  <SignInMagicLink email={email} reservationId={reservationData.reservationNumber} />
                  <Text pt={5}>----- OR -----</Text>
                  <Button h={"40px"} mt={6} mb={6} onClick={switchLoginMethod} colorScheme={'dmNavy'} 
                    variant='outline' w={'100%'} maxW={'350px'}
                  >
                    Sign in with Password
                  </Button>
                  
                </Flex>
                :
                <Flex flexDirection={'column'} w={'100%'} alignItems={'center'}>
                  <ClerkSignInEmail email={email} reservationId={reservationData.reservationNumber} />
                  <Text pt={5}>----- OR -----</Text>
                  <Button h={"40px"} mt={6} mb={6} onClick={switchLoginMethod} colorScheme={'dmNavy'} 
                    variant='outline' w={'100%'} maxW={'350px'}
                  >
                    Sign in with Secure Link
                  </Button>
                </Flex>
                }
                <ReservationSummary />
              </>
            )
    
          } else {
            // If there is somehow clerk data for them, but no registration email found, give an error and ask them to sign up again
    
            return (
              !isLoading && 
                <>
                  <Text>Error: We cannot find your Del Mar account. Please sign up with a new one here:</Text>
                  <div>
                    <SignUp />
                  </div>
                </>     
            )
    
          }
    
        } else {
          // Display SIGN UP (when there is NO clerkData for the email)
    
          return (
            !isLoading &&
              !useSignIn ? (
                <Flex w={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                  <Alert status='success' textAlign={'left'} maxW={'600px'} pb={5}>
                    <AlertIcon />
                    Create a Del Mar Vacations Account to see your trip details, find nearby beaches and attractions, order extra services, and more!
                    </Alert>
                  <SignUp 
                    appearance={{
                      elements: {
                        logoBox: {
                          display: 'none',
                        },
                        card: {
                          boxShadow: 'none',
                        },
                        footer: {
                          display: 'none'
                        }
                      }
                    }} 
                    redirectUrl={`${homePage}${reservationData.reservationNumber}`}
                    initialValues={{
                      emailAddress:email
                    }}
                  />
                  <Text textAlign={'center'} pb={8}>Already have an account? <Link onClick={toggleUseSignIn}>Sign In</Link></Text>
                  <ReservationSummary />
                </Flex>
              ) : (
                <Flex w={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                  <Alert status='warning' maxW={'600px'} textAlign={'left'} pb={3}>
                    <AlertIcon />
                    <Text>We couldn't find an existing Del Mar Vacations Guest Portal Account with the email you used to sign the Guest Agreement. If you already have a Del Mar Vacations Guest Portal Account under a different email, sign in below to link this reservation to your existing account.</Text>
                  </Alert>
                  <SignIn 
                    appearance={{
                      elements: {
                        logoBox: {
                          display: 'none',
                        },
                        card: {
                          boxShadow: 'none',
                        },
                        footer: {
                          display: 'none'
                        }
                      },
                    }}
                    redirectUrl={`${homePage}${reservationData.reservationNumber}`}
                  />
                  <Text textAlign={'center'} pb={8}>Need to create a new account? <Link onClick={toggleUseSignIn}>Sign Up</Link></Text>
                  <ReservationSummary />
                </Flex>
              )
          )
        }
      }

    return (
        <Flex
          position={"relative"}
          width={"full"}
          data-id={"listing"}
          p={{ base: 2, md: 5 }}
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          {/* Top Bar */}
          <Flex maxWidth='1124px' width={'100%'} p={2} 
            textAlign={'left'} alignItems={'start'} flexDirection={'column'}
          >  
            <ClerkConfirmationDisplay />
          </Flex>
        </Flex>
    )
}

export { CustomLogin };

