import {
  Alert, AlertIcon, Box,
  Button,
  Flex,
  FormControl, FormLabel, Input,
  Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalHeader, ModalOverlay,
  Select,
  Text, Textarea,
  useToast
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { submitBookingHelpForm } from '../helpers/fetch';
import { ReactComponent as DelMarLogoDark } from "../media/logodark.svg";

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }) => {
  const toast = useToast();

  const handleSubmit = async (values) => {
    try {
      await submitBookingHelpForm(values);
      toast({
        title: 'Request Submitted',
        description: 'Your request has been submitted. A Del Mar Guest Experience Manager will be in touch with you soon!',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: "top"
      });
      onClose();
    } catch (error) {
      // Handle submission error
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email'),
    phone: Yup.string(),
    checkInDate: Yup.date(),
    checkOutDate: Yup.date(),
    town: Yup.string(),
    guests: Yup.number(),
    additionalRequests: Yup.string().max(1000, 'Max 1000 characters')
  }).test(
    'emailOrPhone',
    'Either Email or Phone is required',
    function (values) {
      const { email, phone } = values;
      if (!email && !phone) {
        return new Yup.ValidationError(
          'Either Email or Phone is required',
          null,
          'emailOrPhone'
        );
      }
      return true;
    }
  );
  

  // Define the ErrorMessageText component for error messages
  const ErrorMessageText = ({ children }) => (
    <Text fontSize="sm" color="red.500">{children}</Text>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{base:'full',md:'2xl'}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={4}>
          <DelMarLogoDark />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH={'calc(100vh - 152px)'} overflowY={'scroll'} overflowX={'hidden'}>
          <Alert status='info' mb={5}><AlertIcon />
            For assistance, complete the form below or give us a call at 508-422-8047.
          </Alert>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              checkInDate: '',
              checkOutDate: '',
              town: '',
              guests: 2,
              additionalRequests: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <Text fontSize={'1em'} fontWeight={'500'}>How Can We Reach You?</Text>
                <Flex gap={2} mt={2}>                  
                  {/* First Name */}
                  <Field name="firstName">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.firstName && form.touched.firstName} flex={1}>
                        <Input {...field} fontSize={16} placeholder="First Name" />
                        <ErrorMessage name="firstName" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                  
                  {/* Last Name */}
                  <Field name="lastName">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.lastName && form.touched.lastName} flex={1}>
                        <Input {...field} fontSize={16} placeholder="Last Name" />
                        <ErrorMessage name="lastName" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex gap={2} mt={2}>
                  {/* Email */}
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <Input {...field} type="email" fontSize={16} placeholder="Email" />
                        <ErrorMessage name="email" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>

                  {/* Phone */}
                  <Field name="phone">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                        <Input {...field} fontSize={16} placeholder="Phone" />
                        <ErrorMessage name="phone" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                {typeof errors.emailOrPhone === 'string' && (
                  <Text fontSize="sm" color='#E53E3E'>{errors.emailOrPhone}</Text>
                )}
                <Flex gap={2} mt={6} w={'100%'}>
                  {/* Check-In Date */}
                  <Field name="checkInDate">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.checkInDate && form.touched.checkInDate} flex={1} maxW={{base:'calc(50% - 4px)',md:'50%'}}>
                        <FormLabel>Desired Check-In</FormLabel>
                        <Input {...field} type="date" fontSize={16} placeholder="Check-In Date" />
                        <ErrorMessage name="checkInDate" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>

                  {/* Check-Out Date */}
                  <Field name="checkOutDate">
                  {({ field, form }) => (
                      <FormControl isInvalid={form.errors.checkInDate && form.touched.checkInDate} flex={1} maxW={{base:'calc(50% - 4px)',md:'50%'}}>
                        <FormLabel>Desired Check-Out</FormLabel>
                        <Input {...field} type="date" fontSize={16} placeholder="Check-In Date" />
                        <ErrorMessage name="checkInDate" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Flex>

                <Flex gap={2} mt={6} w={'100%'}>
                  {/* Town */}
                  <FormControl w={'100%'} >
                    <FormLabel>Preferred Location</FormLabel>
                    <Field as={Select} name="town">
                      <option value="">Select Town</option>
                      <option value="Barnstable">Barnstable</option>
                      <option value="Bourne">Bourne</option>
                      <option value="Brewster">Brewster</option>
                      <option value="Chatham">Chatham</option>
                      <option value="Dennis">Dennis</option>
                      <option value="Eastham">Eastham</option>
                      <option value="Falmouth">Falmouth</option>
                      <option value="Harwich">Harwich</option>
                      <option value="Mashpee">Mashpee</option>
                      <option value="Orleans">Orleans</option>
                      <option value="Provincetown">Provincetown</option>
                      <option value="Sandwich">Sandwich</option>
                      <option value="Truro">Truro</option>
                      <option value="Wellfleet">Wellfleet</option>
                      <option value="Yarmouth">Yarmouth</option>
                    </Field>
                  </FormControl>

                  {/* Phone */}
                  <Field name="guests">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.guests && form.touched.guests}>
                        <FormLabel>Number of Guests</FormLabel>
                        <Input {...field} fontSize={16} placeholder="Guests" />
                        <ErrorMessage name="guests" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Flex>

                {/* Additional Requests */}
                <Field name="additionalRequests">
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.additionalRequests && form.touched.additionalRequests} my={4}>
                      <FormLabel>Additional Requests</FormLabel>
                      <Textarea fontSize={16} h={'130px'} {...field} placeholder="Let us know of any specific preferences, such as number of bedrooms/bathrooms, dog-friendly, or anything else!" />
                      <ErrorMessage name="Additional Requests" component={ErrorMessageText} />
                    </FormControl>
                  )}
                </Field>
                <Box
                  position={{base:"fixed", md:"unset"}} 
                  maxWidth='1440px' w={'100%'} 
                  bgColor={{base:'white', md:'white'}} 
                  left={{base:0, md:'unset'}} 
                  boxShadow={{base:'dmShadow', md:'none'}}
                  bottom={{base:0, md:'unset'}} margin={'0 auto'} px={{base:5, md:0}}
                >
                  <Button type="submit" w={'full'} colorScheme={'dmOrange'} my={4}>
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HelpModal;
