import {
  Accordion, AccordionButton, AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList
} from "@chakra-ui/react";

type BeachProps = {
  openItems: any[];
  town: string;
  standalone?: boolean;
}

const Beach: React.FC<BeachProps> = ({
    openItems, town, standalone=false
  }) => {

  console.log(town)
  const townStickers = {
    "Provincetown": (
      <>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            The two large beaches in Provincetown are part of the Cape Cod National Seashore. See National Seashore instructions to learn how to get a sticker.
          </ListItem>
        </UnorderedList>
      </>
    ),
    "Truro": (
      <>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Non-residents can purchase weekly stickers here <Link color={'#e95037'} target={'_blank'} href='https://trurostickers.townhall247.com'>https://trurostickers.townhall247.com</Link>. Stickers are not mailed and will need to be picked up at the beach office window.
          </ListItem>
          <ListItem>
            You can also puchase stickers in-person at the Beach office at 36 Shore Road, North Truro, MA 02652
          </ListItem>
          <ListItem>
            You must provide your guest agreement (proof of stay) while purchasing a sticker, and your vehicle registration.
          </ListItem>
          <ListItem>
            Daily Beach sticker can be purchased at Head of the Meadow Beach and Corn Hill Beach for $25. 
          </ListItem>
        </UnorderedList>
      </>
    ),
    "Wellfleet": (
      <>
        <Text>Stickers can be purchased online by non-residents here: <Link color={'#e95037'} target={'_blank'} href='https://wellfleetstickers.townhall247.com'>https://wellfleetstickers.townhall247.com</Link>.</Text>
        <Text>Beach stickers are also available at the Beach Office at 255 Commercial Street at the walk-up windows.</Text>
        <Text>Wellfleet offers the following stickers for non-resident visitors to visit any town beaches:</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Three Day: $70
          </ListItem>
          <ListItem>
            One Week: $105
          </ListItem>
          <ListItem>
            Two Week: $200
          </ListItem>
          <ListItem>
            Seasonal: $325
          </ListItem>
        </UnorderedList>
        <Text>* Mayo Beach, at the Town Pier, is a free beach that requires no fee to park.</Text>
      </>
    ),
    "Eastham": (
      <>
        <Text>There are 4 locations in Eastham where a daily sticker is sold and permitted, aside from the National Seashore:</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Campground Beach
          </ListItem>
          <ListItem>
            Cooks Brook Beach
          </ListItem>
          <ListItem>
            First Encounter Beach
          </ListItem>
          <ListItem>
            Wiley Park
          </ListItem>
        </UnorderedList>
        <Text>Daily passes are not sold at the Town of Eastham Sticker Office.</Text>
        <Text>Non-residents can also apply for a $300 seasonal beach pass, which is available only by mail. The application be found here under "Visitor Mail-In Application" starting mid to late April at <Link color={'#e95037'} target={'_blank'} href="https://www.eastham-ma.gov/410/Beach-Parking-Daily-Passes">https://www.eastham-ma.gov/410/Beach-Parking-Daily-Passes</Link>.</Text>
      </>
    ),
    "Orleans": (
      <>
        <Text>Non-residents may purchase all 2023 season stickers and permits through an online portal here: <Link color={'#e95037'} target={'_blank'} href='https://orleansstickers.townhall247.com'>https://orleansstickers.townhall247.com</Link></Text>
        <Text>The cost for a weekly permit is $150 for non-residents, and is valid at all beaches in Orleans.</Text>
        <Text>These two beaches offer daily sticker parking for $30/day:</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Nauset Beach<br/>
            250 Beach Road<br/>
            Orleans, MA 02653
          </ListItem>
          <ListItem>
            Skaket Beach<br/>
            192 Skaket Beach Road<br/>
            Orleans, MA 02653
          </ListItem>
        </UnorderedList>
      </>
    ),
    "Brewster": (
      <>
        <Text>Non-residents may purchase daily or seasonal beach parking stickers online here: <Link color={'#e95037'} target={'_blank'} href='https://brewsterstickers.townhall247.com'>https://brewsterstickers.townhall247.com</Link> - please note that these are paperless.</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Your beach parking pass will be automatically valid once you receive the confirmation email.
          </ListItem>
          <ListItem>
            There is no need to print hard copies of the permit, as enforcement will be conducted using license plate reader technology.
          </ListItem>
          <ListItem>
            Visitor passes may be purchased the day of, or in advance of your visit. Please make sure to enter your car license plate number correctly and do not include any symbols, hyphens, or periods ( -, /, etc.).
          </ListItem>
        </UnorderedList>
      </>
    ),
    "Chatham": (
      <>
        <Text>Chatham offers non-resident stickers, for daily ($20), weekly ($75) and seasonal ($175) parking. Those passes can be purchased online here: <Link color={'#e95037'} target={'_blank'} href='https://www.chatham-ma.gov/646/Non-Resident-Beach-Passes'>https://www.chatham-ma.gov/646/Non-Resident-Beach-Passes</Link>.</Text>
        <Text>Passes can also be purchased at 261 George Ryder Road Chatham, MA 02633 in person, between 10am-1pm Mon-Fri.</Text>
        <Text>Passes can also be purchased in-person with cash at these beach gates:</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Harding's Beach
          </ListItem>
          <ListItem>
            Ridgevale Beach
          </ListItem>
          <ListItem>
            Cockle Cove Beach
          </ListItem>
          </UnorderedList>
          <Text>Chatham also offers these free beaches, with limited parking:</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Oyster Pond Beach
          </ListItem>
          <ListItem>
            Forest Beach
          </ListItem>
          <ListItem>
            Pleasant Street Beach
          </ListItem>
          <ListItem>
            Jackknife Harbor Beach
          </ListItem>
        </UnorderedList>
      </>
    ),
    "Harwich": (
      <>
        <Text>Non-resident stickers can be purchased at <Link color={'#e95037'} target={'_blank'} href='https://harwichstickers.townhall247.com'>https://harwichstickers.townhall247.com</Link>. For non-residents, the town offers: </Text>
         <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Seasonal beach sitckers - $150.00 each vehicle
          </ListItem>
          <ListItem>
            1 week  beach stickers - $65.00 each vehicle
          </ListItem>
          <ListItem>
            2 week beach stickers - $125.00 each vehicle
          </ListItem>
        </UnorderedList>
        <Text>Stickers can also be purchased in person at the Chamber of Commerce: Additional Fee of $3.00 at this location.</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            1 Schoolhouse Road, Harwich Port, (508) 432-1600
          </ListItem>
          <ListItem>
            June 12th thru Labor Day
          </ListItem>
          <ListItem>
            9:00 AM to 4:00 PM Monday thru Friday
          </ListItem>
          <ListItem>
            10:00 AM to 4:00 PM Saturdays
          </ListItem>
          <ListItem>
            10:00 AM to 2:00 PM Sundays
          </ListItem>
          </UnorderedList>
          <Text>Daily Passes can be purchased at the gate at these beaches:</Text>
          <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Pleasant Road Beach
          </ListItem>
          <ListItem>
            Red River Beach
          </ListItem>
          <ListItem>
            Earle Road Beach
          </ListItem>
          <ListItem>
            Long Pond (Fernandez Bog)
          </ListItem>
        </UnorderedList>
      </>
    ),
    "Dennis": (
      <>
        <Text>Non-resident stickers can be purchased at <Link color={'#e95037'} target={'_blank'} href='https://dennisstickers.townhall247.com/'>https://dennisstickers.townhall247.com/</Link>. For non-residents, the town offers:</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Seasonal passes $360.00
          </ListItem>
          <ListItem>
            Weekly passes $90.00
          </ListItem>
          <ListItem>
            Daily Parking $30.00
          </ListItem>
        </UnorderedList>
        <Text>
          Stickers can also be purchased at the Dennis Sticker office (check hours).
        </Text>
        <Text>
          Sticker Office Contact Information
        </Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Dennis Town Hall | 685 Route 134 S. Dennis, MA 02660
          </ListItem>
          <ListItem>
            Phone: (508) 760-6106
          </ListItem>
          <ListItem>
            Email: stickers@town.dennis.ma.us
          </ListItem>
        </UnorderedList>
      </>
    ),
    "Yarmouth": (
      <>
        <Text>
          Non residents can purchase a seasonal beach sticker <Link color={'#e95037'} target={'_blank'} href='https://epay.cityhallsystems.com/selection'>https://epay.cityhallsystems.com/selection</Link>
        </Text>
        <Text>
          Yarmouth also offers these stickers for non-residents:
        </Text>
        <UnorderedList ml={{base:4,md:10}}>
        <ListItem>
          Daily Parking $20
        </ListItem>
        <ListItem>
          Weekly Sticker $75
        </ListItem>
        </UnorderedList>
        <Text>
          Daily and weekly passes can be purchased online and by phone by calling City Hall Systems at: 508-381-5455
        </Text>
        <Text>
          Daily Beach stickers can also be purchased in-person at:
        </Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
          Seagull Beach
          </ListItem>
          <ListItem>
          Smuggler's Beach
          </ListItem>
          <ListItem>
          Parker's River Beach
          </ListItem>
          <ListItem>
          Seaview Beach
          </ListItem>
        </UnorderedList>
      </>
    ),
    "Barnstable": (
      <>
        <Text>
          For non-residents, weekly and seasonal passes can be purchased online <Link color={'#e95037'} target={'_blank'} href='https://barnstablema.viewpointcloud.com/categories/1089/record-types/6489'>https://barnstablema.viewpointcloud.com/categories/1089/record-types/6489</Link>
        </Text>
        <Text>
          Daily passes can be purchased at the gate at these beaches:
        </Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>Veteran's</ListItem>
          <ListItem>Kalmus</ListItem>
          <ListItem>Keyes</ListItem>
          <ListItem>Craigville</ListItem>
          <ListItem>Sandy Neck</ListItem>
        </UnorderedList>
      </>
    ),
    "Sandwich": (
      <>
        <Text>
          Non-resident beach stickers can be purchased online <Link color={'#e95037'} target={'_blank'} href='https://sandwichstickers.townhall247.com'>https://sandwichstickers.townhall247.com</Link> for $105, and are valid for the season.
        </Text>
        <Text>
          Annual passes can also be purchased in person at the Tax Collector’s Office at Town Hall - 100 Route 6A Sandwich, MA 02563
        </Text>
        <Text>
          Daily stickers can be purchased by non-residents only at Oak Cove Crest Beach.
        </Text>
        <Text>
          For more info visit <Link color={'#e95037'} target={'_blank'} href='https://www.sandwichmass.org/168/Beaches'>https://www.sandwichmass.org/168/Beaches</Link>
        </Text>
      </>
    ),
    "Falmouth": (
      <>
        <Text>Falmouth seasonal, and by-the-week beach stickers can be purchased by non-residents at at the Ellen T. Mitchell Bathhouse At 56 Surf Drive - open 9:00 a.m. - 4:00 p.m. daily.</Text>
        <Text>Daily stickers can be purchased with cash or card at the following beach gates:</Text>
        <UnorderedList ml={{base:4,md:10}}>
          <ListItem>
            Menauhant Beach - East
          </ListItem>
          <ListItem>
            Old Silver Beach
          </ListItem>
          <ListItem>Surf Drive Beach</ListItem>
          <ListItem>Surf Drive Extension (Mill Rd.)</ListItem>
          <Text>
            For all beach sticker info, visit <Link color={'#e95037'} target={'_blank'} href='https://www.falmouthma.gov/152/Beach-Parking-Stickers'>https://www.falmouthma.gov/152/Beach-Parking-Stickers</Link>
          </Text>
        </UnorderedList>
      </>
    ),
    "Bourne": (
      <>
        <Text>
          Non-resident beach stickers can be purchased online <Link color={'#e95037'} target={'_blank'} href='https://bournestickers.townhall247.com/'>https://bournestickers.townhall247.com/</Link>.
        </Text>
        <Text>
          More info and a list of beaches that require stickers can be found <Link color={'#e95037'} target={'_blank'} href='https://www.townofbourne.com/natural-resources/pages/beaches-boat-ramps-parking'>https://www.townofbourne.com/natural-resources/pages/beaches-boat-ramps-parking</Link>
        </Text>
      </>
    ),
    "Mashpee": (
      <>
        <Text>
          Non-resident beach stickers can be purchased online <Link color={'#e95037'} target={'_blank'} href='https://mashpeestickers.townhall247.com'>https://mashpeestickers.townhall247.com</Link>
        </Text>
        <Text>
          Stickers are required from June 24 to September 4 (Labor Day) to use the three public beaches in Mashpee: South Cape Town Beach, Attaquin Park and Johns Pond.
        </Text>
        <Text>
          Beach stickers cost $30. One-week passes for nonresidents cost $50.
        </Text>
      </>
    ),  
  };

  return (
    <Flex direction='column' pb={10} alignItems={'center'}>
      <Text fontSize={20} pt={2} pb={8} textAlign={'left'} 
        maxW={standalone ? 'full' : {base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}
        width={{ base: "90vw" }}
      >
        Most beaches on Cape Cod require a beach sticker for parking between 8am-5pm. Please refer to the instructions 
        below for how to obtain a sticker for the town you're staying in, as well as the National Seashore.
      </Text>
      <Accordion
        defaultIndex={openItems}
        allowMultiple
        maxW={standalone ? 'full' : {base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}
        width={{ base: "90vw" }}
      >
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontSize={18}
                fontWeight={"500"}
                my={2}
              >
                {town} Beach Stickers
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} textAlign="left" fontSize={16} mt={1} mb={6} lineHeight={8}>
            {townStickers[town]}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontSize={18}
                fontWeight={"500"}
                my={2}
              >
                National Seashore Stickers
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} textAlign="left" fontSize={16} mt={1} mb={6} lineHeight={8}>
            The Cape Cod National Seashore offers some of the best Cape Cod Beaches in Eastham, Wellfleet, Truro, and Provincetown.
            <br/><br/>
            Parking stickers are $25 daily, and $60 for an annual pass. All you need to do is purchase a sticker at the beach entrance (passes can only be purchased at the beach) and they are for sale at the following locations:
            <UnorderedList ml={{base:4,md:10}}>
              <ListItem>
                Nauset Light Beach - Eastham
              </ListItem>
              <ListItem>
                Coast Guard Beach - Eastham
              </ListItem>
              <ListItem>
                Herring Cove Beach - Provincetown
              </ListItem>
              <ListItem>
                Head of the Meadow Beach - Truro
              </ListItem>
              <ListItem>
                Marconi Beach - Wellfleet
              </ListItem>
              <ListItem>
                Race Point Beach - Provincetown
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontSize={18}
                fontWeight={"500"}
                my={2}
              >
                Other Town Stickers
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} textAlign="left" fontSize={16} my={3} lineHeight={8}>
            For town or beach specific information click below or call the town beach offices (phone numbers are at link).  Each link below will open a page with town-specific beach sticker information.
            <UnorderedList ml={{base:4,md:10}}>
              <ListItem>
                Brewster Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.brewster-ma.gov/beach-information'>www.brewster-ma.gov/beach-information</Link>
              </ListItem>
              <ListItem>
                Chatham Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.chatham-ma.gov/311/Sticker-Office'>www.chatham-ma.gov/311/Sticker-Office</Link>
              </ListItem>
              <ListItem>
                Dennis Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.town.dennis.ma.us/sticker-sales/pages/sticker-fee-schedule'>www.town.dennis.ma.us/sticker-sales/pages/sticker-fee-schedule</Link>
              </ListItem>
              <ListItem>
                Eastham Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.eastham-ma.gov/408/Beach-Sticker-Information'>www.eastham-ma.gov/408/Beach-Sticker-Information</Link>
              </ListItem>
              <ListItem>
                Harwich Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.harwich-ma.gov/treasurertown-collector/pages/beach-stickers-transfer-station-access'>www.harwich-ma.gov/treasurertown-collector/pages/beach-stickers-transfer-station-access</Link>
              </ListItem>
              <ListItem>
                Orleans Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.town.orleans.ma.us/595/Stickers-Fees-Eligibility'>www.town.orleans.ma.us/595/Stickers-Fees-Eligibility</Link>
              </ListItem>
              <ListItem>
                Truro Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.truro-ma.gov/beach-office/pages/beach-list-information'>www.truro-ma.gov/beach-office/pages/beach-list-information</Link>
              </ListItem>
              <ListItem>
                Wellfleet Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.wellfleet-ma.gov/beaches'>www.wellfleet-ma.gov/beaches</Link>
              </ListItem>
              <ListItem>
                Yarmouth Beaches:{' '}
                <Link color={'#e95037'} target={'_blank'} href='https://www.yarmouth.ma.us/464/Beach-Stickers'>www.yarmouth.ma.us/464/Beach-Stickers</Link>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>

  );
};

export { Beach };

