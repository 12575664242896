import {
  Badge,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";

import { removeFavorite, setFavorite } from "../../../helpers/fetch";

export const TopBar = () => {
  let { houseNumber } = useParams();
  const {
    houseData,
    userEmail,
    setUserEmail,
    favoritesLoading,
    numFavorites,
    setNumFavorites,
    userFavorites,
    emailLoading,
  } = useContext(SearchResultsContext);
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);

  // Check to see if favorite on page load
  useEffect(() => {
    const isHouseNumberInArray = userFavorites.some(
      (house) => house.houseNumber === houseNumber
    );
    setIsFavorite(isHouseNumberInArray);
    //console.log("isHouseNumberInArray", isHouseNumberInArray)
  }, [userFavorites]);

  const handleModalSubmit = async () => {
    localStorage.setItem("userEmail", emailInput);
    setUserEmail(emailInput);
    setShowFavoriteModal(false);

    if (!isFavorite) {
      try {
        await setFavorite(houseNumber, emailInput);
        setIsFavorite(true);
        //console.log("Added to favorites:", houseNumber);
      } catch (error) {
        console.error("Error setting favorite:", error);
        // Handle error (placeholder)
      }
    } else {
      try {
        await removeFavorite(houseNumber, emailInput);
        setIsFavorite(false);
        //console.log("Removed from favorites:", houseNumber);
      } catch (error) {
        console.error("Error removing favorite:", error);
        // Handle error (placeholder)
      }
    }
  };
  const { onOpen: onOpenReviewModal } = useDisclosure({
    id: "review-modal",
  });

  const { onOpen: onOpenShareModal } = useDisclosure({
    id: "share-modal",
  });

  const handleFavoriteClick = async (e) => {
    e.stopPropagation(); // Prevents the navigate function from firing when the icon is clicked

    if (!userEmail) {
      setShowFavoriteModal(true);
      return;
    }

    setIsFavorite(!isFavorite);

    try {
      if (!isFavorite) {
        await setFavorite(houseNumber, userEmail);
        setNumFavorites(numFavorites + 1);
      } else {
        // Remove from favorites
        await removeFavorite(houseNumber, userEmail);
        setNumFavorites(numFavorites - 1);
      }
    } catch (error) {
      console.error("Error handling favorite:", error);
      // Handle error (show a message to the user, rollback state changes, etc.)
    }
  };
  return (
    <>
      {/* Top Bar */}
      <Flex
        maxWidth="1440px"
        data-id="top-bar"
        width={"100%"}
        px={{ base: 4, md: 2 }}
        pb={2}
        pt={{ base: 0, md: 4 }}
        textAlign={"left"}
        alignItems={"start"}
        flexDirection={"column"}
      >
        <Flex
          fontSize={{ base: "18px", md: "24px" }}
          fontWeight={500}
          color={"#00000050"}
        >
          #{houseNumber}
          {
            <Text as="span" mx={2} color={"dmOrange.500"}>
              {" "}
              \{" "}
            </Text>
          }{" "}
          {houseData && houseData.name && houseData.name}
        </Flex>
        <Flex
          justifyContent={"space-between"}
          px={1}
          width={"100%"}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Flex flexDirection={{ base: "column", md: "row" }} gap={3}>
            {/* Listing Title */}
            <Flex
              mr={{ base: 8, md: 0 }}
              fontSize={{ base: "20px", md: "24px" }}
              mb={{ base: 2, md: 0 }}
              fontWeight={500}
            >
              {houseData.title}
            </Flex>
            {/* Rating and Review Count */}
            <Flex
              className="listing-reviews-parent"
              alignItems={"center"}
              justifyContent={{ base: "space-between" }}
            >
              {houseData.reviews > 0 ? (
                <Flex>
                  <Flex className="listing-rating">
                    {!isNaN(houseData.rating) && houseData.rating !== null
                      ? houseData.rating.toFixed(1)
                      : "0"}
                  </Flex>
                  <Image
                    src={"../media/ratingstar.png"}
                    alt="Home Rating"
                    objectFit={"contain"}
                    align={"left"}
                    width={"18px"}
                    height={"18px"}
                    className="listing-rating-star"
                    mx={1.5}
                  />
                  <Flex
                    className="listing-reviews"
                    fontSize={"14px"}
                    fontWeight={500}
                    pt={0.5}
                    as="u"
                    onClick={onOpenReviewModal}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    {!isNaN(houseData.reviews)
                      ? (houseData.reviews as number)
                      : 0}{" "}
                    Reviews
                  </Flex>
                </Flex>
              ) : (
                <Badge colorScheme="green">New Listing</Badge>
              )}

              {/* Share and Save Mobile */}
              <Flex display={{ base: "-webkit-box", md: "none" }}>
                {/* Share Icon */}
                <Flex
                  className="share-parent"
                  alignItems={"center"}
                  px={{ base: 0, md: 1 }}
                  onClick={onOpenShareModal}
                  _hover={{ cursor: "pointer" }}
                >
                  <Image
                    src={"../media/share.png"}
                    alt="Share Home"
                    objectFit={"contain"}
                    align={"left"}
                    width={"20px"}
                    height={"20px"}
                    className="share-icon"
                    mx={{ base: 0, md: 1.5 }}
                    mr={{ base: 1.5, md: 1.5 }}
                  />
                  <Flex
                    className="share-text"
                    fontSize={"14px"}
                    fontWeight={500}
                    pt={0.5}
                    as="u"
                    mr={1}
                  >
                    Share
                  </Flex>
                </Flex>
                {/* Save Icon */}
                <Flex
                  className="save-parent"
                  alignItems={"center"}
                  px={1}
                  _hover={{ cursor: "pointer" }}
                  onClick={handleFavoriteClick}
                >
                  {!favoritesLoading && !emailLoading && (
                    <>
                      {isFavorite ? (
                        <FaHeart color={"#e95037"} size="1.15em" />
                      ) : (
                        <FaRegHeart color={"black"} size="1.15em" />
                      )}
                      <Flex
                        className="save-text"
                        fontSize={"14px"}
                        fontWeight={500}
                        ml={1}
                        pt={0.5}
                        as="u"
                      >
                        Save
                      </Flex>
                    </>
                  )}
                </Flex>
              </Flex>
            </Flex>

            {/* Modal for Email Input for Favorites */}
            <Modal
              isOpen={showFavoriteModal}
              onClose={() => setShowFavoriteModal(false)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Enter Your Email</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Input
                    placeholder="Email address"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="dmOrange"
                    mb={2}
                    onClick={handleModalSubmit}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>

          {/* Share and Save Desktop */}
          <Flex display={{ base: "none", md: "-webkit-box" }}>
            {/* Share Icon */}
            <Flex
              className="share-parent"
              alignItems={"center"}
              px={{ base: 0, md: 1 }}
              onClick={onOpenShareModal}
              _hover={{ cursor: "pointer" }}
            >
              <Image
                src={"../media/share.png"}
                alt="Share Home"
                objectFit={"contain"}
                align={"left"}
                width={"20px"}
                height={"20px"}
                className="share-icon"
                mx={{ base: 0, md: 1.5 }}
                mr={{ base: 1.5, md: 1.5 }}
              />
              <Flex
                className="share-text"
                fontSize={"14px"}
                fontWeight={500}
                pt={0.5}
                as="u"
              >
                Share
              </Flex>
            </Flex>
            {/* Save Icon */}
            <Flex
              className="save-parent"
              alignItems={"center"}
              px={1}
              _hover={{ cursor: "pointer" }}
              onClick={handleFavoriteClick}
            >
              {!favoritesLoading && !emailLoading && (
                <>
                  {isFavorite ? (
                    <FaHeart color={"#e95037"} size="1.15em" />
                  ) : (
                    <FaRegHeart color={"black"} size="1.15em" />
                  )}
                  <Flex
                    className="save-text"
                    fontSize={"14px"}
                    fontWeight={500}
                    ml={1}
                    pt={0.5}
                    as="u"
                  >
                    Save
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* End Top Bar */}
    </>
  );
};
