import React, { useEffect } from 'react';
import config from '../config';

declare global {
  interface Window {
    initMap: () => void;
  }
}

const HouseMap: React.FC<{ latitude: number; longitude: number }> = ({ latitude, longitude }) => {
  useEffect(() => {
    window.initMap = () => {

      const generateRandomOffset = (degree = 0.0005) => {
        // Generates a random number between -degree and +degree
        return Math.random() * degree * 2 - degree;
      };

      const offsetLat = latitude + generateRandomOffset();
      const offsetLng = longitude + generateRandomOffset();

      const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        zoom: 14,
        center: { lat: offsetLat, lng: offsetLng },
      });

      new google.maps.Circle({
        strokeColor: '#e95037',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#e95037',
        fillOpacity: 0.35,
        map,
        center: { lat: offsetLat, lng: offsetLng },
        radius: 1000, // Adjust the radius as needed
      });
    };

    const scriptId = 'google-maps-script';
    let script = document.getElementById(scriptId) as HTMLScriptElement | null;

    // If the script is not already included, create it and append to the head
    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${config.mapKey}&callback=initMap`;
      script.async = true;
      document.head.appendChild(script);
    }

    return () => {
      window.initMap = () => {}; // Reset the initMap function
      // Only remove the script if it was created by this instance
      if (script && !document.getElementById(scriptId)) {
        document.head.removeChild(script);
      }
    };
  }, [latitude, longitude]);

  return (
    <div id="map" style={{ height: '400px', width: '100%' }}></div>
  );
};

export default HouseMap;