import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Box, Flex, Heading, Link,
  ListItem,
  OrderedList,
  Text
} from "@chakra-ui/react";
import { differenceInHours, format, parseISO } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { formatTime, reformatDateString } from '../../helpers/helpers';

type Props = {
  reservationData: any;
  onReportDamage?: () => void;
};

const CheckOutInfo: React.FC<Props> = ({
   reservationData, onReportDamage
}) => {

  // Set Time Zone To Eastern Time
  const ET = 'America/New_York';
  // Calculation of Time To Check In for Display of Door Code / Lockbox Directions
  const checkoutAt = zonedTimeToUtc(new Date(`${reservationData.checkoutDate} ${reservationData.checkoutTime}`), ET);
  const hoursToCheckOut = differenceInHours(new Date(), checkoutAt);
  // Formatting of Checkin and Checkout Times for Display on Reservation Widget
  let checkOutTimeFormatted = formatTime(reservationData.checkoutTime);

  // Formatting of Checkin and Checkout Dates for Display on Reservation Widget
  const formattedCheckoutDate = format(utcToZonedTime(parseISO(reservationData.checkoutDate), ET), 'MM/dd/yyyy');
  const formattedCheckoutDateNoTimeZone = reformatDateString(reservationData.checkoutDate);

  const dateDescription = getFormattedDateDescription(formattedCheckoutDateNoTimeZone);

  // Function to get the date description for the checkout date
  function getFormattedDateDescription(checkoutDateString) {
    // Parse the checkout date string
    const [month, day, year] = checkoutDateString.split('/');
    const checkoutDate = new Date(year, month - 1, day);
  
    // Get today's date without time
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Calculate the difference in days
    const diffTime = checkoutDate.getTime() - today.getTime();
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
  
    if (diffDays === 1) {
      // If the checkout is tomorrow
      return "tomorrow";
    } else if (diffDays > 0 && diffDays <= 5) {
      // If the checkout is within the next 5 days
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return "on "+daysOfWeek[checkoutDate.getDay()];
    } else {
      // Otherwise, return the original date string
      return "on "+checkoutDateString;
    }
  }

  return (
    <Flex direction='column' alignItems={'center'}>

      <Heading mt={4} mb={2} fontSize={24} w={'100%'} maxW={{base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}>
        Check-Out Date and Time
      </Heading>
      <Flex
        w={"100%"} maxW={{base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}
        justifyContent={"start"}
        flexDirection={'row'}
        gap={2}
        pb={10} ps={{base:0,md:0}}
        fontSize={{base:30,md:36}} fontWeight={'700'}
      >
        <Box>{formattedCheckoutDateNoTimeZone}</Box>
        <Box>{checkOutTimeFormatted}</Box>
      </Flex>

      {/* Accordion Group */}
      <Flex direction='column' pb={10}>
        <Text fontSize={24} fontWeight={'700'} pb={3} textAlign={'left'} 
          maxW={{base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}
        >
          Check-Out Checklist
        </Text>
        <Accordion
          defaultIndex={[0,1,3,4,5]}
          allowMultiple
          maxW={{base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}
          width={{ base: "90vw" }}
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={18}
                  fontWeight={"500"}
                  my={2}
                >
                  Check-Out Time
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left" fontSize={16} mb={3} pt={0} lineHeight={8}>
              Your support in departing by {checkOutTimeFormatted} {dateDescription} helps the hardworking cleaning team for your home avoid rescheduling complications. Thank you!
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={18}
                  fontWeight={"500"}
                  my={2}
                >
                  Check Out in Three Easy Steps
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left" fontSize={16} mb={3} pt={0} lineHeight={8}>
              <OrderedList ml={10}>
                <ListItem><b>Sheets & Towels:</b> Please remove the sheets and pillowcases from all used beds and leave them in a pile on the bedroom floor. Leave all used towels on the bathroom floor.  After stripping the sheets, it would be so helpful if you could lay the pillows and comforters neatly on the beds.</ListItem>
                <ListItem><b>Dishes:</b> Please do your dishes/run the dishwasher.</ListItem>
                <ListItem><b>Trash:</b> Dispose of any remaining trash in the outdoor cans.</ListItem>
              </OrderedList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={18}
                  fontWeight={"500"}
                  my={2}
                >
                  Refundable Deposit
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left" fontSize={16} mb={3} pt={0} lineHeight={8}>
              Your deposit will be returned within 10-14 days if the home is left in a clean and tidy condition and without unreported damages. Please note that a portion of your deposit may be withheld if the bulleted checklist below is not completed upon your departure.
            </AccordionPanel>
          </AccordionItem>
          {onReportDamage &&
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={18}
                    fontWeight={"500"}
                    my={2}
                  >
                    Accidental Damage
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} textAlign="left" fontSize={16} mb={3} pt={0} lineHeight={8}>
                Report any damage prior to departing & you'll be covered for up to $500 in accidental damage.{' '}
                <Link
                  onClick={onReportDamage}
                  isExternal
                  className="underline no-underline-hover"
                  fontWeight={"500"}
                >
                  Report damage here.
                </Link>
              </AccordionPanel>
            </AccordionItem>
      }
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={18}
                  fontWeight={"500"}
                  my={2}
                >
                  Forgotten Personal Items
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left" fontSize={16} mb={3} pt={0} lineHeight={8}>
              Please be sure to collect all personal items. Items left behind that are found and returned to our office may be requested for return with a minimum shipping charge of $25. Note: prescription medication cannot be shipped and will be disposed of as necessary.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={18}
                  fontWeight={"500"}
                  my={2}
                >
                  Linens, Dishes, and Trash Instructions
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left" fontSize={16} mb={3} pt={0} lineHeight={8}>
              Before you leave, please remove the sheets and pillowcases from all used beds and leave them in a pile on the bedroom floor. Please leave all used towels on the bathroom floor.
              <br/><br/>
              After stripping the sheets, it would be helpful and appreciated if you could lay the pillows and comforters neatly on the beds.
              <br/><br/>
              Please do your dishes/ run the dishwasher before departing. Please take out your trash and leave them in the trash barrels. The location of the trash barrels can be found in your Pre-Arrival Details.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
      {/* End Accordion Group */}

    </Flex>
  );
};

export { CheckOutInfo };

