import { Button, Flex, Link, Text } from "@chakra-ui/react";
export const NotAcceptedBlock = () => (
  <Flex
    position={"relative"}
    width={"full"}
    data-id={"listing"}
    p={{ base: 2, md: 5 }}
    justifyContent={"center"}
    flexDirection={"column"}
    alignItems={"center"}
    minH={"calc(100vh - 236px)"}
  >
    <Text fontSize="xl" py={12}>
      This house is not currently accepting reservations.
    </Text>
    <Link href="/">
      <Button colorScheme="dmOrange" size="lg" minW={"250px"}>
        Start New Search
      </Button>
    </Link>
  </Flex>
);
