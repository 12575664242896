import {
  Button,
  Flex,
  FormLabel,
  Input,
  Text
} from '@chakra-ui/react';

import { useSignIn } from "@clerk/clerk-react";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect') || '/'; // Replace '/default-redirect-path' with a default path

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [code, setCode] = useState("")
  const [successfulCreation, setSuccessfulCreation] = useState(false)
  const [complete, setComplete] = useState(false)
  const [secondFactor, setSecondFactor] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { isLoaded, signIn, setActive } = useSignIn()

  const navigate = useNavigate();

  useEffect(() => {
    if (complete) {
      navigate(redirectUrl || '/'); // Fallback to home page ('/') if redirectUrl is not provided
    }
  }, [complete, navigate, redirectUrl]);

  if (!isLoaded) return null

  async function create(e: SyntheticEvent) {
    e.preventDefault()

    await signIn
      ?.create({
        strategy: "reset_password_email_code",
        identifier: email
      })
      .then(_ => {
        setErrorMessage(null)
        setSuccessfulCreation(true)
      })
      .catch(err => {
        const errorToDisplay = err?.errors[0]?.longMessage
        setErrorMessage(mapError(errorToDisplay))
        console.error("create error", errorToDisplay, "mapped error", mapError(errorToDisplay))
      });
  }

  async function reset(e: SyntheticEvent) {
    e.preventDefault();

    await signIn
      ?.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code,
        password
      })
      .then(result => {
        setErrorMessage(null)
        if (result.status === "needs_second_factor") {
          setSecondFactor(true)
        } else if (result.status === "complete") {
          setActive({ session: result.createdSessionId })
          setComplete(true)
        } else {
          //console.log(result)
        }
      })
      .catch(err => {
        const errorToDisplay = err?.errors[0]?.longMessage
        setErrorMessage(mapError(errorToDisplay))
        console.error("signIn error", errorToDisplay, "mapped error", mapError(errorToDisplay))
      });
  }

  function mapError(error) {
    switch (error) {
      case "Identifier is invalid.":
        return "Invalid Email Address";
      case "`reset_password_email_code` isn't allowed for `strategy` when user's password is not set.":
        return "You previously used social sign in. Please return to the previous window and sign in with Google or Facebook.";
      case "You're currently in single session mode. You can only be signed into one account at a time. mapped error Password reset error. Please try again later.":
        return "You are already signed in.";
      case "Password has been found in an online data breach.  For account safety, please use a different password.":
        return "Password has been found in an online data breach.  For account safety, please use a different password.";
      default:
        return("Password reset error. Please try again later.")
    } 
  }

  return (
    <Flex
      as="form"
      w="100%"
      maxW="500px"
      flexDirection="column"
      gap={4}
      onSubmit={!successfulCreation ? create : reset}
      margin="auto"
      py={8}
      minH="60vh"
    >
  
      {errorMessage !== null && (
        <Text fontWeight="500" color="red.500" mb={2}>
          Error: {errorMessage}
        </Text>
      )}
  
      {!successfulCreation && !complete && (
        <>
          <Text fontWeight="bold" fontSize="xl" textAlign="center" my={4}>Forgot Your Password?</Text>
          <Flex flexDirection="column" gap={2}>
            <FormLabel htmlFor="email">Please enter your email address</FormLabel>
            <Input
              id="email"
              type="email"
              placeholder="e.g john@doe.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Flex>
          <Button w="100%" colorScheme="dmOrange" mt={4} type="submit">Sign in</Button>
        </>
      )}
  
      {successfulCreation && !complete && (
        <>
          <Flex flexDirection="column" gap={2} mt={4}>
            <Text>We just sent a Reset Password Code to your email. Input the Code and your New Password below.</Text>
          </Flex>
          <Flex flexDirection="column" gap={2} mt={4}>
            <FormLabel htmlFor="resetCode">Reset Password Code</FormLabel>
            <Input
              id="resetCode"
              type="text"
              value={code}
              onChange={e => setCode(e.target.value)}
              autoComplete='off'
            />
          </Flex>
          <Flex flexDirection="column" gap={2}>
            <FormLabel htmlFor="newPassword">New Password</FormLabel>
            <Input
              id="newPassword"
              type="newPassword"
              value={password}
              onChange={e => setPassword(e.target.value)}
              autoComplete='off'
            />
          </Flex>
          
          <Button w="100%" colorScheme="dmOrange" mt={4} type="submit">Reset</Button>
        </>
      )}
  
      {complete && <Text>You have successfully changed your password.</Text>}
      {secondFactor && <Text>Two-factor authentication is required. This UI does not handle that.</Text>}
  
    </Flex>
  );
}

export { ForgotPassword };

