import { useUser } from "@clerk/clerk-react";
import { addDays, differenceInHours } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import * as React from "react";
import { createContext, useContext, useState } from 'react';
import { eventBus } from "../helpers/EventBus";
import { fetchReservations } from "../helpers/guestportalfetch";

export interface TripsContextData {
    allTripsData: any;
    updateAllTrips: () => Promise<void>;
    selectedTrip: any;
    setSelectedTrip: React.Dispatch<React.SetStateAction<any[]>>;
    currentFutureTrips: any;
    setCurrentFutureTrips: React.Dispatch<React.SetStateAction<any[]>>;
    currentFutureCount: number | null;
    setCurrentFutureCount: React.Dispatch<React.SetStateAction<number | null>>;
    pastTrips: any;
    setPastTrips: React.Dispatch<React.SetStateAction<any[]>>;
    isTripsContextLoaded: Boolean;
    setTripsContextLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    isTripsContextValid: Boolean;
    setTripsContextValid: React.Dispatch<React.SetStateAction<boolean>>;
  }
  
  const defaultContextData: TripsContextData = {
    allTripsData: null,
    updateAllTrips: async () => { /* default function implementation */ },
    selectedTrip: null,
    setSelectedTrip: async () => { },
    currentFutureTrips: null,
    setCurrentFutureTrips: async () => { },
    currentFutureCount: 0,
    setCurrentFutureCount: () => { },
    pastTrips: null,
    setPastTrips: async () => { },
    isTripsContextLoaded: false,
    setTripsContextLoaded: () => { },
    isTripsContextValid: null,
    setTripsContextValid: () => { },
  };

const TripsContext = createContext<TripsContextData>(defaultContextData);


export const useTrips = () => useContext(TripsContext);

export const TripsProvider = ({ children }) => {
  const [allTripsData, setAllTripsData] = useState(null);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [currentFutureTrips, setCurrentFutureTrips] = useState(null);
  const [currentFutureCount, setCurrentFutureCount] = useState(null);
  const [pastTrips, setPastTrips] = useState(null);
  const [isTripsContextLoaded, setTripsContextLoaded] = useState(false);
  const [isTripsContextValid, setTripsContextValid] = useState(null);

  const { user } = useUser();
      
  const updateAllTrips = async () => {

    //console.log('updating trips context')
    const userEmail = user.primaryEmailAddress.emailAddress;

    try {
      const responseReservations = await fetchReservations({
        tenantEmail: userEmail,
      });
      let usersReservations = await responseReservations.json();

      usersReservations = usersReservations
        .filter((reservation) => {
          return (
            reservation.house &&
            (reservation.status === "Booked" ||
              reservation.status === "Modified" || 
              reservation.status === "Closed")
          );
        })

      for (let i = 0; i < usersReservations.length; i++) {
        let reservation = usersReservations[i];
        const checkoutAt = `${reservation.checkoutDate} ${reservation.checkoutTime}`;
        const hoursToCheckOut = differenceInHours(new Date(checkoutAt), new Date());
        //console.log('hoursToCheckOut',hoursToCheckOut)

        if (hoursToCheckOut < 24) {
          //console.log("true",usersReservations[i])
          usersReservations[i]['disableReview'] = true;
        } else {
          //console.log("false",usersReservations[i])
          usersReservations[i]['disableReview'] = false;
        }
      }
      
      setAllTripsData([...usersReservations]);
      
      setTripsContextLoaded(true);
      //console.log('all trips',usersReservations)
      
  
      const easternTz = 'America/New_York';

      // Get the current date and time in Eastern Time
      const now = new Date();
      const nowInEasternTime = utcToZonedTime(now, easternTz);
      
      // Calculate the cutoff time for moving reservations to the past category
      const cutoffTimeForPastReservations = addDays(nowInEasternTime, -1);
      
      // Convert the cutoff time back to UTC for comparison
      const cutoffTimeUtc = zonedTimeToUtc(cutoffTimeForPastReservations, easternTz);
      
      // Filter for current or future reservations
      const currentFutureReservationsArray = usersReservations.filter(reservation => {
        const checkoutDateTimeString = `${reservation.checkoutDate}T${reservation.checkoutTime}:00`;
        const checkoutDateTimeInEasternTime = zonedTimeToUtc(checkoutDateTimeString, easternTz);
        
        // A reservation is current or future if the checkout time is before the cutoff time
        return checkoutDateTimeInEasternTime >= cutoffTimeUtc;
      });
      
      // Filter for past reservations
      const pastReservationsArray = usersReservations.filter(reservation => {
        const checkoutDateTimeString = `${reservation.checkoutDate}T${reservation.checkoutTime}:00`;
        const checkoutDateTimeInEasternTime = zonedTimeToUtc(checkoutDateTimeString, easternTz);
      
        // A reservation is past if the checkout time is before the cutoff time
        return checkoutDateTimeInEasternTime < cutoffTimeUtc;
      });

      //console.log("currentFutureReservationsArray",currentFutureReservationsArray,"pastReservationsArray",pastReservationsArray)
  
      if (currentFutureReservationsArray.length > 0) {
        setCurrentFutureCount(currentFutureReservationsArray.length)
        setCurrentFutureTrips(currentFutureReservationsArray)
        eventBus.dispatch("houseData", {
          detail: {
            houseNumber: currentFutureReservationsArray[0].house.houseNumber,
            reservationNumber: currentFutureReservationsArray[0].reservationNumber,
          },
        });
      }
      //console.log("pastReservations.length",pastReservationsArray.length)
      if (pastReservationsArray.length > 0) {
        setPastTrips(pastReservationsArray);
      }
      //console.log("currentFutureReservations",currentFutureReservations,"pastReservations",pastReservations)
    } catch (error) {
      //console.error("Error fetching reservations and houses data:", error);
    }
  };

  return (
    <TripsContext.Provider value={{ 
      allTripsData, updateAllTrips,
      selectedTrip, setSelectedTrip,
      currentFutureTrips, setCurrentFutureTrips,
      currentFutureCount, setCurrentFutureCount,
      pastTrips, setPastTrips,
      isTripsContextLoaded, setTripsContextLoaded, 
      isTripsContextValid, setTripsContextValid 
    }}>
      {children}
    </TripsContext.Provider>
  );
};
