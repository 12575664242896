import { Alert, AlertIcon, Button, Flex, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import _ from 'lodash';
import { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import { useLocation, useSearchParams } from 'react-router-dom';
import { getRecentAgreements } from "../../helpers/agreementService";
import { formatTimestamp } from '../../helpers/helpers';

import { StaticDogAgreement } from "../confirmation/StaticDogAgreement";
import { StaticGuestAgreement } from "../confirmation/StaticGuestAgreement";

const GuestAgreements = ({ reservationNumber, displayType="Guest", rebook }) => {
  const { pathname } = useLocation();
  const [isLoadingAgreements, setIsLoadingAgreements] = useState(false);
  const [agreements, setAgreements] = useState(null);
  const [resNumber, setResNumber] = useState(reservationNumber);
  const [searchParams, setSearchParams] = useSearchParams();

  const agreementApiKey = searchParams.get('apikey');

  const navigate = useNavigate();

  useEffect(() => {
    setResNumber(reservationNumber);

    setIsLoadingAgreements(true);

    getRecentAgreements(reservationNumber)
      .then(fetchedAgreements => {
        if (fetchedAgreements && fetchedAgreements.length > 0) {
          setAgreements(fetchedAgreements);
          //console.log("fetched agreements", fetchedAgreements);
        } else {
          //console.log('No agreements found for this reservation number.');
          //console.log(agreements.length)
          setAgreements([]);
        }
      })
      .catch(error => {
        //console.error('Error fetching agreements:', error);
        setAgreements(null);
      })
      .finally(() => {
        setIsLoadingAgreements(false);
        
      });
    
  }, [pathname, agreementApiKey]); // Only pathname is a dependency now

  //console.log("agreements",agreements)

  const renderAgreementsTabs = (categoryAgreements) => (
    <Tabs variant="enclosed-colored" isLazy defaultIndex={displayType==='Dog' ? 1 : 0} w={'100%'}>
      <TabList>
        {categoryAgreements.map((agreement, index) => (
          <Tab key={index} fontSize={{base:'xs',md:'md'}} p={4}>
            {`${_.startCase(_.toLower(agreement.type))} Agreement ${formatTimestamp(agreement.signedAt)}`}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {categoryAgreements.map((agreement, index) => (
          <TabPanel key={index} px={0}>
            {agreement.type === 'guest' ?
              <StaticGuestAgreement reservationNumber={resNumber} agreementData={agreement} rebook={rebook} />
              :
              <StaticDogAgreement reservationNumber={resNumber} agreementData={agreement} />
            }
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );

  
  // Render helper function to decide what to render
  const renderContent = () => {
    if (isLoadingAgreements) return <Spinner />;
  
    if (!resNumber || agreements === null) {
      return (
        <Flex py={8} mb={'100px'} fontSize={'20px'} w={'100%'} flexDirection={'column'}>
          <Alert w={'100%'} status='warning'>
            <AlertIcon />
              No Agreements Found. Please complete your Guest Agreement.
          </Alert>
          <Button size={'lg'} w={'300px'} margin={'36px auto 12px auto'} colorScheme={'dmOrange'}
            onClick={() => navigate(`/confirmation/${reservationNumber}`)}
          >Complete Guest Agreement</Button>
        </Flex>
      );
    }
  
    if (Array.isArray(agreements) && agreements.length === 0) {
      return (
        <Flex py={8} mb={'100px'} fontSize={'20px'} w={'100%'} flexDirection={'column'}>
          <Alert w={'100%'} status='info'>
            <AlertIcon />
              No agreements have been signed for this reservation.
          </Alert>
          <Button size={'lg'} w={'300px'} margin={'36px auto 12px auto'} colorScheme={'dmOrange'}
            onClick={() => navigate(`/confirmation/${reservationNumber}`)}
          >Complete Guest Agreement</Button>
        </Flex>
      );
    }
  
    // Find the most recent guest and dog agreements
    const mostRecentGuestAgreement = agreements.reduce((latest, agreement) => {
      return (agreement.type === 'guest' && (!latest || agreement.signedAt > latest.signedAt)) ? agreement : latest;
    }, null);
  
    const mostRecentDogAgreement = agreements.reduce((latest, agreement) => {
      return (agreement.type === 'dog' && (!latest || agreement.signedAt > latest.signedAt)) ? agreement : latest;
    }, null);
  
    const currentAgreements = [mostRecentGuestAgreement, mostRecentDogAgreement].filter(Boolean);
    const archivedAgreements = agreements.filter(a => a !== mostRecentGuestAgreement);
  

    return (
      currentAgreements.length > 0 ? renderAgreementsTabs(currentAgreements) : <p>No current agreements.</p>
    );
  };

  return (
    <Flex
      position={"relative"}
      width={"full"}
      data-id={"listing"}
      p={{ base: 2, md: 5 }}
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      {/* Top Bar */}
      <Flex maxWidth='1124px' width={'100%'} p={2} textAlign={'left'} alignItems={'start'} flexDirection={'column'}>
        {renderContent()}
      </Flex>
    </Flex>
  );
  
}

export { GuestAgreements };

