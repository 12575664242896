import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useToast
} from "@chakra-ui/react";
import { useAuth } from '@clerk/clerk-react';
import { Field, Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { findReservation } from "../../helpers/guestportalfetch";
import { SingleDatepicker } from "./DatePicker";

type Props = {
  reservationData: any;
  modalInitiateOpen: boolean;
  onModalClose: Function;
  setReservationSearchSuccessful: Function;
  reservationSearchSuccessful: Boolean;
};

const ReservationLookup: React.FC<Props> = ({
  reservationData,
  modalInitiateOpen,
  onModalClose,
  setReservationSearchSuccessful,
  reservationSearchSuccessful
}) => {
  const [modalOpen, setModalState] = React.useState(modalInitiateOpen);

  function openModal() {
    setModalState(true);
  }
  function closeModal() {
    setModalState(false);
  }

  function modalClose() {
    closeModal();
    onModalClose();
  }

  const { getToken } = useAuth();

  React.useEffect(() => {
    setModalState(modalInitiateOpen);
  }, [modalInitiateOpen]);

  const [checkInDate, setCheckInDate] = React.useState(new Date()); // Check-In Date Picker
  const [noReservationsFoundMessage, setNoReservationsFoundMessage] = React.useState(false); // Error Message - No Reservations Found
  const [existingPrimaryGuestMessage, setExistingPrimaryGuestMessage] = React.useState(false); // Error Message - Reservation Already Primary Associated
  const [obfuscatedEmail, setObfuscatedEmail] = React.useState(false); // Error Message - Email of Reservation Already Primary Associated
  const toast = useToast()

  const reservationLookupValidationSchema = Yup.object().shape({
    confirmationNumber: Yup.string().required("Confirmation number required")
  });

  return (
    <>
      {/* Reservation Lookup Modal */}
      <Modal isOpen={modalOpen} onClose={() => void 0} isCentered size="4xl">
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent borderRadius="none" mx="4">
          <ModalCloseButton size="lg" onClick={modalClose} />
          <ModalBody padding="0">
            <Flex align="center">
              <Flex
                direction="column"
                align="center"
                flex="1"
                py="24"
                px={{ base: "2", md: "4" }}
              >
                <Box maxW="3xl" mx="auto">
                  <Box
                    textAlign="center"
                    maxW={{ base: "full", sm: "3xl" }}
                    mx={{ base: "3", md: "auto" }}
                    mt="2"
                  >
                    <Heading fontWeight="extrabold">
                      Let's help you find your reservation. <br />
                    </Heading>
                    
                    <Text
                      fontSize={{ base: "md", md: "lg" }}
                      textAlign={{ base: "left", md: "left" }}
                      mt="10"
                      mb="5"
                      lineHeight={1.7}
                    >
                      Enter your <u><b>Check-in Date</b></u> and <u><b>Del Mar Vacations Confirmation Code</b></u>, which can be found in your Guest Portal Welcome email.
                    </Text>

                    <Formik
                      initialValues={{
                        checkInDate: checkInDate,
                        confirmationNumber: null
                      }}
                      validationSchema={reservationLookupValidationSchema}
                      onSubmit={async (values) => {
                        //console.log(values);

                        const convertToLocalDateISOString = (date) => {
                          const offset = date.getTimezoneOffset();
                          const localDate = new Date(date.getTime() - offset * 60 * 1000);
                          return localDate.toISOString().slice(0, 10);
                        };
                        
                        const fields = {
                          confirmationNumber: values.confirmationNumber,
                          checkInDate: convertToLocalDateISOString(values.checkInDate)
                        };

                        //console.log(fields);
                      
                        // Pass the token to findReservation
                        let result = await findReservation(fields).then((response) => response.json());
                       
                        if(result.message == 'success'){
                          modalClose();
                          setReservationSearchSuccessful(!reservationSearchSuccessful);
                        }
                        else if(result.message == 'no reservations found'){
                          setNoReservationsFoundMessage(true);
                          setExistingPrimaryGuestMessage(false);
                        }
                        else if(result.message == 'existing primary'){
                          setExistingPrimaryGuestMessage(true);
                          setObfuscatedEmail(result.email);
                          setNoReservationsFoundMessage(false);
                        }
                      }}
                    >
                      {({ values, handleChange, errors, touched, isSubmitting }) => (
                        <Form>
                          {isSubmitting && <Spinner />}
                          <Flex py="4" width={'100%'}>
                            <Flex flexDirection={"column"} pr="2" pt="1" flexGrow={1}>
                              <Text
                                textAlign={"left"}
                                fontWeight="semibold"
                                fontSize={{base:14,sm:18}}
                              >
                                Check-in Date
                                <span className="red-text"> *</span>
                              </Text>
                              <Field name="checkInDate">
                                {({ field, form }) => (
                                  <SingleDatepicker
                                    name="checkInDate"
                                    date={checkInDate}
                                    onDateChange={(date) => {
                                      form.setFieldValue(
                                        field.name,
                                        date.toISOString().slice(0, 10)
                                      );
                                      setCheckInDate(date);
                                    }}
                                  />
                                )}
                              </Field>
                            </Flex>
                            <Flex flexDirection={"column"} pr="2" pt="1" flexGrow={1}>
                              <Text
                                textAlign={"left"}
                                fontWeight="semibold"
                                fontSize={{base:14,sm:18}}
                              >
                                Confirmation Code
                                <span className="red-text"> *</span>
                              </Text>
                              <Field name="confirmationNumber">
                                {({ field, form }) => (
                                  <Input onChange={handleChange} id="confirmationNumber" name="confirmationNumber" placeholder="Del Mar Confirmation Code" />
                                )}
                              </Field>
                              <Flex className="form-error">
                                {errors.confirmationNumber &&
                                touched.confirmationNumber ? (
                                  <div>{errors.confirmationNumber as string}</div>
                                ) : null}
                              </Flex>
                            </Flex>
                          </Flex>
                          <Flex
                            w={"100%"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                            pt={4}
                          >
                            <Button
                              type="submit"
                              disabled={isSubmitting}
                              className="dm-button-orange-pill"
                              w={{ base: "100%", lg: "100%" }}
                              boxShadow="base"
                            >
                              Find my reservation
                            </Button>
                          </Flex>
                          {noReservationsFoundMessage &&
                            <Text
                              fontSize={{ base: "md", md: "lg" }}
                              textAlign={{ base: "left", md: "left" }}
                              color={'red'}
                              mt="6"
                              mb="3"
                            >
                              No reservation was found with the information you entered. Please double check your check-in date and confirmation code. 
                            </Text>
                          }
                          {existingPrimaryGuestMessage &&
                            <Text
                              fontSize={{ base: "md", md: "lg" }}
                              textAlign={{ base: "left", md: "left" }}
                              color={'red'}
                              mt="6"
                              mb="3"
                            >
                              A reservation was found already linked to an existing user account with an email address of {' '}{obfuscatedEmail}.
                            </Text>
                          }
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* End Reservation Lookup Modal */}
    </>
  );
};

export { ReservationLookup };

