import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
  UserProfile,
  useClerk,
  useUser,
} from "@clerk/clerk-react";
import * as React from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HelpModal from "../components/HelpModal";
import SearchWidget from "../components/blocks/search/SearchWidget";
import { GuestServicesPopOver } from "../components/guestportal/GuestServicesPopOver";
import { SearchResultsContext } from "../contexts/SearchResultsContext";
import { useTrips } from "../contexts/TripContext";
import { eventBus } from "../helpers/EventBus";
import { fetchSupportManager } from "../helpers/guestportalfetch";
import { ReactComponent as DelMarLogoDarkText } from "../media/logo.svg";

const Layout = () => {
  const toast = useToast();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { numFavorites } = React.useContext(SearchResultsContext);
  const { selectedTrip, currentFutureTrips, isTripsContextLoaded, allTripsData, setSelectedTrip, updateAllTrips } = useTrips();

  const {
    isOpen: isHelpModalOpen,
    onOpen: onOpenHelpModal,
    onClose: onCloseHelpModal,
  } = useDisclosure();

  const { isLoaded, isSignedIn, user } = useUser();
  const { signOut } = useClerk();
  const [rebookReservation, setRebookReservation] = React.useState(null);
  const [listenerSet, setListenerSet] = React.useState(false);
  const [tripView, setTripView] = React.useState(pathname.includes("/trips"));
  let displayNavBar = true;
  let narrowNavBar = false;

  if (pathname.includes("/agreements")) {
    displayNavBar = false;
  }

  if (/\/\d{5}(\/|$)/.test(pathname) || pathname.includes("/confirmation")) {
    narrowNavBar = true;
  }

  /// Rebook Mode Variables ///
  const [showBanner, setShowBanner] = React.useState(false);
  const [rebookMode, setRebookMode] = React.useState(false);

  React.useEffect(() => {
    // Check localStorage to see if rebook mode is active
    const rebookParameter = localStorage.getItem("dm-rebook-mode");
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const currentTime = Date.now();

    if (rebookParameter) {
      const rebookTimestamp = parseInt(rebookParameter, 10);
      if (currentTime - rebookTimestamp < oneDayInMilliseconds) {
        setRebookMode(true);
        setShowBanner(true);
      }
    }

    // Handling for first-time rebook mode activation
    const urlParams = new URLSearchParams(window.location.search);
    const rebookMode = urlParams.get("rebook");
    if (rebookMode) {
      localStorage.setItem("dm-rebook-mode", Date.now().toString());
      setRebookMode(true);
      setShowBanner(true);
    }

  }, []);
  /// END Rebook Mode Variables ///

  React.useEffect(() => {
    if (isLoaded && isSignedIn && !localStorage.getItem("loginEventFired")) {
      // User has logged in
      if ("dataLayer" in window) {
        (window.dataLayer as any[]).push({
          event: "clerk_login",
        });
      }
      // Set a flag in local storage
      localStorage.setItem("loginEventFired", "true");
      //console.log("Log In Event Fired");
      //console.log(user);
    }

    // Used to determine whether to display Del Market in the menu
    if (isLoaded && isSignedIn) {
      if (!selectedTrip) updateAllTrips();

      if (currentFutureTrips && currentFutureTrips.length > 0) {
        // Sort the trips by checkinDate
        currentFutureTrips.sort((a, b) => new Date(a.checkinDate).getTime() - new Date(b.checkinDate).getTime());
        
        // Grab the trip with the earliest checkinDate
        const earliestTrip = currentFutureTrips[0];
        
        // Set the selectedTrip to the earliest trip
        setSelectedTrip(earliestTrip);
        // console.log("Selected Trip Set to Earliest Trip", earliestTrip);
      }
      if (allTripsData && allTripsData.length > 0) {
        // Find the latest reservation that is not in the future (currently active or most recent past one)
        const currentDate = new Date();
        const pastAndCurrentTrips = allTripsData.filter(trip => new Date(trip.checkoutDate) <= currentDate);
        
        if (pastAndCurrentTrips.length > 0) {
          // Sort past trips by checkoutDate in descending order to find the most recent one
          pastAndCurrentTrips.sort((a, b) => new Date(b.checkoutDate).getTime() - new Date(a.checkoutDate).getTime());
          const latestPastAndCurrentTrip = pastAndCurrentTrips[0];
          
          // Set the rebook reservation to the latest past trip
          setRebookReservation(latestPastAndCurrentTrip);
        }
      }
    }
       
  }, [user, isSignedIn, isLoaded]);

  React.useEffect(() => {
    if (!listenerSet) {
      eventBus.on("reportDamage", (e) => {
        //console.log(e);
        onPopoverOpen();
      });
      setListenerSet(true);
    }

    setTripView(pathname.includes("/trips") || pathname.includes("/details"));
  }, [pathname]);

  const {
    isOpen: isPopoverOpen,
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
  } = useDisclosure();
  const {
    isOpen: isProfileModalOpen,
    onOpen: onProfileModalOpen,
    onClose: onProfileModalClose,
  } = useDisclosure();
  const {
    isOpen: isSupportOpen,
    onOpen: onSupportOpen,
    onClose: onSupportClose,
  } = useDisclosure(); // Support Modal

  const signOutToast = () => {
    toast({
      title: "Signed out",
      description: "You are now logged out.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  // Support Manager Data

  interface Manager {
    name: string;
    title: string;
    avatar: string;
  }
  const [manager, setManager] = React.useState<Manager | null>(null);

  let userEmail = "";

  React.useEffect(() => {
    const fetchData = async () => {
      if (user && isSignedIn) {
        let userEmail = user.primaryEmailAddress.emailAddress;
        try {
          const response = await fetchSupportManager({
            tenantEmail: userEmail,
          });
          const managerInfo = await response.json();
          setManager(managerInfo[0]);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    fetchData();
  }, [user]);

  const NonTypedSignInButton = SignInButton as any;

  return (
    <>
      <Box
        bg={"white"}
        px={0}
        boxShadow={"md"}
        className="topNavContainer"
        position={"sticky"}
        zIndex={"1000"}
      >
        {rebookMode && showBanner && (
          <Box borderBottomWidth="1px" bg="green.300" py={{ base: 3, md: 1 }}>
            <HStack
              spacing={{ base: "3", md: "4" }}
              justify={{ base: "start", md: "space-between" }}
            >
              <Box boxSize="8" display={{ base: "block", md: "block" }} />
              <Text color="fg.emphasized" fontWeight="medium">
                🎉 <b>Exclusive Offer:</b> Pay a non-refundable $300 deposit
                today, with flexible changes. Remaining balance in 2 equal
                payments at 6 and 2 months prior to check-in. Terms apply.
              </Text>
              <IconButton
                icon={<FiX />}
                variant="tertiary"
                aria-label="Close banner"
                onClick={() => {
                  setShowBanner(false);
                }}
              />
            </HStack>
          </Box>
        )}

        <Flex
          px={4}
          h={20}
          display={displayNavBar ? "flex" : "none"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexGrow={1}
          maxW={narrowNavBar ? 1440 : "full"}
          margin={"0px auto"}
          w="100%"
        >
          {/* Del Mar logo on left of the navbar */}
          <Flex
            alignItems={"center"}
            className="logo-container"
            display={{ base: "none", md: "block" }}
          >
            <Link href="/">
              <Box pl={3}>
                <DelMarLogoDarkText />
              </Box>
            </Link>
          </Flex>

          {/* SearchWidget component in /components/block/search/SearchWidget.tsx */}
          <Flex
            alignItems={"center"}
            height={"100%"}
            color={"white"}
            className={"searchWidgetContainer"}
            ml={{ base: 0, md: 2 }}
            gap={3}
          >
            <Image
              src="/media/InitialsLogoDM.png"
              h="100%"
              onClick={() => window.open("/", "_self")}
              py={"22px"}
              px={1}
              display={{ base: "block", md: "none" }}
            />
            <SearchWidget />
            <Button
              onClick={tripView ? onSupportOpen : onOpenHelpModal}
              className="navHelpButton"
              display={{ base: "none", md: "flex" }}
              color={"dmNavy.500"}
              bg={""}
              justifyContent={"center"}
              alignItems={"center"}
              _hover={{ background: "#D3D3D3" }}
              borderRadius="28px"
              whiteSpace={"normal"}
            >
              <Flex gap={3} justifyContent={"center"} alignItems={"center"}>
                <Box display={{ base: "none", lg: "flex" }}>
                  <BsFillQuestionCircleFill
                    color={"#0F2238"}
                    width={"16px"}
                    height={"16px"}
                  />
                </Box>
                <Flex
                  fontWeight={"normal"}
                  textAlign={{ base: "center", lg: "start" }}
                  lineHeight={"22px"}
                >
                  {tripView ? "Get Support" : "Need Help Booking?"}
                </Flex>
              </Flex>
            </Button>

            <HelpModal isOpen={isHelpModalOpen} onClose={onCloseHelpModal} />

            <Modal
              isOpen={isSupportOpen}
              onClose={() => void 0}
              isCentered
              size={"lg"}
            >
              <ModalOverlay
                bg="blackAlpha.700"
                backdropFilter="blur(10px) hue-rotate(90deg)"
              />
              <ModalContent borderRadius="none" mx="0">
                <ModalCloseButton size="lg" onClick={onSupportClose} />
                <ModalBody padding="0">
                  {manager &&
                    isSignedIn &&
                    isTripsContextLoaded &&
                    selectedTrip && (
                      <GuestServicesPopOver
                        onPopoverClose={onSupportClose}
                        userEmail={userEmail}
                        contactOwner={manager}
                        houseNumber={selectedTrip.houseNumber}
                        reservationNumber={selectedTrip.reservationNumber}
                      />
                    )}
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>

          <Flex justifyContent={"center"} gap={3} alignItems={"center"}>
            {/* Help Popover */}

            <Modal isOpen={isProfileModalOpen} onClose={onProfileModalClose}>
              <ModalOverlay />
              <ModalContent>
                <UserProfile />
              </ModalContent>
            </Modal>

            {/* More content when user is signed in/out */}
            <Flex alignItems={"center"} display={{ base: "none", md: "flex" }}>
              <Menu placement="bottom-end">
                <Flex className="menu-container" alignItems={"center"} p={1}>
                  <MenuButton
                    size={"lg"}
                    as={Button}
                    leftIcon={<HamburgerIcon />}
                    aria-label={"Open Menu"}
                    bg="none"
                    border="solid 1px #00000038"
                    color={"dmNavy.500"}
                    borderRadius="28px"
                    mr={1}
                    _hover={{
                      base: { background: "none" },
                      md: { background: "#D3D3D3" },
                    }}
                    _active={{ background: "#D3D3D3" }}
                    pl={3}
                    pr={1}
                    m={0}
                    minH={"40px"}
                    h={"40px"}
                  >
                    <SignedIn>
                      <UserButton
                        showName={false}
                        appearance={{
                          elements: {
                            avatarBox: { border: "solid 1px white" },
                            userButtonOuterIdentifier: {
                              color: "white",
                              fontFamily: "azo-sans-web",
                              fontSize: "16px",
                              fontWeight: "normal",
                            },
                          },
                        }}
                      />
                    </SignedIn>
                    <SignedOut>
                      <Avatar size="sm" />
                    </SignedOut>
                  </MenuButton>

                  <MenuList>
                    <MenuItem onClick={() => navigate("/trips")}>
                      My Trips
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        window.location.href = "/favorites";
                      }}
                    >
                      Favorites{" "}
                      <Box as="span" ml={2}>
                        <FaHeart color={"#e95037"} size="1.15em" />
                      </Box>
                      {numFavorites && numFavorites > 0 ? (
                        <Box as="span" ml={1}>
                          ({numFavorites})
                        </Box>
                      ) : (
                        <Box as="span" ml={1}>
                          (0)
                        </Box>
                      )}
                    </MenuItem>
                    {rebookReservation &&
                    <MenuItem onClick={() => navigate(`/rebook/${rebookReservation.reservationNumber}`)}>
                      Rebook
                    </MenuItem>
                    }
                    <MenuDivider />
                    
                    <MenuItem
                      onClick={() =>
                        window.open(
                          "https://delmarvacations.myshopify.com/collections/products",
                          "_blank"
                        )
                      }
                    >
                      <Flex direction={"column"} gap={0} alignItems={"start"}>
                        <Text>Del Merch</Text>
                        <Text fontSize={"12px"}>
                          Gifts and style for your wardrobe
                        </Text>
                      </Flex>
                    </MenuItem>
                    {selectedTrip &&
                      <MenuItem
                        onClick={() =>
                          navigate(`/store/${selectedTrip.reservationNumber}`)
                        }
                      >
                        <Flex direction={"column"} gap={0} alignItems={"start"}>
                          <Text>Del Market</Text>
                          <Text fontSize={"12px"}>
                            Amenities for your vacation
                          </Text>
                        </Flex>
                      </MenuItem>
                    }
                    <MenuItem
                      display={{ base: "block", md: "none" }}
                      onClick={onOpenHelpModal}
                      mt={2}
                    >
                      Get help with booking
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem>
                      <SignedIn>
                        {/* Mount the UserButton component */}
                        <Flex
                          onClick={onProfileModalOpen}
                          gap={3}
                          alignItems={"center"}
                        >
                          <Text onClick={onProfileModalOpen}>
                            My Profile
                          </Text>
                          <UserButton
                            showName={false}
                            appearance={{
                              elements: {
                                avatarBox: { border: "none" },
                                userButtonOuterIdentifier: {
                                  color: "white",
                                  fontFamily: "azo-sans-web",
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                },
                              },
                            }}
                          />
                        </Flex>
                      </SignedIn>
                      <SignedOut>
                        <Flex
                          color={"black"}
                          _hover={{ cursor: "pointer" }}
                          w={"100%"}
                        >
                          <NonTypedSignInButton
                            mode="modal"
                            className="signinbuttonchakra"
                            w={"100%"}
                          >
                            <Flex gap={4} alignItems={"center"} w={"100%"}>
                              <Text>Sign In</Text>
                            </Flex>
                          </NonTypedSignInButton>
                        </Flex>
                      </SignedOut>
                    </MenuItem>
                    <SignedIn>
                      <MenuItem
                        onClick={() => {
                          signOut();
                          signOutToast();
                        }}
                      >
                        <Flex gap={3} alignItems={"center"}>
                          <Text>Sign Out</Text>
                        </Flex>
                      </MenuItem>
                    </SignedIn>
                  </MenuList>
                </Flex>
              </Menu>
            </Flex>
          </Flex>
        </Flex>
      </Box>

      <Outlet />
    </>
  );
};

export default Layout;
