import {
  Box,
  Button,
  Flex,
  IconButton, IconButtonProps, Image,
  Link,
  Text, useColorModeValue
} from "@chakra-ui/react";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";


// Function to collapse text based on max length
export const CollapsibleText = ({ text, maxChar = 350 }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const endOfText = text.length > maxChar && isCollapsed;

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box whiteSpace="pre-wrap">
      {endOfText ? text.slice(0, maxChar) + '...' : text}
      {endOfText && (
        <Link color="blue.500" onClick={toggleCollapse} pl={1} fontWeight={500}>
          Show more
        </Link>
      )}
      {!isCollapsed && text.length > maxChar && (
        <Link color="blue.500" onClick={toggleCollapse} pl={1} fontWeight={500}>
          Show less
        </Link>
      )}
    </Box>
  );
};


// Component for Full Screen Image View
export const FullScreenImageView = ({ image, onClose, onNext, onPrevious }) => (
  <Flex direction="column" align="center" justify="center" bg="black" w="100vw" h="100vh" position="fixed" top="0" left="0" zIndex={'501'}>
    <Image src={image?.original} alt={`Full view of ${image?.caption}`} maxW="80%" maxH="80%" />
    <Text position={'absolute'} bottom={8} color="white">{image?.caption}</Text>
    <Flex position="absolute" top="50%" w="100%" justify="space-between">
      <CarouselIconButton
        onClick={onPrevious}
        icon={<BsChevronLeft />}
        aria-label="Previous Image"
      />
      <CarouselIconButton
        onClick={onNext}
        icon={<BsChevronRight />}
        aria-label="Next Image"
      />
    </Flex>
    <Button position="absolute" top="10px" right="10px" onClick={onClose}>Close</Button>
  </Flex>
);


// Full Screen View Carousel Button Styling
export const CarouselIconButton = (props: IconButtonProps) => (
  <IconButton
    fontSize="lg" isRound mx={3} boxShadow="base"
    bg={useColorModeValue("white", "gray.800")}
    _hover={{ bg: useColorModeValue("gray.300", "gray.700"), }}
    _active={{ bg: useColorModeValue("gray.400", "gray.600"), }}
    _focus={{ boxShadow: "inherit" }}
    _focusVisible={{ boxShadow: "outline" }}
    {...props}
  />
);