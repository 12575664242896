import Cookies from "js-cookie";
import config from "../config";

const defaultHeaders = () => {
  const token = Cookies.get("__session");
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const API_ENDPOINT = config.apiEndpoint;


export const getAllProducts = async (reservationNumber:string) => {
  //console.log("Retrieving all products");
  try {
    const response = await fetch(`${API_ENDPOINT}/shopify/getAllProducts?reservationNumber=${reservationNumber}`, {
      method: "GET",
      headers: {
        ...defaultHeaders(),
        'Content-Type': 'application/json'
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error retrieving store products from Shopify", error);
  }
}


export const generateDateOptions = (checkinDate, checkoutDate) => {
  const startDate = new Date(checkinDate);
  const endDate = new Date(checkoutDate);
  const dateOptions = [];

  while (startDate < endDate) {
    // Increment the date by one day
    startDate.setDate(startDate.getDate() + 1);

    // Convert the date to the desired format: "Month day, year"
    const formattedDate = startDate.toLocaleDateString('en-US', {
      month: 'long', // Full name of the month
      day: 'numeric', // Numeric day of the month
      year: 'numeric' // Four digit year
    });

    dateOptions.push(formattedDate);
  }

  return dateOptions;
};



export const getReservationCart = async (reservationNumber:string) => {
  //console.log("Retrieving cart for reservation number", reservationNumber);
  try {
    const response = await fetch(`${API_ENDPOINT}/shopify/getCart?reservationNumber=${reservationNumber}`, {
      method: "GET",
      headers: {
        ...defaultHeaders(),
        'Content-Type': 'application/json'
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error retrieving cart", error);
  }
}


export const addCartItem = async (reservationNumber, merchandiseId, quantity, attributes = []) => {
  //console.log("Adding item to cart for reservation number", reservationNumber);
  try {
    const body = {
      reservationNumber,
      item: {
        merchandiseId,
        quantity,
        attributes,
      },
    };

    const response = await fetch(`${API_ENDPOINT}/shopify/addCartLineItem`, {
      method: "POST",
      headers: defaultHeaders(),
      body: JSON.stringify(body), // Convert the JavaScript object to a JSON string
    });

    const addResponse = await response.json();
    //console.log("addResponse", addResponse);
    return addResponse;
  } catch (error) {
    console.error("Error adding item to cart", error);
  }
}


export const updateCartLineItem = async (reservationNumber, itemId, quantity) => {
  //console.log("Updating cart line item for reservation number", reservationNumber, "item", itemId, "quantity", quantity);
  try {
    const body = {
      reservationNumber,
      item: {
        id: itemId,
        quantity,
      },
    };

    const response = await fetch(`${API_ENDPOINT}/shopify/updateCartLineItem`, {
      method: "POST",
      headers: defaultHeaders(),
      body: JSON.stringify(body),
    });

    const updateResponse = await response.json();
    //console.log("updateResponse", updateResponse);
    return updateResponse;
  } catch (error) {
    console.error("Error updating cart line item", error);
  }
};


export const removeCartLineItem = async (reservationNumber, itemId) => {
  //console.log("Removing cart line item for reservation number", reservationNumber);
  try {
    const body = {
      reservationNumber,
      itemId,
    };

    const response = await fetch(`${API_ENDPOINT}/shopify/removeCartLineItem`, {
      method: "POST",
      headers: defaultHeaders(),
      body: JSON.stringify(body),
    });

    return await response.json();
  } catch (error) {
    console.error("Error removing cart line item", error);
  }
};


export const getOrders = async (reservationNumber:string) => {
  //console.log("Retrieving orders for reservation number", reservationNumber);
  try {
    const response = await fetch(`${API_ENDPOINT}/orders/reservation/${reservationNumber}`, {
      method: "GET",
      headers: {
        ...defaultHeaders(),
        'Content-Type': 'application/json'
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error retrieving orders", error);
  }
}