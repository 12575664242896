import { useUser } from '@clerk/clerk-react';
import { useLocation, useNavigate } from 'react-router-dom';

const SignedInParamHandler = () => {
  
  const { isSignedIn, isLoaded } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
{/*
  useEffect(() => {
    if (isLoaded && isSignedIn) {
      const urlParams = new URLSearchParams(location.search);
      if (!urlParams.has('signedin')) {
        urlParams.append('signedin', 'true');
        navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
      }
    }
  }, [isSignedIn, isLoaded, location, navigate]);
*/}
  return null;
  
};

export default SignedInParamHandler;
