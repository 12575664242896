import { ChakraProvider, ColorModeScript, Flex } from "@chakra-ui/react";
import {
  ClerkProvider,
  useClerk,
  useSignIn,
  useUser,
} from "@clerk/clerk-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import SignedInParamHandler from "./components/SignedInParamHandler";
import config from "./config";
import { ReservationProvider } from "./contexts/ReservationContext";
import { SearchResultsContext } from "./contexts/SearchResultsContext";
import { TripsProvider } from "./contexts/TripContext";
import { Agreements } from "./pages/Agreements";
import { Checkout } from "./pages/Checkout";
import { CheckoutNew } from "./pages/CheckoutNew";
import { Confirmation } from "./pages/Confirmation";
import { Explore } from "./pages/Explore";
import { Favorites } from "./pages/Favorites";
import { ForgotPassword } from "./pages/ForgotPassword";
import Layout from "./pages/Layout";
import { Listing } from "./pages/Listing";
import { Places } from "./pages/Places";
import { PreArrivalGuide } from "./pages/PreArrivalGuide";
import { QRCodes } from "./pages/QRCodes";
import { Quote } from "./pages/Quote";
import { Rebook } from "./pages/Rebook";
import { Review } from "./pages/Review";
import { Share } from "./pages/Share";
import { Store } from "./pages/Store";
import { Trips } from "./pages/Trips";
import Footer from "./pages/footer";
import customTheme from "./theme";

import "./styles/styles.scss";

const ProtectedRoute = ({ children }) => {
  const { isSignedIn, isLoaded } = useUser();
  const { openSignIn } = useClerk();
  const location = useLocation();

  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      openSignIn({
        redirectUrl: window.location.origin + location.pathname,
      });
    }
  }, [isSignedIn, isLoaded, openSignIn, location.pathname]);

  if (!isLoaded) {
    return <Flex w={"100%"} h={"80vh"} />; // Placeholder for your loading component
  }

  return isSignedIn ? children : null;
};

type UseUserReturn = ReturnType<typeof useUser>;
type UserResource = UseUserReturn["user"];

const PreserveQueryParamsRedirect = ({ to }) => {
  const { search } = useLocation();
  return <Navigate to={`${to}${search}`} replace />;
};

const clerk_pub_key = config.clerkKey;
const signInUrl = config.signInUrl;

export const useAppData = () => {
  const [reservationData, setReservationData] = useState([]);
  return { reservationData, setReservationData };
};

const AppContext = createContext({
  currentComponent: null,
  setCurrentComponent: (value: string | null) => {},
  redirect: null,
  setRedirect: (value: string | null) => {},
});

export const useAppContext = () => useContext(AppContext);

interface AppProviderProps {
  children?: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [currentComponent, setCurrentComponent] = useState<string | null>(null);
  const [redirect, setRedirect] = useState<string | null>(null);

  return (
    <AppContext.Provider
      value={{ currentComponent, setCurrentComponent, redirect, setRedirect }}
    >
      {children}
    </AppContext.Provider>
  );
};

function WithValidHouseNumber({ children }) {
  const { houseNumber } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  if (
    !/^\d{5}$/.test(houseNumber) &&
    queryParams.get("pointofsale") !== "google"
  ) {
    return <Navigate to="/" replace />;
  }

  return children;
}

const handleEmailTokenSignIn = async (
  token,
  signIn,
  setActive,
  setIsSignedIn
) => {
  try {
    const res = await signIn.create({
      strategy: "ticket",
      ticket: token,
    });
    await setActive({ session: res.createdSessionId });
    setIsSignedIn(true);
  } catch (error) {
    console.error("Error during email token sign-in:", error);
    setIsSignedIn(false);
  }
};

const EmailTokenHandler = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const { signIn, setActive } = useSignIn();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      handleEmailTokenSignIn(token, signIn, setActive, setIsSignedIn);
    }
  }, [signIn, setActive]);

  return null;
};

export const App = () => {
  const [searchResults, setSearchResults] = React.useState([]);
  const [flexSearchResults, setFlexSearchResults] = React.useState(false);
  const [totalResults, setTotalResults] = React.useState<number>();
  const [bufferLoading, setBufferLoading] = React.useState(null);
  const [loading, setLoading] = React.useState(true); // Initialize as true to show skeletons first
  const [contextDateRange, setContextDateRange] = React.useState([]);
  const [guests, setGuests] = React.useState<number | null>();
  const [dogs, setDogs] = React.useState<boolean>();
  const [houseData, setHouseData] = React.useState(null);
  const [houseCalendar, setHouseCalendar] = React.useState(null);
  const [priceSearch, setPriceSearch] = React.useState(null);
  const [maxSearchPriceValue, setMaxSearchPriceValue] = React.useState<
    number | null
  >();
  const [minSearchPriceValue, setMinSearchPriceValue] = React.useState<
    number | null
  >();
  const [queryOffset, setQueryOffset] = React.useState(0);
  const [pathingFilterSettings, setPathingFilterSettings] = React.useState({});
  const [userFavorites, setUserFavorites] = React.useState([]);
  const [favoritesLoading, setFavoritesLoading] = React.useState(true);
  const [numFavorites, setNumFavorites] = React.useState<number | null>();
  const [userEmail, setUserEmail] = React.useState(null);
  const [emailLoading, setEmailLoading] = React.useState(true);
  const [globalCheckIns, setGlobalCheckIns] = React.useState(null);
  const [globalCalendarLoading, setGlobalCalendarLoading] =
    React.useState(true);
  const [searchWithDates, setSearchWithDates] = React.useState(false);
  const [boundingBox, setBoundingBox] = React.useState(null);
  const [boundingBoxMoved, setBoundingBoxMoved] = React.useState(false);
  const [triggerSearch, setTriggerSearch] = React.useState(0);
  const [mapAreaSearch, setMapAreaSearch] = React.useState(false);
  const [town, setTown] = React.useState(null);
  const [mapDisplayOnLoad, setMapDisplayOnLoad] = React.useState(true);
  const [randomResults, setRandomResults] = React.useState(false);
  const [activeQuoteNumber, setActiveQuoteNumber] = React.useState(null);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setLoading(false);
    }
  }, [searchResults]);

  useEffect(() => {
    // Function to check and set the dm-attribution from URL parameters
    const setAttributionFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const attribution = urlParams.get("source");
      const rebookMode = urlParams.get("rebook");
      if (attribution) {
        localStorage.setItem("dm-attribution", attribution);
      }
      if (rebookMode) {
        localStorage.setItem("dm-rebook-mode", Date.now().toString());
      }
    };

    setAttributionFromUrl();

    // Optional: Listen for changes in the URL
    window.addEventListener("popstate", setAttributionFromUrl);

    // Cleanup listener when component unmounts
    return () => {
      window.removeEventListener("popstate", setAttributionFromUrl);
    };
  }, []);

  const advancedMatching = {
    em: "",
    fn: "",
    ln: "",
    ph: "",
    ge: "",
    db: "",
    ct: "",
    st: "",
    zp: "",
    country: "",
  };

  const options = {
    autoConfig: true,
    debug: true,
  };

  ReactPixel.init("1976313969100219", advancedMatching, options);
  ReactPixel.pageView();

  return (
    <ChakraProvider theme={customTheme}>
      <ColorModeScript />
      <BrowserRouter>
        <ClerkProvider
          publishableKey={clerk_pub_key}
          appearance={{
            variables: {
              fontFamily: "azo-sans-web",
              borderRadius: "6px",
            },
          }}
        >
          <SignedInParamHandler />
          <EmailTokenHandler />
          <SearchResultsContext.Provider
            value={{
              searchResults,
              setSearchResults,
              flexSearchResults,
              setFlexSearchResults,
              totalResults,
              setTotalResults,
              queryOffset,
              setQueryOffset,
              loading,
              setLoading,
              bufferLoading,
              setBufferLoading,
              contextDateRange,
              setContextDateRange,
              guests,
              setGuests,
              dogs,
              setDogs,
              houseData,
              setHouseData,
              houseCalendar,
              setHouseCalendar,
              priceSearch,
              setPriceSearch,
              maxSearchPriceValue,
              setMaxSearchPriceValue,
              minSearchPriceValue,
              setMinSearchPriceValue,
              pathingFilterSettings,
              setPathingFilterSettings,
              userFavorites,
              setUserFavorites,
              favoritesLoading,
              setFavoritesLoading,
              numFavorites,
              setNumFavorites,
              userEmail,
              setUserEmail,
              emailLoading,
              setEmailLoading,
              globalCheckIns,
              setGlobalCheckIns,
              globalCalendarLoading,
              setGlobalCalendarLoading,
              searchWithDates,
              setSearchWithDates,
              boundingBox,
              setBoundingBox,
              boundingBoxMoved,
              setBoundingBoxMoved,
              triggerSearch,
              setTriggerSearch,
              mapAreaSearch,
              setMapAreaSearch,
              town,
              setTown,
              mapDisplayOnLoad,
              setMapDisplayOnLoad,
              randomResults,
              setRandomResults,
              activeQuoteNumber,
              setActiveQuoteNumber,
            }}
          >
            <TripsProvider>
              <Layout />
              <ReservationProvider>
                {/* Appends a signedin=true query parameter to the URL when the user is signed in. HubSpot chat widget is configured to be hidden if the URL path contains this param. */}
                <Routes>
                  <Route index element={<Explore />} />
                  <Route
                    path="/properties/*"
                    element={<PreserveQueryParamsRedirect to="/" />}
                  />
                  <Route
                    path="/listings/*"
                    element={<PreserveQueryParamsRedirect to="/" />}
                  />
                  <Route
                    path="/search-results/*"
                    element={<PreserveQueryParamsRedirect to="/" />}
                  />
                  <Route path="/favorites" element={<Favorites />} />
                  <Route
                    path="/:houseNumber"
                    element={
                      <WithValidHouseNumber>
                        <Listing />
                      </WithValidHouseNumber>
                    }
                  />
                  <Route
                    path="/:houseNumber/checkout"
                    element={
                      <WithValidHouseNumber>
                        <Checkout />
                      </WithValidHouseNumber>
                    }
                  />

                  <Route
                    path="/:houseNumber/payment"
                    element={
                      <WithValidHouseNumber>
                        <CheckoutNew />
                      </WithValidHouseNumber>
                    }
                  />
                  {/* Forgot Password Page */}
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/forgotpassword" element={<ForgotPassword />} />
                  <Route
                    path="confirmation/:reservationNumber"
                    element={<Confirmation />}
                  />
                  <Route path="confirmation" element={<Confirmation />} />
                  <Route
                    path="agreements/:reservationNumber"
                    element={<Agreements />}
                  />
                  <Route path="agreements" element={<Agreements />} />

                  {/* Quotes Page */}
                  <Route path="/quotes/" element={<Quote />} />
                  <Route path="/quotes/:quoteNumber" element={<Quote />} />

                  {/* QR Codes Page */}
                  <Route path="/qr/:encoding" element={<QRCodes />} />

                  {/* All Trips or Selected Trip Details if Reservation Number*/}
                  <Route
                    path="/trips/:paramReservationNumber?"
                    element={
                      <ProtectedRoute>
                        <Trips />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/prearrivalguide/:paramReservationNumber?"
                    element={<PreArrivalGuide />}
                  />
                  <Route
                    path="/places/:paramReservationNumber?"
                    element={
                      <ProtectedRoute>
                        <Places />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/discounts/:paramReservationNumber?"
                    element={
                      <ProtectedRoute>
                        <Places />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/review/:paramReservationNumber"
                    element={<Review />}
                  />
                  <Route
                    path="/share/:paramReservationNumber"
                    element={
                      <ProtectedRoute>
                        <Share />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/rebook/:paramReservationNumber"
                    element={
                      <ProtectedRoute>
                        <Rebook />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/store/:paramReservationNumber"
                    element={<Store />}
                  />
                  <Route path="/store/" element={<Store />} />
                  <Route path="*" element={<Explore key={Date.now()} />} />
                </Routes>
              </ReservationProvider>
              <Footer />
            </TripsProvider>
          </SearchResultsContext.Provider>
        </ClerkProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};
