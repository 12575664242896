import { Alert, AlertIcon, Box, Button, Checkbox, Flex, FormControl, FormLabel, Grid, GridItem, Icon, Input, InputGroup, InputLeftElement, Stack, Text } from "@chakra-ui/react";
import { Field, Form, Formik } from 'formik';
import { RefObject, useRef } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import 'react-phone-number-input/style.css';
import { calculateDaysBetweenDates, formatCurrency, formatDateTimeString, formatTimestamp } from '../../helpers/helpers';
import HouseRules from './HouseRules';

const StaticGuestAgreement = ({reservationNumber, agreementData, rebook = false}) => {

	const printRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

	// Reservation Summary Rows
	const InfoRow = ({ label, value }) => (
		label === 'Booked Via' && agreementData.channel === 'Standard' ?
			<></>
		: label === 'Spacer' ?
			<Box height="10px" /> // Adjust the height as needed for the spacer
		: !label ?
			<Grid templateColumns="7fr" gap={2}>
				<Text>{value}</Text>
			</Grid>
		: label === 'Booked Occupants' ?
			<Grid templateColumns="3fr 4fr" gap={2}>
				<Text fontWeight={500}>{label}:</Text>
				<Flex gap={2} alignItems={'top'}>
					<Text>{value}</Text>
				</Flex>
			</Grid>
		:
			<Grid templateColumns="3fr 4fr" gap={2}>
				<Text fontWeight={500}>{label}:</Text>
				<Text>{value}</Text>
			</Grid>
	);

  // Splitting data into two columns
  const firstColumnData = [
    { label: "Confirmation #", value: reservationNumber },
		{ label: "Spacer" },
    { label: "Max Occupants", value: agreementData.maxOccupants},
		{ label: "Bedrooms", value: agreementData.bedrooms},
		{ label: "Bathrooms", value: agreementData.bathrooms},
		{ label: "Spacer" },
		{ label: "Booked Occupants", value: agreementData.bookedOccupants},
  ];

	// Define a helper function to format the second column data based on reservationData.typename
	const formatSecondColumnData = () => {
		const baseData = [
			{ 
				label: "Check-In Date", 
				value: agreementData.checkinDate && formatDateTimeString(agreementData.checkinDate, agreementData.checkinTime)
			},
			{ 
				label: "Check-Out Date", 
				value: agreementData.checkoutDate && formatDateTimeString(agreementData.checkoutDate, agreementData.checkoutTime)
			},
			{ label: "Spacer" },
		];

		// If reservationData.typename indicates a direct booking or BE-API
		if (["manual", "Direct", "BE-API", "direct", "website", "be-api", "standard"].includes(agreementData.channel.toLowerCase())) {
			// Add the standard pricing details
			baseData.push(
				{ label: `${agreementData.numNights} Nights`, value: formatCurrency(agreementData.nightlyRates) },
				{ label: "Taxes and Fees", value: formatCurrency(agreementData.totalPrice - agreementData.nightlyRates) },
				{ label: "Total Price", value: formatCurrency(agreementData.totalPrice) }
			);
		} else if (agreementData.channel.includes("VRBO")) {
			// For VRBO bookings, only show total price and a specific message
			baseData.push(
				{ label: "Total Price", value: formatCurrency(agreementData.totalPrice) },
				{ label: "Spacer" },
				{ label: "Note", value: "Since you booked via VRBO, you are also charged a booking fee by VRBO that Del Mar Vacations does not collect and therefore is not reflected in the above total." }
			);
		} else {
			// For other third-party bookings, display a generic message
			baseData.push(
				{ label: "Note", value: "Since you booked through a third party booking channel, Del Mar Vacations does not collect your rental payments. Please refer back to your booking site for payment terms and information." }
			);
		}

		return baseData;
	};

	const secondColumnData = formatSecondColumnData();

	const typenameMapper = (typename) => {
		switch(typename.toLowerCase()) {
			case 'airbnb2':
				return "Airbnb";
			case 'manual':
			case 'direct':
			case 'be-api':
			case 'website':
				return "Direct";
			case 'vrbo':
			case 'uploaded_vrbo':
				return "VRBO";
			case 'booking.com':
				return "Booking.com";
			case 'homeaway us':
			case 'homeaway ca':
			case 'homeaway de':
			case 'homeaway cafr':
				return "HomeAway";
			default:
				return typename;
		}
	}

	const handleSubmitPlaceholder = () => { console.log("Submit") }

	const handlePrint = () => {
		const printFrame = document.getElementById("printFrame") as HTMLIFrameElement;
		if (printRef.current && printFrame && printFrame.contentWindow) {
			const printStyles = `
				<style>
					@media print {
						.house-rules-container {
							max-height: 100%;
							overflow: visible;
						}
						.chakra-input {
							opacity: 1 !important;
							border: solid 1px #CDCDCD !important;
						}
						.chakra-input:disabled {
							border: solid 1px #CDCDCD !important;
						}
						.print-agreement {
							display: none;
						}
						body, h1, h2, h3, div { 
							color: #000 !important; /* Ensures text is black */
						}
					}
				</style>
			`;
	
			const printContent = printRef.current.innerHTML;
	
			// Copy existing styles from the document's head, excluding script tags
			const docHead = document.head.innerHTML.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "");
			const headContent = docHead + printStyles; // Combine the existing head content with the new print styles
	
			// Open the document of the iframe
			printFrame.contentWindow.document.open();
			printFrame.contentWindow.document.write(`
				<html>
					<head>${headContent}</head>
					<body>${printContent}</body>
				</html>
			`);
			printFrame.contentWindow.document.close();
	
			// Set the onload function to ensure the iframe is fully loaded before printing
			printFrame.onload = () => {
				printFrame.contentWindow.focus();
				printFrame.contentWindow.print();
				printFrame.onload = null; // Cleanup after printing
			};
		} else {
			console.error('Unable to access the print content or frame.');
		}
	};
	

	return (
		<Flex
			position={"relative"}
			width={"full"}
			data-id={"listing"}
			p={{ base: 0, md: 5 }}
			justifyContent={'center'}
			flexDirection={'column'}
			alignItems={'center'}
			ref={printRef}
		>

			<Flex maxWidth='1440px' width={'100%'} p={2} textAlign={'left'} alignItems={'start'} flexDirection={'column'}>

				<Button onClick={handlePrint} className='print-agreement' colorScheme={'dmOrange'} size="md" mb="4" alignSelf={'end'} display={'none'}>
					Print Agreement
				</Button>

				{/* Hidden iframe for printing */}
				<iframe id="printFrame" style={{ display: 'none' }} title="Print Frame"></iframe>

				<Text pb={3} w={'100%'} textAlign={'center'} fontSize={'1.4em'} fontWeight={'700'}>
					{agreementData.firstName} {agreementData.lastName}'s Vacation Rental Agreement for
				</Text>
				<Text pb={6} w={'100%'} textAlign={'center'} fontSize={'1.1em'} >
					#{agreementData.houseNumber} {agreementData.houseName} at {agreementData.address ? agreementData.address.split(', United States')[0] : ''}
				</Text>

				{/* Reservation Summary */}
				<Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} 
					gap={{md:8,lg:4,xl:2}} fontSize={'0.9em'}
					width={'100%'}
				>
					<GridItem pb={{base:3, md:0}}>
						{firstColumnData.map((item, index) => (
							<InfoRow key={index} label={item.label} value={item.value} />
						))}
					</GridItem>
					<GridItem>
						{secondColumnData.map((item, index) => (
							<InfoRow key={index} label={item.label} value={item.value} />
						))}
					</GridItem>
				</Grid>
				{/* END Reservation Summary */}

				{(["manual", "direct", "website", "be-api", "standard"].includes(agreementData.channel.toLowerCase())) ?
					<>
						{rebook ? (
							<>
								<Alert status='success' mt={6} fontSize={'0.9em'} fontWeight={500}><AlertIcon />By rebooking directly with Del Mar, you saved 10-20% in fees as compared to if you had booked on 3rd party websites! Same Local Service; Same Homes; Lower Costs!</Alert>
								<Text fontWeight={"bold"} pt={4} fontSize={"0.9em"}>
									Del Mar VIP Vacation Pass Member Payment Schedule:
								</Text>
								<Text pt={2} fontSize={"0.9em"} fontWeight={"bold"} >Reservation Rebook 6+ Months in Advance</Text>
								<Text pt={0} fontSize={"0.9em"}>You will have 3 scheduled payments. The first payment is $300, due upon booking. The second payment 50% of the remaining balance, due 180 days prior check in. The third and final payment is the remaining balance, due 60 days prior to check in.</Text>
								<Text pt={2} fontSize={"0.9em"} fontWeight={"bold"} >Reservation Rebook Less Than 6 Months in Advance</Text>
								<Text pt={0} fontSize={"0.9em"}>Upon booking confirmation, 51% of the total reservation cost will be charged to the credit card on file. The remaining balance will be charged 60 days prior to the check-in date. If the reservation is booked within 60 days of check-in, the total reservation cost will be charged in full upon booking confirmation.</Text>
							</>
							) : (
							<>
								<Alert status='success' mt={6} fontSize={'0.9em'} fontWeight={500}><AlertIcon />By booking directly with Del Mar, you saved 10-20% in fees as compared to if you had booked on 3rd party websites! Same Local Service; Same Homes; Lower Costs!</Alert>
								<Text pt={4} fontSize={'0.9em'} fontWeight={500}>Payment Schedule:</Text>
								<Text fontSize={'0.9em'}>Upon booking confirmation, 51% of the total reservation cost will be charged to the credit card on file. The remaining balance will be charged 60 days prior to the check-in date. If the reservation is booked within 60 days of check-in, the total reservation cost will be charged in full upon booking confirmation.</Text>
							</>
						)}
					</>
					:
					<>
						<Text pt={6} fontSize={'0.9em'} fontWeight={500}>Booked Via: {typenameMapper(agreementData.channel)}</Text>
						<Text pt={1} fontSize={'0.9em'} fontWeight={500}>Payment Schedule:</Text>
						<Text fontSize={'0.9em'}>Since you booked with a 3rd party website, your payment schedule is according to their posted terms of service.</Text>
					</>
				}

				{agreementData.dogFriendly ?
					<>
						<Text pt={6} fontSize={'0.9em'} fontWeight={500}>Pet Policy:</Text>
						<Text fontSize={'0.9em'}>This home is dog friendly (no other animals are permitted), but requires payment for a dog guest unless a licensed Service Animal.  Please confirm and pay for dogs on the next page or in your Guest Portal and review and adhere to all dog rules.</Text>
					</>
					:
					<>
						<Text pt={6} fontSize={'0.9em'} fontWeight={500}>No Animals Permitted:</Text>
						<Text fontSize={'0.9em'}>This home is not authorized for dogs or animals of any kind. A fine of $1,000 and removal of your pet will occur for non-compliance. Service animals unfortunately are also not authorized at this home - however, if you have a documented Service Animal then it is authorized at one of our Dog Friendly homes and we will waive the Dog Fee but you must provide notice and documentation. Please call us to change to a dog or service animal friendly home.</Text>
					</>
				}

				<Formik
					initialValues={{
						firstName: agreementData.firstName || '',
						lastName: agreementData.lastName || '',
						email: agreementData.email || '',
						phone: agreementData.phone || '',
						street: agreementData.street || '',
						city: agreementData.city || '',
						state: agreementData.state || '',
						zip: agreementData.zip || '',
						noDogAgreement: true
					}}
					onSubmit={handleSubmitPlaceholder}
				>
				{({ errors, touched, setFieldValue, setFieldTouched }) => (
					<>
					<Form>
						<Stack spacing="3">
						{/* Disclaimer text */}
						<Text fontSize={'0.9em'} pt={3}>
							<i>By clicking the submit button below, I, the undersigned, hereby apply for the above listed accommodation and warrant that I have read, understand and agree to the terms and conditions of this application, and I agree to abide by house rules and homeowners/agents/association restrictions on use of said property. I further understand and represent that I am executing this application on behalf of all parties on the premises during the dates of my stay.</i>
						</Text>

						{/* Name fields */}
						<Stack direction={{ base: 'column', md: 'row' }}>
							<Field name="firstName">
							{({ field, form }) => (
								<FormControl isInvalid={form.errors.firstName && form.touched.firstName}>
								<FormLabel fontSize={'0.9em'} mb={0}>First Name</FormLabel>
								<Input {...field} fontSize={'0.9em'} placeholder="First Name" readOnly disabled />
								</FormControl>
							)}
							</Field>
							<Field name="lastName">
							{({ field, form }) => (
								<FormControl isInvalid={form.errors.lastName && form.touched.lastName}>
								<FormLabel fontSize={'0.9em'} mb={0}>Last Name</FormLabel>
								<Input {...field} fontSize={'0.9em'} placeholder="Last Name" readOnly disabled />
								</FormControl>
							)}
							</Field>
						</Stack>

						{/* Email and Phone fields */}
						<Stack direction={{ base: 'column', md: 'row' }}>
							<Field name="email">
							{({ field, form }) => (
								<FormControl isInvalid={form.errors.email && form.touched.email}>
								<FormLabel fontSize={'0.9em'} mb={0}>Email</FormLabel>
								<InputGroup>
									<InputLeftElement pointerEvents='none'>
									<Icon as={MdEmail} color='gray.300' />
									</InputLeftElement>
									<Input {...field} fontSize={'0.9em'} placeholder="Email" readOnly disabled />
								</InputGroup>
								</FormControl>
							)}
							</Field>
							<Field name="phone">
							{({ field, form }) => (
								<FormControl isInvalid={form.errors.phone && form.touched.phone}>
								<FormLabel fontSize={'0.9em'} mb={0}>Cell Phone</FormLabel>
								<InputGroup>
									<InputLeftElement pointerEvents='none'>
									<Icon as={FaPhoneAlt} color='gray.300' />
									</InputLeftElement>
									<Input {...field} fontSize={'0.9em'} placeholder="Phone" readOnly disabled />
									</InputGroup>
								</FormControl>
							)}
							</Field>
						</Stack>

						{/* Address fields */}
						<Stack spacing="3" direction={{ base: 'column', md: 'row' }}>
							<Field name="street">
							{({ field, form }) => (
								<FormControl isInvalid={form.errors.street && form.touched.street}>
								<FormLabel fontSize={'0.9em'} mb={0}>Street</FormLabel>
								<Input {...field} fontSize={'0.9em'} placeholder="Street" readOnly disabled />
								</FormControl>
							)}
							</Field>
							<Field name="city">
							{({ field, form }) => (
								<FormControl isInvalid={form.errors.city && form.touched.city}>
								<FormLabel fontSize={'0.9em'} mb={0}>City</FormLabel>
								<Input {...field} fontSize={'0.9em'} placeholder="City" readOnly disabled />
								</FormControl>
							)}
							</Field>
							<Field name="state">
							{({ field, form }) => (
								<FormControl isInvalid={form.errors.state && form.touched.state}>
								<FormLabel fontSize={'0.9em'} mb={0}>State</FormLabel>
								<Input {...field} fontSize={'0.9em'} placeholder="State / Province" readOnly disabled />
								</FormControl>
							)}
							</Field>
							<Field name="zip">
							{({ field, form }) => (
								<FormControl isInvalid={form.errors.zip && form.touched.zip}>
								<FormLabel fontSize={'0.9em'} mb={0}>ZIP</FormLabel>
								<Input {...field} fontSize={'0.9em'} placeholder="ZIP / Postal Code" readOnly disabled />
								</FormControl>
							)}
							</Field>
						</Stack>
						</Stack>
						
						{!agreementData.dogFriendly &&
						<Field name="noDogAgreement">
							{({ field, form }) => (
							<FormControl pt={'20px'} isInvalid={form.errors.noDogAgreement && form.touched.noDogAgreement} isRequired >
								<Checkbox {...field} fontSize={'0.9em'} defaultChecked readOnly disabled>
									I understand that this home does not permit animals, and agree to abide by the No Pets Permitted policy.
								</Checkbox>
							</FormControl>
							)}
						</Field>
						}
						
						<HouseRules 
							channel={agreementData.channel}
							rebook={agreementData.rebook}
                			longTerm={calculateDaysBetweenDates(agreementData.checkinDate,agreementData.checkoutDate) >= 29}
						/>

						<Flex direction="row" py="4" alignItems={'center'} gap={3}>
							<Button type="submit" colorScheme="gray" isDisabled>Submit</Button>
							<Text color={'dmOrange.500'}>Signed and submitted at {formatTimestamp(agreementData.signedAt)}</Text>
						</Flex>
					</Form>
					</>
				)}
				</Formik>

			</Flex>
		</Flex>
	)
}

export { StaticGuestAgreement };

