import React, { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchReservationData } from "../helpers/fetch";

export interface ReservationContextData {
  reservationData: any; // Replace 'any' with the actual type of your reservation data
  updateReservationData: (reservationNumber: string) => Promise<void>;
  isReservationContextLoaded: Boolean;
  setReservationContextLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isReservationContextValid: Boolean;
  setReservationContextValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultContextData: ReservationContextData = {
  reservationData: null, // Set the default value for reservationData
  updateReservationData: async () => {
    /* default function implementation */
  },
  isReservationContextLoaded: false,
  setReservationContextLoaded: () => {},
  isReservationContextValid: null,
  setReservationContextValid: () => {},
};

const ReservationContext =
  createContext<ReservationContextData>(defaultContextData);

export const useReservation = () => useContext(ReservationContext);

export const ReservationProvider = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    // Function to check and set the dm-attribution from URL parameters
    const setAttributionFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const attribution = urlParams.get("source");
      const rebookMode = urlParams.get("rebook");
      if (attribution) {
        localStorage.setItem("dm-attribution", attribution);
      }
      if (rebookMode) {
        localStorage.setItem("dm-rebook-mode", Date.now().toString());
        localStorage.setItem("dm-rebook-reservation", rebookMode);
      }
    };

    setAttributionFromUrl();

    // Optional: Listen for changes in the URL
    window.addEventListener("popstate", setAttributionFromUrl);

    // Cleanup listener when component unmounts
    return () => {
      window.removeEventListener("popstate", setAttributionFromUrl);
    };
  }, [location]);

  const [reservationData, setReservationData] = useState(null);
  const [isReservationContextLoaded, setReservationContextLoaded] =
    useState(false);
  const [isReservationContextValid, setReservationContextValid] =
    useState(null);

  const updateReservationData = async (reservationNumber) => {
    try {
      const response = await fetchReservationData(reservationNumber);
      //console.log("Raw fetch response:", response);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const updatedData = await response.json(); // Parsing the response to JSON
      //console.log("Parsed reservation data:", updatedData);

      setReservationData(updatedData);
      setReservationContextLoaded(true);
      setReservationContextValid(true); // Assuming the data is valid if the response is ok
    } catch (error) {
      console.error("Error fetching reservation data:", error);
      setReservationContextLoaded(true);
      setReservationContextValid(false);
    }
  };

  return (
    <ReservationContext.Provider
      value={{
        reservationData,
        updateReservationData,
        isReservationContextLoaded,
        setReservationContextLoaded,
        isReservationContextValid,
        setReservationContextValid,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};
