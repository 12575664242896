import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Flex, Text } from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from 'react-router-dom';
import PlacesMap from "../PlacesMap";
import { PlacesGridWidget } from "./PlacesGridWidget";

const PlacesModal = ({houseData, initialType}) => {
  const navigate = useNavigate();

  const [places, setPlaces] = React.useState([]);
  const [placesLoading, setPlacesLoading] = React.useState(true);
  const [placesType, setPlacesType] = React.useState(initialType);

  const handlePlacesChange = (newPlaces) => {
    setPlaces(newPlaces);
  };  

  return (
    <>
      <Flex className={'tripcontainer'} w={'100%'}>
        { houseData ?
          <Flex
            pt={{ base: 2, lg: 8 }}
            justifyContent={"start"}
            alignItems={"center"}
            direction={"column"}
            w={'100vw'}
            maxW={'720px'}
            className={'places-container'}
          >
            <Flex
              direction={{ base: "column" }}
              textAlign={"left"}
              justifyContent={"center"}
              maxW={"680px"} mx={'20px'} w={'calc(100% - 40px)'}
              pb={3}
            >
              <Flex gap={{base:1,md:2}} mb={3}>
                <Button variant={'outline'} colorScheme="dmNavy" fontSize={{base:'sm',md:'md'}}
                  isDisabled={placesType==='Beaches/Ponds'}
                  onClick={() => {setPlacesType('Beaches/Ponds')}}
                >
                  Beaches
                </Button>
                <Button variant={'outline'} colorScheme="dmNavy" fontSize={{base:'sm',md:'md'}}
                  isDisabled={placesType==='Restaurants'}
                  onClick={() => {setPlacesType('Restaurants')}}
                >
                  Restaurants
                </Button>
                <Button variant={'outline'} colorScheme="dmNavy" fontSize={{base:'sm',md:'md'}}
                  isDisabled={placesType==='Shopping/Retail'}
                  onClick={() => {setPlacesType('Shopping/Retail')}}
                >
                  Shopping
                </Button>
                <Button variant={'outline'} colorScheme="dmNavy" fontSize={{base:'sm',md:'md'}}
                  isDisabled={placesType==='Discounts/Partners'}
                  onClick={() => {setPlacesType('Discounts/Partners')}}
                >
                  Discounts
                </Button>
              </Flex>
              <PlacesGridWidget
                title={placesType}
                subtitle="From our local partner businesses in Cape Cod."
                subtitleColor="#777"
                subType={placesType}
                houseData={houseData}
                onPlacesChange={handlePlacesChange}
                onPlacesLoadingChange={setPlacesLoading}
              />
            </Flex>
          </Flex>
          :
          <Flex
            pt={{ base: 2, lg: 16 }}
            justifyContent={"start"}
            alignItems={"center"}
            direction={"column"}
            w={{base:'100vw',md:'50vw'}}
            maxW={'720px'}
            className={'places-container'}
          >
            <Flex
              direction={{ base: "column" }}
              textAlign={"left"}
              justifyContent={"center"}
              maxW={"680px"} mx={'20px'} w={'calc(100% - 40px)'}
              pb={3}
            >
            </Flex>
              <Flex alignItems="center" fontSize={{base:16, md:20}} mb={{base:2, md:4}} justifyContent={'space-between'}>
                <Flex my={3}>
                  <ChevronLeftIcon onClick={() => navigate('/trips')} />
                  <Text as="span" cursor="pointer" ml={2} lineHeight={'16px'} onClick={() => navigate('/trips')} >
                    Back to My Trips
                  </Text>
                </Flex>
              </Flex>
          </Flex>
        }

        {!placesLoading &&
          <Flex className="places-map-container" h={{base:'calc(100vh - 150px)',md:'calc(100vh - 90px)'}} 
            w={{ base: '100%' }}
            position={'sticky'} right='0px' top='80px'
            display={{base:'none',md:'flex'}}
            >          
            <PlacesMap navigate={navigate} places={places} houseData={houseData} />
          </Flex>
        }
      </Flex>

    </>
  );
};

export { PlacesModal };

