import {
  Box,
  IconButton,
  IconButtonProps,
  Image,
  Skeleton,
  Stack,
  StackProps,
  useColorModeValue
} from "@chakra-ui/react";
import { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Carousel, CarouselSlide, useCarousel } from "./Carousel";

interface GalleryProps {
  images: Array<any>;
  rootProps?: StackProps;
}

export const Gallery = (props: GalleryProps) => {
  const { images, rootProps } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ref, slider] = useCarousel({
    slideChanged: (slider: any) => setCurrentSlide(slider.track.details.rel),
  });

  const hasPrevious = currentSlide !== 0;
  const hasNext = currentSlide < images.length - 1;

  return (
    <Stack spacing="0" {...rootProps} h={'100%'} >
      <Box
        position="relative" h={'100%'}
        sx={{
          _hover: {
            "> button": {
              display: "inline-flex",
            },
          },
        }}
      >
        <Carousel h={'100%'} ref={ref} w={'100%'}>
          {images.map((image, i) => (
            <CarouselSlide key={i}>
              <Image borderRadius={'6px 0px 0px 0px'}
                src={image.link}
                width={"100%"} h={'100%'}
                objectFit="cover"
                alt={image.link}
                fallback={<Skeleton />}
                onLoad={() => {
                  // Force update the carousel or adjust its size
                  slider.current?.update();
                }}
              />
            </CarouselSlide>
          ))}
        </Carousel>
        {hasPrevious && (
          <CarouselIconButton
            pos="absolute"
            left="3"
            top="50%"
            transform="translateY(-50%)"
            onClick={() => slider.current?.prev()}
            icon={<BsChevronLeft />}
            aria-label="Previous Slide"
          />
        )}

        {hasNext && (
          <CarouselIconButton
            pos="absolute"
            right="3"
            top="50%"
            transform="translateY(-50%)"
            onClick={() => slider.current?.next()}
            icon={<BsChevronRight />}
            aria-label="Next Slide"
          />
        )}
      </Box>
    </Stack>
  );
};

const CarouselIconButton = (props: IconButtonProps) => (
  <IconButton
    display="none"
    fontSize="lg"
    isRound
    boxShadow="base"
    bg={useColorModeValue("white", "gray.800")}
    _hover={{
      bg: useColorModeValue("gray.100", "gray.700"),
    }}
    _active={{
      bg: useColorModeValue("gray.200", "gray.600"),
    }}
    _focus={{ boxShadow: "inherit" }}
    _focusVisible={{ boxShadow: "outline" }}
    {...props}
  />
);
