import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Link,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { SlidersHorizontal } from "lucide-react";
import * as React from "react";
import { FaRegMap } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SearchResultsContext } from "../contexts/SearchResultsContext";
import FiltersModal from "./FiltersModal";
import HelpModal from "./HelpModal";
import Pagination from "./Pagination";
import SearchMap from "./SearchMap";
import {
  SearchResultsCard,
  SearchResultsCardSkeleton,
} from "./SearchResultsCard";

type Props = {
  params?: any;
  favoritesPage?: Boolean;
  showMapOnLoad?: Boolean;
};

const SearchResultsGrid: React.FC<Props> = ({
  params,
  favoritesPage,
  showMapOnLoad,
}) => {
  const {
    searchResults,
    flexSearchResults,
    bufferLoading,
    totalResults,
    queryOffset,
    maxSearchPriceValue,
    loading,
    pathingFilterSettings,
    setPathingFilterSettings,
    setQueryOffset,
    setBufferLoading,
    emailLoading,
    userFavorites,
    setUserFavorites,
    favoritesLoading,
    activeQuoteNumber,
    setActiveQuoteNumber,
  } = React.useContext(SearchResultsContext);

  /// Admin Mode Handling
  const user = useUser();
  const userIsAdmin =
    user.isSignedIn &&
    user.user.primaryEmailAddress &&
    user.user.primaryEmailAddress.emailAddress &&
    (user.user.primaryEmailAddress.emailAddress.includes("darrenhe.com") ||
      user.user.primaryEmailAddress.emailAddress.includes(
        "cloudthoughts.com"
      ) ||
      user.user.primaryEmailAddress.emailAddress.includes("thisisdelmar.com"));
  const [adminMode, setAdminMode] = React.useState(userIsAdmin);
  React.useEffect(() => {
    setAdminMode(userIsAdmin);
  }, [userIsAdmin]);
  /// END Admin Mode Handling

  // Handling for pagination and filters
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = React.useState(false);
  const [resultsLoaded, setResultsLoaded] = React.useState(false);
  const [filterOn, setFilterOn] = React.useState(false);
  const [filteredResults, setFilteredResults] = React.useState([]);
  const [paginationTotal, setPaginationTotal] = React.useState(totalResults);
  const [pageNumberWhenBuffering, setPageNumberWhenBuffering] =
    React.useState();
  //console.log('showMapOnLoad in Grid',showMapOnLoad)
  const [showMapSelection, setShowMapSelection] = React.useState(
    favoritesPage ? false : true
  );

  /*Looks like a redundant useEffect, conflicting with the useEffect that properly sets filteredResults*/
  /*React.useEffect(() => {
    if (filteredResults) {
      setCurrentPageNumber(1);
      setPaginationTotal(filteredResults.length)
      setDisplayedSearchResults(filteredResults);
    }
  }, [filteredResults]);*/

  const {
    isOpen: isHelpModalOpen,
    onOpen: onOpenHelpModal,
    onClose: onCloseHelpModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const returnHome = () => {
    window.location.href = "/";
  };

  const pageSize = 20;

  const renderSearchResultsMessage = () => {
    if (favoritesLoading || emailLoading) {
      return;
    } else if (!favoritesPage) {
      if (searchResults && searchResults.length === 0) {
        return (
          <Flex
            direction={"column"}
            gap={3}
            justifyContent={"center"}
            alignItems={"center"}
            px={8}
          >
            <Text pt={8}>
              Your search returned no results, please enter at least two nights
              between March 1st and January 1st.
            </Text>
            <Text mt={3}>
              For July/August dates we typically have a 7 night minimum with a
              Saturday-Saturday check in, or Sunday-Sunday.
            </Text>
            <Button
              my={5}
              variant="outline"
              colorScheme="dmOrange"
              size="lg"
              w={"250px"}
              onClick={onOpenHelpModal}
            >
              Need help booking?
            </Button>
            <HelpModal isOpen={isHelpModalOpen} onClose={onCloseHelpModal} />
          </Flex>
        );
      } else {
        return <Text p={8}>No vacation homes meet your filter criteria.</Text>;
      }
    } else if (
      favoritesPage &&
      !favoritesLoading &&
      userFavorites &&
      userFavorites.length === 0
    ) {
      return <Text p={8}>You have not selected any favorites yet.</Text>;
    } else {
      return <Text p={8}>&nbsp;</Text>;
    }
  };

  function pageChangeHandler(event) {
    pageChange(event.page);
  }

  function pageChange(pageNumber) {
    let results = filterOn ? filteredResults : searchResults;

    if (
      pageNumber * pageSize > results.length &&
      results.length !== totalResults &&
      !filterOn &&
      !bufferLoading
    ) {
      setPageNumberWhenBuffering(pageNumber);
      let offset =
        queryOffset === 0
          ? 100
          : queryOffset > totalResults
          ? totalResults
          : queryOffset + 100;
      setQueryOffset(offset);
      setCurrentPageNumber(pageNumber);
      setResultsLoaded(false);
    } else if (currentPageNumber === 0) {
      pageNumber = 1;
      setResultsLoaded(false);
      setDisplayedSearchResults(
        results.slice(currentPageNumber * pageSize, pageNumber * pageSize)
      );
      setCurrentPageNumber(pageNumber);
    } else if (
      pageNumber > currentPageNumber &&
      pageNumber * pageSize <= results.length
    ) {
      setResultsLoaded(false);
      setDisplayedSearchResults(
        results.slice(currentPageNumber * pageSize, pageNumber * pageSize)
      );
      setCurrentPageNumber(pageNumber);
    } else if (
      pageNumber > currentPageNumber &&
      pageNumber * pageSize > results.length
    ) {
      if (filterOn) {
        setResultsLoaded(false);
        setDisplayedSearchResults(
          results.slice(currentPageNumber * pageSize, results.length)
        );
        setCurrentPageNumber(pageNumber);
      }
      if (results.length === totalResults) {
        setResultsLoaded(false);
        setDisplayedSearchResults(
          results.slice(currentPageNumber * pageSize, totalResults)
        );
        setCurrentPageNumber(pageNumber);
      }
    } else if (pageNumber < currentPageNumber) {
      setResultsLoaded(false);
      setBufferLoading(false);
      setDisplayedSearchResults(
        results.slice(
          (pageNumber - 1) * pageSize,
          (currentPageNumber - 1) * pageSize
        )
      );
      setCurrentPageNumber(pageNumber);
    }
  }

  // Scroll Search Results Grid Div and Window to Top on Page Change
  React.useEffect(() => {
    const element = document.querySelector('[data-id="search-results-grid"]');
    element.scrollTop = -20;
    window.scrollTo(0, 0);
  }, [currentPageNumber]);

  function filteredPageChange(filteredResults) {
    setResultsLoaded(false);
    //console.log('pageSize: ' + pageSize + ' filteredResults.length: ' + filteredResults.length)
    let maxSize =
      pageSize > filteredResults.length ? filteredResults.length : pageSize;
    //console.log('maxSize: ' + maxSize)
    let filteredPageResults = [...filteredResults.slice(0, maxSize)];
    setCurrentPageNumber(1);
    setPaginationTotal(filteredResults.length);
    //console.log('filteredPageResults', filteredPageResults)
    setDisplayedSearchResults([...filteredPageResults]);
  }

  const MAX_SLIDER_PRICE = 1000;

  React.useEffect(() => {
    //console.log("firing pathingFilterSettings", pathingFilterSettings);
    if (
      pathingFilterSettings.tags ||
      pathingFilterSettings.maxPrice ||
      pathingFilterSettings.selectedBedrooms ||
      pathingFilterSettings.selectedBathrooms
    ) {
      setResultsLoaded(false);

      let updatedMaxPrice;
      if (
        (pathingFilterSettings.maxPrice &&
          pathingFilterSettings.maxPrice >= MAX_SLIDER_PRICE) ||
        (maxSearchPriceValue && maxSearchPriceValue >= MAX_SLIDER_PRICE)
      ) {
        updatedMaxPrice = 10000;
      } else {
        updatedMaxPrice = pathingFilterSettings.maxPrice
          ? pathingFilterSettings.maxPrice
          : maxSearchPriceValue;
      }

      //console.log('updatedMaxPrice', updatedMaxPrice)

      applyFilters({
        tags: pathingFilterSettings.tags ? pathingFilterSettings.tags : [],
        maxPrice: updatedMaxPrice,
        bedrooms: pathingFilterSettings.selectedBedrooms
          ? pathingFilterSettings.selectedBedrooms
          : ["Any"],
        bathrooms: pathingFilterSettings.selectedBathrooms
          ? pathingFilterSettings.selectedBathrooms
          : ["Any"],
      });

      setPathingFilterSettings({});
      setResultsLoaded(true);
    }
  }, [pathingFilterSettings]);

  React.useEffect(() => {
    if (favoritesPage) {
      setDisplayedSearchResults(userFavorites);
      //console.log('after set ', userFavorites)
    }
  }, [userFavorites, favoritesPage]);

  const openFiltersModal = () => setIsFiltersModalOpen(true);
  const closeFiltersModal = () => setIsFiltersModalOpen(false);

  // Define the default value for different breakpoints
  const isLargerThanMD = useBreakpointValue({ base: false, md: true });

  // State for showing the map
  const [showMap, setShowMap] = React.useState(false);
  const [showMapButton, setShowMapButton] = React.useState(false);

  // Effect to update state based on breakpoint
  React.useEffect(() => {
    if (showMapOnLoad && isLargerThanMD) {
      setShowMap(true);
      //console.log('setting map true')
    } else {
      setShowMap(false);
      //console.log('setting map false')
    }
    setShowMap(isLargerThanMD);
  }, [isLargerThanMD, showMapOnLoad]);

  function toggleMap() {
    setShowMap(!showMap);
    setShowMapSelection(!showMap);
  }

  const [filterSettings, setFilterSettings] = React.useState({
    tags: [],
    maxPrice:
      maxSearchPriceValue >= MAX_SLIDER_PRICE ? 10000 : maxSearchPriceValue,
    bedrooms: ["Any"],
    bathrooms: ["Any"],
  });

  // Assuming you have a state for storing the displayed search results
  const [displayedSearchResults, setDisplayedSearchResults] = React.useState(
    filteredResults || searchResults
  );

  React.useEffect(() => {
    //console.log('displayed search results: ', pathingFilterSettings !== null);
    //console.log('displayed search results: ', Object.keys(pathingFilterSettings).length);
    //console.log('displayed search results: ', pathingFilterSettings);

    if (
      !pathingFilterSettings.tags &&
      !pathingFilterSettings.maxPrice &&
      !pathingFilterSettings.selectedBedrooms &&
      !pathingFilterSettings.selectedBathrooms
    ) {
      setResultsLoaded(true);
    }

    // Hide Toggle Map Button and Hide Map if No Results\
    if (
      displayedSearchResults &&
      displayedSearchResults.length === 0 &&
      !loading &&
      !favoritesLoading
    ) {
      setShowMapButton(false);
      setShowMap(false);
    } else {
      setShowMapButton(true);
      if (isLargerThanMD && showMapOnLoad) {
        setShowMap(showMapSelection);
      } else {
        setShowMap(false);
      }
    }
  }, [displayedSearchResults]);

  React.useEffect(() => {
    if (loading) {
      setResultsLoaded(false);
      setFilterOn(false);
      setFilterSettings({
        tags: [],
        maxPrice:
          maxSearchPriceValue >= MAX_SLIDER_PRICE ? 10000 : maxSearchPriceValue,
        bedrooms: ["Any"],
        bathrooms: ["Any"],
      });
    } else if (!loading) {
      setPaginationTotal(totalResults);
      let sliceSize =
        searchResults.length > pageSize ? pageSize : searchResults.length;
      if (favoritesPage) {
        setDisplayedSearchResults(userFavorites || []); // No pagination on favorites for now
      } else if (searchResults.length === 0) {
        setDisplayedSearchResults([]);
      } else {
        setDisplayedSearchResults(searchResults.slice(0, sliceSize));
      }
      setCurrentPageNumber(1);
    }
  }, [loading]);

  React.useEffect(() => {
    if (!bufferLoading && pageNumberWhenBuffering === currentPageNumber) {
      let newSearchResultsLength = searchResults.length;
      let sliceSize =
        newSearchResultsLength > pageSize * currentPageNumber
          ? pageSize * currentPageNumber
          : newSearchResultsLength;
      setDisplayedSearchResults(
        searchResults.slice((currentPageNumber - 1) * pageSize, sliceSize)
      );
    }
  }, [bufferLoading]);

  const applyFilters = (filters) => {
    //console.log("Applying filters with max price:", filters.maxPrice); // Log the max price
    let filteredResults = searchResults;

    // Filter by max price
    if (filters.maxPrice && filters.maxPrice < MAX_SLIDER_PRICE) {
      //console.log("filter max price", filters.maxPrice)
      filteredResults = filteredResults.filter(
        (listing) => Math.round(listing.price) <= filters.maxPrice
      );
    }

    if (filters.minPrice) {
      //console.log("filter min price", filters.minPrice)
      filteredResults = filteredResults.filter(
        (listing) => Math.round(listing.price) >= filters.minPrice
      );
    }

    // Filter by tags
    if (filters.tags.length > 0 && !filters.tags.includes("Any")) {
      filteredResults = filteredResults.filter((listing) =>
        filters.tags.every((tag) => listing.tags.includes(tag))
      );
    }

    // Filter by exact match of bedrooms
    if (filters.bedrooms.length > 0) {
      if (filters.bedrooms.includes("Any")) {
        // If "Any" is selected, do not filter by bedrooms
      } else {
        // Filter listings that match any of the selected bedroom options
        filteredResults = filteredResults.filter((listing) => {
          return (
            listing.bedrooms != null &&
            (filters.bedrooms.includes(listing.bedrooms.toString()) ||
              (listing.bedrooms >= 7 && filters.bedrooms.includes("7+")))
          );
        });
      }
    }

    // Filter by exact match of bathrooms
    if (filters.bathrooms.length > 0) {
      if (filters.bathrooms.includes("Any")) {
        // If "Any" is selected, do not filter by bathrooms
      } else {
        // Filter listings that match any of the selected bathroom options
        filteredResults = filteredResults.filter((listing) => {
          return (
            listing.bathrooms != null &&
            (filters.bathrooms.includes(listing.bathrooms.toString()) ||
              (listing.bathrooms >= 7 && filters.bathrooms.includes("7+")))
          );
        });
      }
    }

    //console.log(filters)
    // Save filter settings
    setFilterSettings(filters);
    if (
      filters.tags.length === 0 &&
      filters.maxPrice === maxSearchPriceValue &&
      filters.bedrooms[0] === "Any" &&
      filters.bathrooms[0] === "Any"
    ) {
      setFilterOn(false);
      setPaginationTotal(totalResults);
      // Update state with the filtered results
      filteredPageChange(filteredResults);
    } else {
      setFilterOn(true);
      setPaginationTotal(filteredResults.length);
      setFilteredResults(filteredResults);
      // Update state with the filtered results
      filteredPageChange(filteredResults);
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false }); // for Mobile Search Bar

  //console.log('showmap', showMap)

  return (
    <Flex
      pb={isMobile && "60px"}
      data-id="search-results-grid-container"
      overflow="auto" // Ensure it's 'auto' to enable scrolling when content overflows
      h={{ base: "calc(100vh - 90px)", md: "100vh - 80px" }}
    >
      <Flex
        position={"relative"}
        width={showMap ? { base: "0px", md: "60vw" } : "full"}
        display={showMap ? { base: "none", md: "flex" } : "flex"}
        overflow={{ base: "auto", md: "auto" }}
        borderStyle={"solid"}
        borderColor={"white"}
        data-id={"search-results-grid"}
        p={{ base: 2, md: 5 }}
        pb={20}
        justifyContent={"start"}
        flexDirection={"column"}
        alignItems={"center"}
        minH={{ base: "calc(100vh - 240px)", md: "calc(100vh - 150px)" }}
      >
        {adminMode && activeQuoteNumber && (
          <Flex
            alignItems={"center"}
            w={"100%"}
            p={2}
            gap={2}
            flexDirection={{ base: "column", md: "row" }}
            background={"#FFE885"}
          >
            <Flex gap={2}>
              {activeQuoteNumber && (
                <Button
                  leftIcon={<EditIcon />}
                  variant={"solid"}
                  colorScheme={"dmNavy"}
                  size={{ base: "sm", md: "md" }}
                  onClick={() => navigate(`/quotes/${activeQuoteNumber}`)}
                >
                  Quote # {activeQuoteNumber}
                </Button>
              )}
              {/* Turning off New Quote Creation because will Trigger from HubSpot Deal - 7/19/24
              <Flex>
                <Button leftIcon={<AddIcon />} variant={'outline'} colorScheme={'dmNavy'} size={{base:'sm',md:'md'}} background={'white'}
                  onClick={() => navigate('quotes')}
                >
                  Start New Quote
                </Button>
              </Flex>
              */}
            </Flex>
            <Text mx={2} fontSize={{ base: "14px", md: "16px" }}>
              Search with Dates and Guests to Add to Quote
            </Text>
          </Flex>
        )}

        <Flex
          justifyContent={"space-between"}
          w={"100%"}
          alignContent={"center"}
        >
          {favoritesPage && userFavorites && userFavorites.length > 0 ? (
            <Flex
              justifyContent={"space-between"}
              w={"100%"}
              alignContent={"center"}
              alignItems={"center"}
              pb={3}
              mt={{ base: 3, md: 0 }}
            >
              <Flex
                mr={8}
                fontSize={{ base: "20px", md: "24px" }}
                p={2}
                fontWeight={500}
              >
                Your Favorites
              </Flex>
              <Button
                variant={"outline"}
                colorScheme={"dmNavy"}
                size={"sm"}
                mr={2}
                onClick={returnHome}
              >
                Explore More
              </Button>
            </Flex>
          ) : (
            <>
              <Flex
                maxWidth="1920px"
                m={2}
                py={2}
                px={4}
                border={"solid 1px"}
                borderColor={"dmNavy.100"}
                borderRadius={8}
                textAlign={"left"}
                alignItems={"center"}
                _hover={{ backgroundColor: "#D3D3D370" }}
                onClick={openFiltersModal}
                cursor="pointer"
              >
                <SlidersHorizontal size="16px" />
                <Text ps={2} fontSize={"md"} fontWeight={500}>
                  Filters
                </Text>
              </Flex>
            </>
          )}
          {displayedSearchResults.length > 0 &&
            !loading &&
            !favoritesPage &&
            !flexSearchResults && (
              <Pagination
                style={{ marginTop: "5px", alignSelf: "center" }}
                designtype="top"
                onPageChange={pageChangeHandler}
                page={currentPageNumber}
                pageSize={pageSize}
                defaultPage={1}
                count={paginationTotal}
              />
            )}
        </Flex>

        {isFiltersModalOpen && (
          <FiltersModal
            isOpen={isFiltersModalOpen}
            onClose={closeFiltersModal}
            applyFilters={applyFilters}
            currentFilters={filterSettings}
            searchResults={searchResults}
          />
        )}

        <>
          {!loading && resultsLoaded && (
            <>
              {displayedSearchResults &&
                displayedSearchResults.length === 0 && (
                  <>
                    {renderSearchResultsMessage()}

                    {favoritesPage &&
                      !favoritesLoading &&
                      userFavorites &&
                      userFavorites.length === 0 && (
                        <Link href="/" w={"100%"}>
                          <Button colorScheme="dmOrange" size="md">
                            Explore More Homes
                          </Button>
                        </Link>
                      )}

                    {!favoritesPage && (
                      <Flex flexDirection={"column"} gap={3}>
                        {searchResults.length > 0 && (
                          <Link
                            onClick={() => {
                              // Reset filter settings
                              setFilterSettings({
                                tags: [],
                                maxPrice: maxSearchPriceValue,
                                bedrooms: ["Any"],
                                bathrooms: ["Any"],
                              });
                              applyFilters({
                                tags: [],
                                maxPrice: maxSearchPriceValue,
                                bedrooms: ["Any"],
                                bathrooms: ["Any"],
                              });
                              /* These were causing a similar issue but only when you use the Clear Filters button when there are no filtered Results */
                              // Reset displayed search results
                              //setDisplayedSearchResults(searchResults);
                              //setPaginationTotal(totalResults)
                            }}
                          >
                            <Button
                              colorScheme="dmOrange"
                              variant="outline"
                              w={"100%"}
                            >
                              Clear Filters
                            </Button>
                          </Link>
                        )}
                        <Link href="/">
                          <Button
                            colorScheme="dmOrange"
                            size="lg"
                            minW={"250px"}
                          >
                            Start New Search
                          </Button>
                        </Link>
                      </Flex>
                    )}
                  </>
                )}
            </>
          )}
        </>

        <SimpleGrid
          columns={
            showMap ? [1, 1, 1, 2, 2, 3, 4, 5] : [1, 2, 2, 3, 4, 5, 5, 5]
          }
          spacingX="10px"
          spacingY="25px"
          maxWidth="1920px"
          w="100%"
          data-id="simple-grid"
        >
          {!resultsLoaded || loading || favoritesLoading || emailLoading
            ? // Display a few skeleton cards while loading
              Array(15)
                .fill(null)
                .map((_, index) => <SearchResultsCardSkeleton key={index} />)
            : displayedSearchResults.map((listing, index) => {
                const isFirstFlexSearchItem =
                  index ===
                  displayedSearchResults.findIndex((item) => item.flexSearch);

                return (
                  <React.Fragment key={listing.uniqueId || index}>
                    {isFirstFlexSearchItem && (
                      // This is the break row with a header
                      <Box gridColumn="1 / -1">
                        {" "}
                        {/* This ensures the box takes the full row */}
                        <Text
                          fontSize="lg"
                          color={"dmNavy.700"}
                          textAlign={"left"}
                          mx={2}
                          pt={4}
                          mb={-3}
                        >
                          Limited availability for your search. Showing
                          additional availability for similar dates below.
                        </Text>
                      </Box>
                    )}
                    <SearchResultsCard
                      key={listing.houseNumber}
                      houseNumber={listing.houseNumber}
                      rating={listing.rating}
                      reviews={listing.reviews}
                      occupancy={listing.occupancy}
                      bedrooms={listing.bedrooms}
                      bathrooms={listing.bathrooms}
                      price={listing.price}
                      title={listing.title}
                      thumbnail={listing.thumbnail}
                      pictures={listing.pictures}
                      latitude={listing.latitude}
                      longitude={listing.longitude}
                      address={listing.address}
                      town={listing.town}
                      amenities={listing.amenities}
                      favorite={
                        favoritesPage
                          ? true
                          : !emailLoading &&
                            userFavorites &&
                            userFavorites.some(
                              (favorite: { houseNumber: any }) =>
                                favorite.houseNumber ===
                                listing.houseNumber.toString()
                            )
                      }
                      listing={listing}
                      setFavorites={setUserFavorites}
                      favoritesArray={userFavorites || []}
                      flexSearch={listing.flexSearch}
                    />
                  </React.Fragment>
                );
              })}
        </SimpleGrid>

        {displayedSearchResults.length > 0 &&
          !loading &&
          !favoritesPage &&
          !flexSearchResults && (
            <Pagination
              style={{ marginTop: "10px" }}
              designtype="bottom"
              onPageChange={pageChangeHandler}
              page={currentPageNumber}
              pageSize={pageSize}
              defaultPage={1}
              count={paginationTotal}
            />
          )}
      </Flex>
      {showMap && (
        <Flex
          className="searchmapcontainer"
          h={{ base: "calc(100vh - 150px)", md: "calc(100vh - 90px)" }}
          w={{ base: "100%", md: "40%" }}
          display={showMap ? "flex" : "none"}
          position={"sticky"}
          right="0px"
          top="80px"
        >
          <SearchMap
            navigate={navigate}
            displayedSearchResults={displayedSearchResults}
          />
        </Flex>
      )}
      <Flex
        position={"fixed"}
        bottom={{ base: "90px", md: "70px" }}
        alignItems={"center"}
        justifyContent={"center"}
        cursor={"pointer"}
        left={"calc(50% - 80px)"}
      >
        {showMapButton && (
          <Button
            onClick={toggleMap}
            bgColor={"dmNavy.500"}
            color={"white"}
            _hover={{ background: "dmNavy.500" }}
            padding="7"
            borderRadius="24"
            border="solid 1px white"
            boxShadow={
              "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"
            }
            justifyContent={"space-evenly"}
            w={"160px"}
          >
            <Box textAlign={"center"} w={"80px"}>
              {showMap ? "Hide Map" : "Show Map"}
            </Box>
            <Box ml={3}>
              <FaRegMap />
            </Box>
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export { SearchResultsGrid };
