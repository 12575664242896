import {
  Box, Flex,
  GridItem,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { FaBell, FaCheck } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

export const CustomGridItem = ({ bgImage, title, disable=false, status=null, setSelectedItem, onOpen, reservationNumber='', redirectAllowed=true }) => {
    const navigate = useNavigate();

    let classTitle = "trip-details-"+title.replace(/\s+/g, '-').toLowerCase();

    // Function to render the status dot based on the status prop
    const renderStatusDot = () => {
      switch(status) {
        case 'complete':
          return (
            <Box position="absolute" top="-7px" right="-7px" color="white" zIndex="1" bgColor={'#38B4E9'} borderRadius='50%' p={1}>
              <FaCheck size='14px' />
            </Box>
          );
        case 'alert':
          return (
            <Box position="absolute" top="-8px" right="-8px" color="white" zIndex="1" bgColor={'dmOrange.500'} borderRadius='50%' p={1}>
              <FaBell size='14px' />
            </Box>
          );
        default:
          return null; // No dot if status is not specified
      }
    };
    
    return (
      <GridItem colSpan={1} maxWidth={'100%'} position="relative">
        {renderStatusDot()}
        <Tooltip 
          hasArrow 
          label="Available after check-out" 
          isDisabled={!disable || title !== "Review"} 
          closeOnClick={false} 
          fontSize={'lg'}
        >
          <Flex
            direction='column'
            borderRadius={'6px'}
            padding={8}
            aspectRatio={1}
            bgImage={bgImage}
            bgSize={'55%'}
            bgRepeat={'no-repeat'}
            bgPosition={'center'}
            bgColor={disable ? (title === "Review" ? 'dmNavy.500' : 'gray.300') : 'dmNavy.500'}
            cursor={disable ? 'auto' : 'pointer'}
            color={title === "Review" ? 'inherit' : 'white'} // Adjust color if necessary
            className={classTitle}
            _hover={{
              backgroundColor: disable ? "dmNavy.500" : "dmNavy.700"
            }}
            onClick={!disable && redirectAllowed ? () => {
              console.log(title)
              switch(title) {
                case 'Local Discounts':
                  navigate(`/discounts/${reservationNumber}/`);
                  break;
                case 'Things To Do':
                  navigate(`/places/${reservationNumber}/`);
                  break;
                case 'Guest Choice Program':
                  navigate("/guestchoice");
                  break;
                case 'Shop':
                  navigate(`/store/${reservationNumber}`);
                  break;
                case 'Merch':
                  window.open("https://delmarvacations.myshopify.com/collections/products", '_blank');
                  break;
                default:
                  setSelectedItem(title);
                  onOpen();
              }
            } : !disable && !redirectAllowed ? () => {
              setSelectedItem(title);
              onOpen();
            } :         
            undefined}
          >
            {/* Conditional rendering can also be applied inside the Flex if needed */}
          </Flex>
        </Tooltip>
        <Text pt={2} fontSize={{base:13, sm:14, md:16, lg:16, xl:16}}>{title}</Text>
      </GridItem>
    );
  };