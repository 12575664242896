import {
  Box, Flex, Heading, Image,
  SimpleGrid, Skeleton, Text, VStack
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { fetchPlaces } from "../../helpers/guestportalfetch";
import { calcHaversineDistace } from "../../helpers/helpers";

const PlacesGridWidget = ({
  title,
  subtitle,
  subtitleColor,
  subType,
  houseData,
  onPlacesChange,
  onPlacesLoadingChange,
}) => {
  const [cards, setCards] = useState([]);
  const [placeLoading, setPlacesLoading] = useState(true);

  const getPlaces = async () => {
    try {
      const response = await fetchPlaces({ type: subType });
      const places = await response.json();
      //console.log("house", houseData);
      //console.log("Places: ", places);

      if (places) {
        if (!houseData.latitude || !houseData.longitude) {
          setCards(places);
          setPlacesLoading(false);
          onPlacesLoadingChange(false)
          onPlacesChange(places);
        } else {
          // Assuming calcHaversineDistance is a correctly implemented function
          for (let i = 0; i < places.length; i++) {
            places[i].distance = calcHaversineDistace(houseData.latitude, houseData.longitude, places[i].latitude, places[i].longitude);
          }

          places.sort((a, b) => {
            if (a.distance === "NaN") return 1;
            if (b.distance === "NaN") return -1;
            return parseFloat(a.distance) > parseFloat(b.distance) ? 1 : -1;
          });

          setCards(places);
          setPlacesLoading(false);
          onPlacesLoadingChange(false)
          onPlacesChange(places);
        }
      } else {
        console.log("Places not found.");
      }
    } catch (error) {
      console.error("Error fetching places data:", error);
    }
  };

  const openPlaceLink = (websiteLink) => { if (websiteLink) { window.open(websiteLink, '_blank'); } }
  const openPlaceAddress = (name, address) => { if (address) {
    const fullAddress = `${name}${address ? `, ${address}` : ''}`;
    window.open(`https://www.google.com/maps/search/${encodeURIComponent(fullAddress)} MA`, '_blank'); } 
  }

  useEffect(() => {
    getPlaces();
  }, [subType,houseData]);

  return (
    <Box
      position="relative"
      width="full"
      overflow="hidden"
      borderTop="10px"
      borderStyle="solid"
      borderColor="white"
      background="white"
      data-id="discounts"
    >
      <Box
        justifyContent="center"
        alignSelf="center"
        margin="0 auto"
        width="100%"
        maxWidth="720px"
      >
        <Flex direction="column">
          {/* Section Header */}
          <Flex justifyContent="space-between" alignItems="center">
            {title ? (
              <Heading fontSize={{ base: "2xl", md: "2xl", lg: "2xl" }} textAlign="left">
                {title}
              </Heading>
            ) : (
              <Text fontSize={{ base: "md", lg: "lg" }} color={subtitleColor} textAlign="left" pt={2}>
                {subtitle}
              </Text>
            )}
          </Flex>

          {/* Grid Section */}
          <Box my={5}>
            {placeLoading ? (
              <SimpleGrid columns={[1, null, 3]} spacing="20px">
                {Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton key={index} height="250px" borderRadius="12px" />
                ))}
              </SimpleGrid>
            ) : (
              <SimpleGrid columns={[1, null, 3]} spacing="20px">
                {cards.map((card, index) => (
                  <Box as="div" key={index} cursor={card.websiteLink ? 'pointer' : 'default'}>
                    <VStack spacing={2}>
                      <Image
                        src={card.imageLink}
                        alt={`Image of ${card.name}`}
                        width="100%"
                        height="250px"
                        borderRadius="12px"
                        objectFit="cover"
                        onClick={() => openPlaceLink(card.websiteLink)} 
                        _hover={{ textDecoration: card.websiteLink ? 'underline' : 'none' }}
                        cursor={card.websiteLink ? 'pointer' : 'default'}
                      />
                      <Flex direction="column" width="100%">
                        <Text fontWeight="semibold"
                          onClick={() => openPlaceLink(card.websiteLink)} 
                          _hover={{ textDecoration: card.websiteLink ? 'underline' : 'none' }}
                          cursor={card.websiteLink ? 'pointer' : 'default'}
                        >
                          {card.name}
                        </Text>
                        <Flex alignItems="center" fontSize="sm" mt={1}
                          onClick={() => openPlaceAddress(card.name, card.address)}
                          _hover={{ textDecoration: card.address ? 'underline' : 'none' }}
                          cursor={card.address ? 'pointer' : 'default'}
                        >
                          {card.distance && (
                            <Flex justifyContent={'space-between'} w={'100%'}>
                              <Text>{Number(card.distance).toFixed(1)} miles away</Text>
                              <Flex alignItems={'center'}>
                                <FaMapMarkerAlt color="#e95037" />
                                <Text as="button" marginLeft={2} color="#e95037">
                                  Directions
                                </Text>
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                        <Text fontSize="sm"
                           onClick={() => openPlaceAddress(card.name, card.address)}
                          _hover={{ textDecoration: card.address ? 'underline' : 'none' }}
                          cursor={card.address ? 'pointer' : 'default'}
                        >{card.address}</Text>
                        <Text fontSize="sm" color="gray.500" mt={1}
                          onClick={() => openPlaceLink(card.websiteLink)} 
                          _hover={{ textDecoration: card.websiteLink ? 'underline' : 'none' }}
                          cursor={card.websiteLink ? 'pointer' : 'default'}
                        >{card.description}</Text>
                      </Flex>
                    </VStack>
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export { PlacesGridWidget };

