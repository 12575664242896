import { Box, Skeleton } from "@chakra-ui/react";
import GoogleMapReact from 'google-map-react';
import { isNumber } from "lodash";
import { useCallback, useEffect, useRef, useState } from 'react';
import houseIcon from '../media/house_icon_orange.png';

const CAPE_COD_CENTER = { lat: 41.7688, lng: -70.2962 };
const MAX_ZOOM_LEVEL = 18;

const getInfoWindowString = (place) => (`
  <div class="info-window-content" style="max-width:200px; cursor: pointer;text-align:left;">
    <div style="font-size: 16px;">
      <img src="${place.imageLink ? place.imageLink : ''}" width="100%" loading="lazy">
    </div>
    <div style="font-size: 16px;margin-top:5px;">
      ${place.name}
    </div>
    <div style="justify-content:space-between; display:flex; flex-direction:column">
      <div style="font-size: 14px; color: grey;margin-top:5px;">
        ${place.address}
      </div>
      <div style="font-size: 14px; color: grey;margin-top:5px;">
        Get Directions
      </div>
    </div>
  </div>`);

const PlacesMap = ({ navigate, places, houseData }) => {
  const [mapObjects, setMapObjects] = useState({ map: null, maps: null });
  const [updatingMarkers, setUpdatingMarkers] = useState(false)
  const markersRef = useRef([]);

  const HOUSE_CENTER = { lat: Number(houseData.latitude), lng: Number(houseData.longitude) }

  const handleApiLoaded = ({ map, maps }) => {
    setMapObjects({ map, maps });
    map.setOptions({
      maxZoom: MAX_ZOOM_LEVEL,
    });
  };

  // Function to update markers
  const updateMarkers = useCallback(() => {

    if (!mapObjects.map || !mapObjects.maps) {
      //console.log("Map object not available yet.");
      return;
    }

    if(!places || places.length===0) {
      return;
    }

    // Clear existing markers
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    setUpdatingMarkers(true);

    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    const bounds = new mapObjects.maps.LatLngBounds();
    let openInfowindow = null;
    
    const closeOpenInfowindow = () => {
      if (openInfowindow) {
        openInfowindow.close();
        openInfowindow = null;
      }
    };

    // Ensure displayedSearchResults is valid before processing
    const validResults = places.filter(place => 
      [place] && 
      typeof place.latitude === 'string' && 
      typeof place.longitude === 'string' &&
      !isNaN(place.latitude) && 
      !isNaN(place.longitude)
    );

    validResults.forEach(place => {
      const lat = Number(place.latitude);
      const lng = Number(place.longitude);

      if (!isNaN(lat) && !isNaN(lng) && isNumber(lat) && isNumber(lng) && lat !== 0 && lng !== 0) {

        const markerPosition = new mapObjects.maps.LatLng(lat, lng);
        const marker = new mapObjects.maps.Marker({
          position: markerPosition,
          map: mapObjects.map,
        });

        bounds.extend(markerPosition);
        markersRef.current.push(marker);

        if (place.imageLink) {
          const infoWindowContent = getInfoWindowString(place);
          const infoWindow = new mapObjects.maps.InfoWindow({ content: infoWindowContent });
          const fullAddress = `${place.name}${place.address ? `, ${place.address}` : ''}`;

          mapObjects.maps.event.addListener(infoWindow, 'domready', () => {
            document.querySelectorAll('.info-window-content').forEach(elem => {
              elem.addEventListener('click', () => window.open(`https://www.google.com/maps/search/${encodeURIComponent(fullAddress)} MA`, '_blank'));
            })
          });

          marker.addListener('click', () => {
            closeOpenInfowindow();
            infoWindow.open(mapObjects.map, marker);
            openInfowindow = infoWindow;
          });
        }
      }
    });

    // Custom marker for HOUSE_CENTER
    const houseMarker = new mapObjects.maps.Marker({
      position: HOUSE_CENTER,
      map: mapObjects.map,
      icon: {
        url: houseIcon,
        scaledSize: new mapObjects.maps.Size(25, 25), 
      },
    });
    markersRef.current.push(houseMarker);

    // InfoWindow for HOUSE_CENTER marker
    const houseInfoWindow = new mapObjects.maps.InfoWindow({
      content: `<div>Your stay at ${houseData.address.split(', United States')[0]}</div>`,
    });

    houseMarker.addListener('click', () => {
      closeOpenInfowindow();
      houseInfoWindow.open(mapObjects.map, houseMarker);
      openInfowindow = houseInfoWindow;
    });

  //console.log('validResults.length',validResults.length)
  //console.log('house_center',HOUSE_CENTER)
  if (validResults.length === 1) {
    const singleResult = validResults[0].house;
    const latLng = new mapObjects.maps.LatLng(Number(singleResult.latitude), Number(singleResult.longitude));
    mapObjects.map.setCenter(latLng);
    mapObjects.map.setZoom(16);
  } else if (validResults.length > 1) {
    mapObjects.map.setCenter(HOUSE_CENTER);
    mapObjects.map.setZoom(13);
  } else {
    mapObjects.map.setCenter(HOUSE_CENTER);
    mapObjects.map.setZoom(12);
  }

  setUpdatingMarkers(false);

  }, [mapObjects, places, HOUSE_CENTER]);

  useEffect(() => {
    updateMarkers();
    // Cleanup function to remove markers when component unmounts or data changes
    return () => {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
    };
  }, [updateMarkers]);

  return (
    <Box width="100%" h="100%">
      {(updatingMarkers) ? <Skeleton w={'100%'} h={'100%'} /> :
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAH2xQVEEEVPM_w1G6IgqOHsmbJF0L-ihE" }}
        defaultZoom={13}
        defaultCenter={HOUSE_CENTER}
        onGoogleApiLoaded={handleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
      >
      </GoogleMapReact>
      }
    </Box>
  );
};

export default PlacesMap;
