import {
  Button, Checkbox, CheckboxGroup, Flex, Grid,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Switch, Text
} from "@chakra-ui/react";
import * as React from "react";
import { SearchResultsContext } from '../contexts/SearchResultsContext';

const MAX_SLIDER_PRICE = 1000;

const FiltersModal = ({ isOpen, onClose, applyFilters, currentFilters, searchResults }) => {

  const { minSearchPriceValue, maxSearchPriceValue, dogs, setDogs } = React.useContext(SearchResultsContext);

  const [maxPrice, setMaxPrice] = React.useState(currentFilters.maxPrice || maxSearchPriceValue || 1000);
  const [minPrice, setMinPrice] = React.useState(currentFilters.minPrice || minSearchPriceValue || 0);
  const [selectedBedrooms, setSelectedBedrooms] = React.useState(Array.isArray(currentFilters.bedrooms) ? currentFilters.bedrooms : ['Any']);
  const [selectedBathrooms, setSelectedBathrooms] = React.useState(Array.isArray(currentFilters.bathrooms) ? currentFilters.bathrooms : ['Any']);
  const [selectedTags, setSelectedTags] = React.useState(currentFilters.tags || []);


  // Update this state directly with the RangeSlider
  const handleRangeSliderChange = (values) => {
    const [newMinPrice, newMaxPrice] = values;
    setMinPrice(newMinPrice);
    setMaxPrice(newMaxPrice);
  };


  // Define the allowed tags for filters
  const ALLOWED_TAGS = ["Pool","Hot Tub","Dog Friendly","Central AC","Walk to Water","Waterfront","Water View","Game Room","Signature"]

  // Extract unique tags from searchResults, excluding "Dog Friendly" to manage separately
  const tagOptions: string[] = Array.from(new Set(
    searchResults.flatMap(listing => 
      listing.tags.filter((tag): tag is string => ALLOWED_TAGS.includes(tag as string)) // Type assertion here
    )
  )); 

  React.useEffect(() => {
    // Synchronize the 'Dog Friendly' tag with the switch state from context
    const isDogFriendlySelected = selectedTags.includes("Dog Friendly");
    if (dogs !== isDogFriendlySelected) {
      if (dogs) {
        setSelectedTags(prevTags => [...prevTags, "Dog Friendly"]);
      } else {
        setSelectedTags(prevTags => prevTags.filter(tag => tag !== "Dog Friendly"));
      }
    }
  }, [dogs]);

  const handleDogsChange = (e) => {
    const isChecked = e.target.checked;
    setDogs(isChecked); // Update context
    if (isChecked && !selectedTags.includes("Dog Friendly")) {
      setSelectedTags(prevTags => [...prevTags, "Dog Friendly"]);
    } else {
      setSelectedTags(prevTags => prevTags.filter(tag => tag !== "Dog Friendly"));
    }
  };

  // Generate button labels
  const buttonLabels = ["Any", "1", "2", "3", "4", "5", "6", "7+"];

  // Handlers for selecting bedrooms and bathrooms
  const handleBedroomSelection = (label) => {
    if (label === "Any" || selectedBedrooms.includes("Any")) {
      setSelectedBedrooms([label]);
    } else {
      setSelectedBedrooms(prev =>
        prev.includes(label) ? prev.filter(item => item !== label) : [...prev, label]
      );
    }
  };

  const handleBathroomSelection = (label) => {
    if (label === "Any" || selectedBathrooms.includes("Any")) {
      setSelectedBathrooms([label]);
    } else {
      setSelectedBathrooms(prev =>
        prev.includes(label) ? prev.filter(item => item !== label) : [...prev, label]
      );
    }
  };

  // Pricing Filter Histograms

  const effectiveMaxPrice = Math.min(maxSearchPriceValue, MAX_SLIDER_PRICE);

  const numberHistogramBars = 30;
  const priceInterval = (effectiveMaxPrice - minSearchPriceValue) / numberHistogramBars;
  const priceFrequencies = new Array(numberHistogramBars).fill(0);
  

  searchResults.forEach(listing => {
    const price = Math.min(listing.price, effectiveMaxPrice); // Cap the price at effectiveMaxPrice
    const index = Math.min(
      Math.floor((price - minSearchPriceValue) / priceInterval),
      numberHistogramBars - 1 // Ensure the index is within the array bounds
    );
    priceFrequencies[index]++;
  });

  const handleApplyFilters = () => {
    applyFilters({
      tags: selectedTags,
      minPrice: minPrice,
      maxPrice: maxPrice,
      bedrooms: selectedBedrooms,
      bathrooms: selectedBathrooms
    });
    onClose();
  };

  // Count function (You might need to adjust this based on how your searchResults are structured)
  const countFilteredHomes = () => {
    return searchResults.filter(listing => {
      let matchesPrice = (maxPrice >= MAX_SLIDER_PRICE) 
                       ? Math.round(listing.price) >= minPrice 
                       : Math.round(listing.price) >= minPrice && Math.round(listing.price) <= maxPrice;
  
      // Adjust the matchesTags check
      let matchesTags = selectedTags.length === 0 ||
        selectedTags.every(tag => 
          listing.tags.includes(tag)
        );

    
      // Existing logic for bedrooms
      let matchesBedrooms = true;
      if (selectedBedrooms.length > 0 && !selectedBedrooms.includes("Any")) {
        matchesBedrooms = selectedBedrooms.includes("7+") && listing.bedrooms >= 7;
        if (listing.bedrooms != null) {
          matchesBedrooms = matchesBedrooms || selectedBedrooms.includes(listing.bedrooms.toString());
        }
      }

      // Existing logic for bathrooms
      let matchesBathrooms = true;
      if (selectedBathrooms.length > 0 && !selectedBathrooms.includes("Any")) {
        matchesBathrooms = selectedBathrooms.includes("7+") && listing.bathrooms >= 7;
        if (listing.bathrooms != null) {
          matchesBathrooms = matchesBathrooms || selectedBathrooms.includes(listing.bathrooms.toString());
        }
      }
  
      return matchesPrice && matchesTags && matchesBedrooms && matchesBathrooms;
    }).length;
  };
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{base:"full", md:"xl"}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Filter Options</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={1} mb={5} alignItems={'center'}>
            <Text me={2} fontWeight={'bold'}>Dog Friendly Homes Only:</Text>

              <Switch
                id="dogs-switch"
                colorScheme="dmNavy"
                isChecked={dogs}
                onChange={handleDogsChange}
                size='lg'
                mb={0}
              />
              <Text fontSize="xs" lineHeight={0}>{dogs ? "Yes" : "No"}</Text>

            
          </Flex>
          <Text mb={2} fontWeight={'bold'}>Amenities:</Text>
          <CheckboxGroup
            colorScheme="dmNavy"
            value={selectedTags.filter(tag => tag !== "Dog Friendly")} // Exclude "Dog Friendly" from CheckboxGroup control
            onChange={(newValues) => setSelectedTags(newValues)}
          >
            <Grid templateColumns="repeat(2, 1fr)" gap={{ base: 1, md: 2 }}>
              {tagOptions.filter(tag => tag !== "Dog Friendly").map((tag, index) => (
                <Checkbox key={index} value={tag}>{tag}</Checkbox>
              ))}
            </Grid>
          </CheckboxGroup>
          

          <Text mt={{base:4, md:8}} mb={3} fontWeight={'bold'}>Price Range:</Text>

            {/* Hide Histogram if Fewer than 10 Results */}
            {searchResults.length > 20 && 
              <Flex direction="row" justify="space-between" align="flex-end" height={{base:"70px",md:"90px"}} mb={0}>
                {priceFrequencies.map((frequency, index) => (
                  <Flex
                    key={index}
                    width={String(100 / numberHistogramBars) + '%'} // Adjust width based on the number of bins
                    height={`${(frequency / Math.max(...priceFrequencies)) * 100}%`}
                    bg={frequency > 0 ? "dmNavy.500" : "dmNavy.300"} // Color changes based on count
                    mx="1px"
                  />
                ))}
              </Flex>
            }
            
            <RangeSlider
              min={minSearchPriceValue}
              max={effectiveMaxPrice}
              onChange={handleRangeSliderChange} // Update state as the slider moves
              defaultValue={[minSearchPriceValue, maxSearchPriceValue]} // Initial values
              value={[minPrice, maxPrice]} // Controlled component
              mt={2}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack bgColor="dmNavy.500" />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} boxSize={5} boxShadow={'dmShadow'} backgroundColor={'dmNavy.500'} borderColor={'dmNavy.300'} />
              <RangeSliderThumb index={1} boxSize={5} boxShadow={'dmShadow'} backgroundColor={'dmNavy.500'} borderColor={'dmNavy.300'} />
            </RangeSlider>
          

            <Flex justifyContent="space-between">
              <Text>${minSearchPriceValue}</Text>
              <Text fontWeight={'500'}>
                ${minPrice} - {maxPrice >= MAX_SLIDER_PRICE ? "$1000+" : `$${maxPrice}`}
              </Text>
              <Text>
                {maxPrice >= MAX_SLIDER_PRICE ? `$${MAX_SLIDER_PRICE}+` : `$${maxPrice}`}
              </Text>
            </Flex>

          {/* Bedroom Filters */}
          <Text mt={4} mb={2}>Bedrooms:</Text>
          <Flex wrap="wrap" mb={4} justifyContent={'space-between'}>
            {buttonLabels.map(label => (
              <Button
                key={label}
                flex="1"
                mr={{ base: 1, md: 2 }} mb={2}
                onClick={() => handleBedroomSelection(label)}
                colorScheme={selectedBedrooms.includes(label) ? "dmNavy" : "gray"}
                size={{ base: "sm", md: "md" }}
              >
                {label}
              </Button>
            ))}
          </Flex>

          {/* Bathroom Filters */}
          <Text mt={4} mb={2}>Bathrooms:</Text>
          <Flex wrap="wrap" justifyContent={'space-between'}>
            {buttonLabels.map(label => (
              <Button
                key={label}
                flex="1"
                mr={{ base: 1, md: 2 }} mb={2}
                onClick={() => handleBathroomSelection(label)}
                colorScheme={selectedBathrooms.includes(label) ? "dmNavy" : "gray"}
                size={{ base: "sm", md: "md" }}
              >
                {label}
              </Button>
            ))}
          </Flex>

        </ModalBody>
        <ModalFooter mb={2}>
          <Button me={2} onClick={() => {
            setSelectedBedrooms(["Any"]);
            setSelectedBathrooms(["Any"]);
            setSelectedTags([]);
            setMaxPrice(maxSearchPriceValue);
            setMinPrice(minSearchPriceValue);
          }}>
            Clear Filters
          </Button>
          <Button colorScheme="dmOrange" onClick={handleApplyFilters}>
            Show {countFilteredHomes() >= 100 ? "100+" : countFilteredHomes()} Homes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal >
  );

};

export default FiltersModal;