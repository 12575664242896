import Cookies from "js-cookie";
import config from "../config";
import { AgreementData } from '../types/AgreementType';

const defaultHeaders = () => {
  const token = Cookies.get("__session");
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const API_ENDPOINT = config.apiEndpoint;


export const createAgreement = async (data: AgreementData) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/agreements/`, {
      method: 'POST',
      headers: {
        ...defaultHeaders(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAgreements = async (reservationNumber, apikey) => {
  try {
    const getAgreementsUrl = `${API_ENDPOINT}/agreements/?reservationNumber=${encodeURIComponent(reservationNumber)}&apikey=${encodeURIComponent(apikey)}`;

    //console.log("getAgreementsUrl",getAgreementsUrl);
    const response = await fetch(getAgreementsUrl, {
      method: 'GET',
      headers: {
        ...defaultHeaders(),
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getRecentAgreements = async (reservationNumber) => {
  try {
    const getAgreementsUrl = `${API_ENDPOINT}/agreements/recentAgreements?reservationNumber=${encodeURIComponent(reservationNumber)}`;

    //console.log("getAgreementsUrl",getAgreementsUrl);
    const response = await fetch(getAgreementsUrl, {
      method: 'GET',
      headers: {
        ...defaultHeaders(),
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};