import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Box, Flex,
  ListItem, Text, UnorderedList
} from "@chakra-ui/react";
import { Beach } from "./Beach";

const WelcomeDetails = ({ channel, town, standalone=false, laundry }) => {

  return (
    <Flex direction='column' alignItems={'center'}>

      {/* Accordion Group */}
      <Flex direction='column' pb={10}>
        <Text fontSize={24} fontWeight={'700'} pb={3} textAlign={'left'} w={'100%'}>
          What Should You Bring?
        </Text>
        <Accordion
          defaultIndex={[]}
          allowMultiple
          maxW={standalone ? 'full' : {base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}
          width={{ base: "90vw" }}
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={18}
                  fontWeight={"500"}
                  my={2}
                >
                  Items to Bring for your Cape Cod Vacation
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left" fontSize={16} my={3} lineHeight={8}>
              Below is a short list of items we recommend bringing with you:
              <UnorderedList ml={10}>
                <ListItem>Shampoo conditioner / bath soap / toothpaste</ListItem>
                <ListItem>Beach towels</ListItem>
                <ListItem>Beach chairs</ListItem>
                <ListItem>Sports equipment (bikes, kayaks, etc)</ListItem>
                <ListItem>Food, spices, cooking oils</ListItem>
                <ListItem>Coffee filters</ListItem>
                <ListItem>Charcoal for the grill (if not a gas grill, we provide propane for gas grills)</ListItem>
                <ListItem>Laundry: {laundry}</ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
      {/* End Accordion Group */}

      {/* Accordion Group */}
      <Flex direction='column' pb={10}>
        <Text fontSize={24} fontWeight={'700'} pb={3} textAlign={'left'} 
          maxW={standalone ? 'full' : {base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}
        >
          What We Provide
        </Text>
        <Accordion
          defaultIndex={[]}
          allowMultiple
          maxW={standalone ? 'full' : {base:'90vw', md:'2xl', lg:'3xl', xl:'5xl'}}
          width={{ base: "90vw" }}
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={18}
                  fontWeight={"500"}
                  my={2}
                >
                  Household Supplies
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left" fontSize={16} my={3} lineHeight={8}>
              We provide a Starter-Kit but do not provide supplies for your entire visit.  If you think you will need more than what is provided, please make sure to bring some with you or go to a local grocer.
              <UnorderedList ml={10}>
                <ListItem>1-roll toilet paper per bathroom</ListItem>
                <ListItem>1-roll paper towel in the kitchen</ListItem>
                <ListItem>Hand-soap</ListItem>
                <ListItem>Dish-soap</ListItem>
                <ListItem>Sponge</ListItem>
                <ListItem>Trash bags</ListItem>
                <ListItem>Cleaning supplies</ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={18}
                  fontWeight={"500"}
                  my={2}
                >
                  Sheets and Towels
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left" fontSize={16} my={3} lineHeight={8}>
              We provide sheets and towels, and launder them after your visit. We provide 1 bath towel per guest based on the max occupancy of the home.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
      {/* End Accordion Group */}

      {/* Accordion Group */}
      <Flex direction='column'>
        <Text fontSize={24} fontWeight={'700'} pb={3} textAlign={'left'} w={'100%'}>
          Beach Stickers
        </Text>
        <Beach 
          openItems={[]}
          town={town}
          standalone={standalone}
        />
      </Flex>
      {/* End Accordion Group */}
      
    </Flex>
  );
};

export { WelcomeDetails };

