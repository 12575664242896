
import { Box, Skeleton } from "@chakra-ui/react";
import GoogleMapReact from 'google-map-react';
import { isNumber } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SearchResultsContext } from '../contexts/SearchResultsContext';
import houseIcon from '../media/house_icon_orange.png';

const CAPE_COD_CENTER = { lat: 41.7688, lng: -70.2962 };
const MAX_ZOOM_LEVEL = 18;

function reformatDateString(date) {
  const [year, month, day] = date.split('-');
  return `${Number(month)}/${Number(day)}/${year}`;
}

const getInfoWindowString = (reservation) => (`
  <div class="info-window-content" style="max-width:200px; cursor: pointer;text-align:left;">
    <div style="font-size: 16px;">
      <img src="${reservation.house.thumbnail ? reservation.house.thumbnail : reservation.house.images ? reservation.house.images[0].link : ''}" width="100%" loading="lazy">
    </div>
    <div style="font-size: 16px;margin-top:5px;">
      ${reservation.house.houseNumber} - ${reservation.house.name}
    </div>
    <div style="justify-content:space-between; display:flex;">
      <div style="font-size: 14px; color: grey;margin-top:5px;">
        ${reformatDateString(reservation.checkinDate)} - ${reformatDateString(reservation.checkoutDate)}
      </div>
      <div style="font-size: 14px;margin-top:3px;">
        <span style="color: orange;">${String.fromCharCode(9733).repeat(Math.round(reservation.house.rating))}</span><span style="color: lightgrey;">${String.fromCharCode(9733).repeat(5 - Math.round(reservation.house.rating))}</span>
      </div>
    </div>
  </div>`);

const TripMap = ({ navigate, reservations }) => {
  const { loading } = React.useContext(SearchResultsContext);    
  const [mapObjects, setMapObjects] = useState({ map: null, maps: null });
  const [updatingMarkers, setUpdatingMarkers] = useState(false)
  const markersRef = useRef([]);

  const handleApiLoaded = ({ map, maps }) => {
    // Set map objects
    setMapObjects({ map, maps });
  
    // Set the maxZoom option (if needed)
    map.setOptions({
      maxZoom: MAX_ZOOM_LEVEL, // Optional, only if you're enforcing a maxZoom
    });
  
    // Add a listener for zoom changes
    maps.event.addListener(map, 'zoom_changed', () => {
      const currentZoom = map.getZoom();
      // Call function to update markers based on zoom level
      updateMarkerIcons(currentZoom, maps);
    });
  };
  
  const updateMarkerIcons = (zoomLevel, maps) => {
    const customMarkerIcon = {
      url: houseIcon, // URL of your custom marker image
      scaledSize: new maps.Size(20, 20), // Adjust size as needed
    };
  
    markersRef.current.forEach(marker => {
      marker.setIcon(customMarkerIcon);
    });
  };

  // Function to update markers
  const updateMarkers = useCallback(() => {

    if (!mapObjects.map || !mapObjects.maps) {
      //console.log("Map object not available yet.");
      return;
    }

    const customMarkerIcon = {
      url: houseIcon, // URL of your custom marker image
      scaledSize: new mapObjects.maps.Size(20, 20), // Adjust size as needed
    };

    if(!reservations || reservations.length===0) {
      return;
    }

    // Clear existing markers
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    setUpdatingMarkers(true);

    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    const bounds = new mapObjects.maps.LatLngBounds();
    let openInfowindow = null;
    
    const closeOpenInfowindow = () => {
      if (openInfowindow) {
        openInfowindow.close();
        openInfowindow = null;
      }
    };

    // Ensure displayedSearchResults is valid before processing
    const validResults = reservations.filter(reservation => 
      reservation && 
      typeof reservation.house.latitude === 'string' && 
      typeof reservation.house.longitude === 'string' &&
      !isNaN(reservation.house.latitude) && 
      !isNaN(reservation.house.longitude)
    );

    validResults.forEach(reservation => {
      const lat = Number(reservation.house.latitude);
      const lng = Number(reservation.house.longitude);

      if (!isNaN(lat) && !isNaN(lng) && isNumber(lat) && isNumber(lng) && lat !== 0 && lng !== 0) {

        const markerPosition = new mapObjects.maps.LatLng(lat, lng);
        const marker = new mapObjects.maps.Marker({
          position: markerPosition,
          map: mapObjects.map,
          icon: customMarkerIcon,
        });

        bounds.extend(markerPosition);
        markersRef.current.push(marker);

        if (reservation.house.thumbnail || reservation.house.images[0]) {
          const infoWindowContent = getInfoWindowString(reservation);
          const infoWindow = new mapObjects.maps.InfoWindow({ content: infoWindowContent });

          /*mapObjects.maps.event.addListener(infoWindow, 'domready', () => {
            document.querySelectorAll('.info-window-content').forEach(elem => {
              elem.addEventListener('click', () => navigate(`/${reservation.house.houseNumber}`));
            });
          });*/

          marker.addListener('click', () => {
            closeOpenInfowindow();
            infoWindow.open(mapObjects.map, marker);
            openInfowindow = infoWindow;
          });
        }
      }
    });


  if (validResults.length === 1) {
    const singleResult = validResults[0].house;
    const latLng = new mapObjects.maps.LatLng(Number(singleResult.latitude), Number(singleResult.longitude));
    mapObjects.map.setCenter(latLng);
    mapObjects.map.setZoom(16);
  } else if (validResults.length > 1) {
    mapObjects.map.fitBounds(bounds);
  } else {
    mapObjects.map.setCenter(CAPE_COD_CENTER);
    mapObjects.map.setZoom(10);
  }

  setUpdatingMarkers(false);

  }, [mapObjects, reservations]);

  useEffect(() => {
    updateMarkers();
    // Cleanup function to remove markers when component unmounts or data changes
    return () => {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
    };
  }, [updateMarkers]);

  return (
    <Box width="100%" h="100%">
      {(loading || updatingMarkers) ? <Skeleton w={'100%'} h={'100%'} /> :
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAH2xQVEEEVPM_w1G6IgqOHsmbJF0L-ihE" }}
        defaultZoom={11}
        defaultCenter={CAPE_COD_CENTER}
        onGoogleApiLoaded={handleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
      >
      </GoogleMapReact>
      }
    </Box>
  );
};

export default TripMap;
