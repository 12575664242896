import { Divider, Flex, Skeleton } from "@chakra-ui/react";
export const SkeletonBlock = () => (
  <Flex
    position={"relative"}
    width={"full"}
    data-id={"listing"}
    p={{ base: 2, md: 5 }}
    justifyContent={"center"}
    flexDirection={"column"}
    alignItems={"center"}
  >
    {/* Top Bar */}
    <Flex
      maxWidth="1440px"
      width={"100%"}
      p={2}
      textAlign={"left"}
      alignItems={"start"}
      flexDirection={"column"}
    >
      <Flex p={2}>
        <Skeleton height="24px" width="100px" />
      </Flex>

      <Flex justifyContent={"space-between"} p={2} width={"100%"}>
        {/* Listing Title */}
        <Skeleton height="24px" width="50%" />
        <Skeleton height="24px" width="20%" />
      </Flex>
      {/* End Top Bar */}

      {/* Photo Gallery */}
      <Flex
        maxWidth="1440px"
        width={"100%"}
        p={2}
        textAlign={"left"}
        alignItems={"start"}
        flexDirection={"column"}
      >
        <Skeleton height={{ base: "300px", md: "550px" }} width="100%" />
      </Flex>
      {/* End Photo Gallery */}

      {/* Body Section */}
      <Flex maxWidth="1440px" w={"100%"} p={2} direction="row">
        {/* Home Details Section */}
        <Flex w={"67%"} flexDirection={"column"}>
          {/* Occupancy, Bedroom, Bathroom Icons */}
          <Skeleton height="68px" width="200px" mb={4} />
          <Skeleton height="168px" width="100%" mb={4} />
          <Skeleton height="268px" width="100%" mb={4} />

          <Divider my={8} color={"black"} />

          <Skeleton height="168px" width="100%" />

          <Divider my={8} color={"black"} />

          <Skeleton height="168px" width="100%" />

          <Divider my={8} color={"black"} />
        </Flex>
        {/* Booking Section */}
        <Flex w={"33%"} ml={10} mt={5}>
          {/* Floating Card */}
          <Skeleton height="330px" width="100%" />
          {/* End Floating Card */}
        </Flex>
      </Flex>
      {/* End Body Section */}
    </Flex>
  </Flex>
);
