import {
  Alert, AlertIcon,
  Button, Flex, FormControl, FormLabel, Input,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
  Text, Textarea,
  useToast
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { SearchResultsContext } from '../contexts/SearchResultsContext';
import { isValidDate } from '../helpers/helpers';
import { createInquiryReservation } from '../helpers/reservationService';

interface InquiryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InquiryModal: React.FC<InquiryModalProps> = ({ isOpen, onClose }) => {
  const toast = useToast();

  const { contextDateRange, dogs, guests, houseData } = React.useContext(SearchResultsContext)

  const handleSubmit = async (values, actions) => {
    //console.log("submitting inquiry modal",values)

    let message = '';
    if (dogs) { message += "User Search - Dogs: " + dogs.toString() + ". "; }
    if (guests) { message += "User Search - Guests: " + guests.toString() + ". "; }
    if (values.additionalRequests) { message += "Additional Requests: " + values.additionalRequests; }

    const inquiryData = {
      guest: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
      },
      checkInDateLocalized: values.checkInDate,
      checkOutDateLocalized: values.checkOutDate,
      houseNumber: houseData.houseNumber, 
      guests: values.guests,
      message: message
    };

    //console.log("inquiryData",inquiryData)
  
    try {
      const response = await createInquiryReservation(inquiryData);
      if (response.error) {
        // Server responded with an error
        toast({
          title: 'Error Submitting Inquiry',
          description: response.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: "top"
        });
      } else {
        // Inquiry submitted successfully
        toast({
          title: 'Inquiry Submitted',
          description: 'Your reservation inquiry has been submitted. A Del Mar Guest Experience Manager will be in touch with you soon!',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: "top"
        });
        onClose();
        actions.resetForm();
      }
    } catch (error) {
      // Handle network or other unexpected errors
      toast({
        title: 'Submission Error',
        description: `An unexpected error occurred: ${error.toString()}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: "top"
      });
      console.error("Error submitting inquiry", error);
    } finally {
      actions.setSubmitting(false);
    }
  };
 
  // Calculate tomorrow's date
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatDate = (dateInput) => {
    // Ensure the input is a Date object
    const date = new Date(dateInput);
  
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
  }; 

  const minCheckInDate = formatDate(tomorrow);  

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email'),
    phone: Yup.string(),
    guests: Yup.number().required('Required'),
    checkInDate: Yup.date().required('Required').min(minCheckInDate, `Check-in date must be tomorrow or later`),
    checkOutDate: Yup.date().required('Required')
    .when('checkInDate', (checkInDate, schema) => {
      return schema.min(Yup.ref('checkInDate'), "Check-out date must be after check-in date");
    }),
    additionalRequests: Yup.string().max(1000, 'Max 1000 characters')
  }).test(
    'emailOrPhone',
    'Either Email or Phone is required',
    function (values) {
      const { email, phone } = values;
      if (!email && !phone) {
        return new Yup.ValidationError(
          'Either Email or Phone is required',
          null,
          'emailOrPhone'
        );
      }
      return true;
    }
  );  

  // Define the ErrorMessageText component for error messages
  const ErrorMessageText = ({ children }) => (
    <Text fontSize="sm" color="red.500">{children}</Text>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{base:'full',md:'2xl'}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={4}>Make a Reservation Inquiry</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert status='info' mb={5}><AlertIcon />
            To inquire, fill out the form below or give us a call at 508-422-8047.
          </Alert>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              guests: 2,
              checkInDate: contextDateRange[0],
              checkOutDate: contextDateRange[1],
              town: '',
              additionalRequests: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, touched, isSubmitting, isValid }) => (
              <Form>
                <Text fontSize={'1em'} fontWeight={'500'}>Guest Information</Text>
                <Flex gap={2} mt={2}>                  
                  {/* First Name */}
                  <Field name="firstName">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.firstName && form.touched.firstName} flex={1}>
                        <Input {...field} fontSize={'16'} placeholder="First Name" />
                        <ErrorMessage name="firstName" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                  
                  {/* Last Name */}
                  <Field name="lastName">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.lastName && form.touched.lastName} flex={1}>
                        <Input {...field} fontSize={'16'} placeholder="Last Name" />
                        <ErrorMessage name="lastName" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Flex gap={2} mt={2}>
                  {/* Email */}
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <Input {...field} type="email" fontSize={'16'} placeholder="Email" />
                        <ErrorMessage name="email" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>

                  {/* Phone */}
                  <Field name="phone">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                        <Input {...field} fontSize={'16'} placeholder="Phone" />
                        <ErrorMessage name="phone" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                {typeof errors.emailOrPhone === 'string' && (
                  <Text fontSize="sm" color='#E53E3E'>{errors.emailOrPhone}</Text>
                )}
                <Flex direction={{ base: 'column', md: 'row' }} gap={2} mt={6}>
                  <Flex direction={{ base: 'column', md: 'row' }} gap={2}>
                    <Flex gap={2}>
                      {/* Check-In Date */}
                      <Field name="checkInDate" w={'50%'}>
                        {({ field, form }) => (
                          <FormControl isInvalid={form.errors.checkInDate && form.touched.checkInDate} w={'calc(50% - 0.25rem)'}>
                            <FormLabel>Desired Check-In</FormLabel>
                            <Input {...field} type="date" min={minCheckInDate} fontSize={'16'} placeholder="Check-In Date" />
                            <ErrorMessage name="checkInDate" component={ErrorMessageText} />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="checkOutDate">
                        {({ field, form }) => {
                          let minCheckOutDate = ''; // Default to an empty string or another sensible default
                          if (isValidDate(form.values.checkInDate)) {
                            const checkInDate = new Date(form.values.checkInDate);
                            // Safely add one day to checkInDate
                            const nextDay = new Date(checkInDate.setDate(checkInDate.getDate() + 1));
                            minCheckOutDate = formatDate(nextDay); // Assuming formatDate returns 'YYYY-MM-DD' formatted string
                          }

                          return (
                            <FormControl isInvalid={form.errors.checkOutDate && form.touched.checkOutDate} w={'calc(50% - 0.25rem)'}>
                              <FormLabel>Desired Check-Out</FormLabel>
                              <Input {...field} type="date" min={minCheckOutDate} fontSize={'16'} placeholder="Check-Out Date" />
                              <ErrorMessage name="checkOutDate" component={ErrorMessageText} />
                            </FormControl>
                          );
                        }}
                      </Field>
                    </Flex>
                  </Flex>
                  <Field name="guests">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.guests && form.touched.guests}>
                        <FormLabel># of Guests</FormLabel>
                        <Input {...field} type="number" fontSize={'16'} placeholder="Guests" />
                        <ErrorMessage name="guests" component={ErrorMessageText} />
                      </FormControl>
                    )}
                  </Field>
                </Flex>

                {/* Additional Requests */}
                <Field name="additionalRequests">
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.additionalRequests && form.touched.additionalRequests} mt={4}>
                      <FormLabel>Additional Requests</FormLabel>
                      <Textarea {...field} placeholder="Let us know of any specific preferences, such as number of guests, number of bedrooms/bathrooms, dog-friendly, or anything else!" />
                      <ErrorMessage name="Additional Requests" component={ErrorMessageText} />
                    </FormControl>
                  )}
                </Field>

                <Button type="submit" colorScheme={'dmOrange'} my={4} w={'100%'} isDisabled={!isValid} isLoading={isSubmitting}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InquiryModal;
