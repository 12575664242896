import { MAX_END_DATE } from "./constants";

// Trims down the Guesty data to only the parts we care about within the next year
export const transformGuestyData = (guestyDataRaw, hoursOffset) => {
  const oneYearFromToday = new Date(MAX_END_DATE);

  return (
    guestyDataRaw.data.days
      // Filter out dates that are past a year from now
      .filter(
        (day) => new Date(day.date).getTime() <= oneYearFromToday.getTime()
      )
      .map((day) => {
        let guestyDate = new Date(day.date);
        guestyDate.setHours(0);
        guestyDate.setMinutes(0);
        guestyDate.setSeconds(0);
        // If hoursOffset is greater than 0, add 1 day to all dates
        if (hoursOffset > 0)
          guestyDate = new Date(guestyDate.setDate(guestyDate.getDate() + 1));

        return {
          startDate: guestyDate,
          status: day.status,
          booked: day.status === "available" ? false : true,
          minDays: day.minNights,
          price: day.price,
          cta: day.cta,
          ctd: day.ctd,
        };
      })
  );
};

// Can only check in on a day that's *not* already book AND where CTA is false
// AND if that date will have a valid Check Out date too
export const processCheckIns = (guestyDataTransformed) => {
  for (const date of guestyDataTransformed) {
    if (
      date.booked === false &&
      date.cta !== true &&
      processCheckOuts(date.startDate, guestyDataTransformed).length > 0
    ) {
      date.checkInOkay = true;
    } else {
      date.checkInOkay = false;
    }
  }

  return guestyDataTransformed;
};

export const processCheckOuts = (pickedDate, allDates) => {
  const pickedDateRange = allDates.find(
    (range) => range.startDate.getTime() === pickedDate.getTime()
  );
  if (!pickedDateRange)
    throw new Error(`Date range not found for the picked date: ${pickedDate}`);

  // Sort allDates in ascending order of startDate
  allDates.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

  // Find the first startDate after the pickedDate that is booked
  const firstBookedDateRange = allDates.find(
    (range) =>
      range.booked === true && range.startDate.getTime() > pickedDate.getTime()
  );
  const availableDates = [];

  for (const dateRange of allDates) {
    const startDate = dateRange.startDate;
    const diffTime = startDate.getTime() - pickedDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // If the start date of the range is not booked and is at least minDays in the future or is in the past, add it to the available dates
    if (
      dateRange.booked === false &&
      startDate.getTime() >= pickedDate.getTime() &&
      diffDays >= pickedDateRange.minDays &&
      dateRange.ctd !== true
    ) {
      // Stop adding to availableDates if the date is later than the first booked date
      if (
        firstBookedDateRange &&
        startDate.getTime() >= firstBookedDateRange.startDate.getTime()
      )
        break;

      availableDates.push(startDate);
    }
  }

  // Add the first booked startDate after the pickedDate as checkout on this date should be allowed
  if (
    firstBookedDateRange &&
    Math.ceil(
      (firstBookedDateRange.startDate.getTime() - pickedDate.getTime()) /
        (1000 * 60 * 60 * 24)
    ) >= pickedDateRange.minDays
  ) {
    availableDates.push(firstBookedDateRange.startDate);
  }

  //console.log(availableDates);
  return availableDates;
};
