import { Alert, AlertIcon, Box, Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, VStack } from '@chakra-ui/react';
import { useUser } from "@clerk/clerk-react";
import { AddressElement, useElements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import ReactPixel from "react-facebook-pixel";
import { useNavigate } from 'react-router-dom';
import { omitNullAndUndefined } from '../helpers/helpers';
import { sendHubspotEvent } from '../helpers/hubspot';
import { createRebookReservation } from '../helpers/reservationService';

interface PaymentFormProps {
  checkInDateLocalized: string;
  checkOutDateLocalized: string;
  houseNumber: string;
  guestyHouseId: string;
  guests: number;
  fareAccommodation: number;
  fareCleaning: number;
  rebookMode: boolean;
  coupon?: string;
}

interface ModalContentType {
  header: string;
  body: React.ReactNode;
  footer: React.ReactNode;
}

const PaymentForm: React.FC<PaymentFormProps> = (
  { checkInDateLocalized, checkOutDateLocalized, houseNumber, guestyHouseId, guests, fareAccommodation, fareCleaning, rebookMode, coupon }
) => {
  const {user} = useUser();
  let userId = null;
  if (user) {userId = user.id;}
  const elements = useElements();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expMonth, setExpMonth] = useState('');
  const [expYear, setExpYear] = useState('');
  const [cvc, setCvc] = useState('');
  const [cardElementFocused, setCardElementFocused] = useState(false);
  const [isRebook, setIsRebook] = useState(rebookMode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContentType>({ header: '', body: '', footer: ''});

  const addressElementOptions = {
    mode: 'billing' as const,
    fields: { phone: 'always' as const },
    validation: { phone: { required: 'always' as const }},
    display: { name: 'split' as const },
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    setIsModalOpen(true);
    setModalContent({ header: 'Processing', body: 
    <Flex flexDirection={'column'} gap={6} alignItems='center' textAlign={'center'} py={5}><Spinner size="xl" /><Text>We are processing your reservation. Please do not navigate away from this page.</Text></Flex>, 
    footer: '' });

    const dmAttribution = localStorage.getItem('dm-attribution');

    if (!elements) return;

    const addressElement = elements.getElement(AddressElement);
    const addressObject = await addressElement?.getValue();
    const addressData = addressObject?.value;
    const firstName = addressData?.firstName;
    const lastName = addressData?.lastName;
    const address = {
      line1: addressData?.address.line1,
      line2: addressData?.address.line2,
      city: addressData?.address.city,
      state: addressData?.address.state,
      postal_code: addressData?.address.postal_code,
      country: addressData?.address.country,
    };
    const phone = addressData?.phone;

    if (!email || !cardNumber || !expMonth || !expYear || !cvc || !firstName || !lastName || !address.line1 || !address.city || !address.state || !address.postal_code || !address.country || !phone) {
      setModalContent({
        header: 'Error',
        body: 'Please complete all fields and try again.',
        footer: <Button colorScheme={'dmNavy'} onClick={() => setIsModalOpen(false)}>Close</Button>
      });
      setIsSubmitting(false);
      return;
    }

    const paymentData = {
      listingId: guestyHouseId,
      card: {
        number: cardNumber,
        exp_month: expMonth,
        exp_year: expYear,
        cvc: cvc
      },
      billing_details: {
        name: `${firstName} ${lastName}`,
        address: {
          line1: address.line1,
          city: address.city,
          postal_code: address.postal_code,
          country: address.country
        }
      }
    };

    const rebookData = {
      paymentData: paymentData,
      checkInDateLocalized: checkInDateLocalized,
      checkOutDateLocalized: checkOutDateLocalized,
      houseNumber: houseNumber,
      guests: guests,
      fareAccommodation: fareAccommodation,
      fareCleaning: fareCleaning,
      guestObject: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        address: address,
        phone: phone,
      },
      userId: userId,
      attribution: dmAttribution || null,
      coupon: coupon || null,
    }

    console.log('rebookData', rebookData)

    try {
      const rebookResponse = await createRebookReservation(rebookData);

      console.log('rebookResponse', rebookResponse);

      // Fire the purchase event
      ReactPixel.track("Purchase", {
        value: fareAccommodation,
        currency: "USD"
      });

      // Custom Event //
      if (rebookResponse.status === 200 || rebookResponse.status === 202) {
        
        const userEmail = user?.primaryEmailAddress?.emailAddress;

        const rebooReservationParam = localStorage.getItem("dm-rebook-reservation")
        const attributionParam = localStorage.getItem("dm-attribution")
        const eventName = "pe21053466_checkout_completed";
        const properties = omitNullAndUndefined({
          house_number: houseNumber,
          rebook_reservation_source: rebooReservationParam,
          attribution: attributionParam,
          hs_page_url: window.location.href,
          check_in_date: checkInDateLocalized || null, // String or null
          check_out_date: checkOutDateLocalized || null, // String or null
          guests: guests || null, // Integer or null
          coupon: coupon || null,
          checkout_page_type: 'Rebook Checkout (New)',
          reservation_confirmation_number: rebookResponse.reservationNumber,
        })
        const eventData = {
          eventName,
          properties,
          ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
        };
        if (properties) {
          // Fire and forget
          (async () => {
            try {
              await sendHubspotEvent(eventData);
            } catch (error) {
              console.error("Error sending HubSpot event:", error);
            }
          })();
        }
      }
      // Custom Event //

      if ((rebookResponse.status === 200 || rebookResponse.status === 202) && rebookResponse.reservationNumber) {
        setModalContent({
          header: 'Booking Confirmed!',
          body: 'Your booking has been successfully confirmed. Redirecting to your guest agreement...',
          footer: ''
        });
        setTimeout(() => navigate(`/confirmation/${rebookResponse.reservationNumber}`), 3000);
      } else if ((rebookResponse.status === 202 || rebookResponse.status === 200) && !rebookResponse.reservationNumber) {
        setModalContent({
          header: 'Your vacation dates have been reserved!',
          body: 'Your vacation dates are confirmed! You will receive a confirmation email within the next 48 hours.',
          footer: <Button colorScheme={'dmNavy'} onClick={() => {
            setIsModalOpen(false);
            navigate(`/${houseNumber}/`);
          }}>Close</Button>
        });
      } else {
        setModalContent({
          header: 'Error',
          body: 'Rebook failed. Please try again.',
          footer: <Button colorScheme={'dmNavy'} onClick={() => setIsModalOpen(false)}>Close</Button>
        });
      }

    } catch (error) {
      setModalContent({
        header: 'Error',
        body: `There was an error processing your request: ${error.message}`,
        footer: <Button colorScheme={'dmNavy'} onClick={() => setIsModalOpen(false)}>Close</Button>
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box as="form" id="payment-form" onSubmit={handleSubmit} width={'100%'}>
        <VStack spacing={4} fontWeight={'bold'} pb={3}>
          <FormControl id="email" isRequired>
            <FormLabel>Contact Information</FormLabel>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl id="payment-element" isRequired>
            <FormLabel>Payment Information</FormLabel>
            {isRebook ?
              <Alert status="success" borderRadius="md" fontSize="md" mb={3} fontWeight={'normal'}><AlertIcon /><span><b>Rebook Special:</b> $300 due today; change dates or houses at anytime in the future; best discount available; no more Airbnb/VRBO fees; reserve your favorite home & weeks.</span></Alert>
              :
              <Alert status="success" borderRadius="md" fontSize="md" mb={3} fontWeight={'normal'}><AlertIcon /><span>You are <b>saving up to 20%</b> by booking direct with Del Mar. Plus, enjoy <b>Free Cancellation and Flexible Modifications</b> within 48 Hours After Booking — No Penalties!</span></Alert>
            }
            <Flex
              padding="0"
              transition="border-color 0.2s, box-shadow 0.2s"
              borderColor={cardElementFocused ? 'blue.500' : 'inherit'}
              boxShadow={cardElementFocused ? '0 0 0 1px #3182ce' : 'none'}
              gap={1}
            >
              <Input
                type="text"
                placeholder="Card Number"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                flex="1 1 50%"
                p={{base:2,md:3}}
              />
              <Input
                type="text"
                placeholder="MM"
                value={expMonth}
                onChange={(e) => setExpMonth(e.target.value)}
                flex="1 1 15%"
                p={{base:2,md:3}}
              />
              <Input
                type="text"
                placeholder="YYYY"
                value={expYear}
                onChange={(e) => setExpYear(e.target.value)}
                flex="1 1 18%"
                p={{base:2,md:3}}
              />
              <Input
                type="text"
                placeholder="CVC"
                value={cvc}
                onChange={(e) => setCvc(e.target.value)}
                flex="1 1 17%"
                p={{base:2,md:3}}
              />
            </Flex>
          </FormControl>
          <FormControl id="shipping-address-element">
            <FormLabel>Billing Information</FormLabel>
            <Box
              border="1px solid #E2E8F0"
              borderRadius="md"
              padding="2"
              transition="border-color 0.2s, box-shadow 0.2s"
            >
              <AddressElement options={addressElementOptions} />
            </Box>
          </FormControl>

          <Button type="submit" colorScheme="dmOrange" w={'100%'} isLoading={isSubmitting}>
            Submit Payment
          </Button>
        </VStack>
      </Box>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size={{base:'full',md:'2xl'}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalContent.header}</ModalHeader>
          <ModalBody>
            <Flex w={'100%'} justifyContent={'center'}>
              {modalContent.body}
            </Flex>
          </ModalBody>
          <ModalFooter>
            {modalContent.footer}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PaymentForm;
