import { Flex, Grid, Skeleton, Text } from "@chakra-ui/react";

const TripDetailsSkeleton = () => {
  return (
    <>
      <Flex
        width={'100%'}
        flexDirection={'column'}
        px={4}
      >
        <Flex direction={"column"} alignItems={"start"} position="relative" w={'100%'}>
          <Skeleton height="80px" mt={{base:5,md:20}} width="100%" borderRadius="16px" />
        </Flex>

        <Text as="h2" px={{base:0, sm:4}}
          fontSize={{base:16, md:20}} fontWeight={'bold'} 
          m={'32px auto 0px auto'} textAlign={'start'} w={'100%'} maxW={'500px'}
        >
          Getaway Checklist
        </Text>

        {/* Skeleton for Grid items */}
        <Grid
          h="auto" w="100%" maxW={'500px'}
          px={{base:0, sm:4}} pt={4} pb={4} margin="0 auto"
          templateRows="repeat(2, 1fr)"
          gap={{ base: 4, sm: 8 }}
          templateColumns={{
            base: "repeat(auto-fill, minmax(60px, 1fr))",
            sm: "repeat(auto-fill, minmax(80px, 1fr))",
          }}
        >
          {Array.from({ length: 8 }).map((_, index) => (
            <Skeleton key={index} height="80px" borderRadius="md" />
          ))}
        </Grid>

        <Text as="h2" px={{base:0, sm:4}} 
          fontSize={{base:16, md:20}} fontWeight={'bold'} 
          m={'32px auto 0px auto'} textAlign={'start'} w={'100%'} maxW={'500px'}
        >
          Information
        </Text>

        {/* Another Skeleton Grid for the Information section */}
        <Grid
          h="auto" w="100%" maxW={'500px'}
          px={{base:0, sm:4}} pt={4} pb={4} margin="0 auto"
          templateRows="repeat(2, 1fr)"
          gap={{ base: 4, sm: 8 }}
          templateColumns={{
            base: "repeat(auto-fill, minmax(60px, 1fr))",
            sm: "repeat(auto-fill, minmax(80px, 1fr))",
          }}
        >
          {Array.from({ length: 8 }).map((_, index) => (
            <Skeleton key={index} height="80px" borderRadius="md" />
          ))}
        </Grid>
      </Flex>
    </>
  );
}

export default TripDetailsSkeleton;
