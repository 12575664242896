import {
  AspectRatio,
  Box,
  Circle,
  HStack,
  IconButton,
  IconButtonProps,
  Image,
  Skeleton,
  Stack,
  StackProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { useState } from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Carousel, CarouselSlide, useCarousel } from './Carousel'
  
  interface GalleryProps {
    images: any
    aspectRatio?: number
    rootProps?: StackProps
  }
  
  export const Gallery = (props: GalleryProps) => {
    const { images, aspectRatio = 4 / 3, rootProps } = props
    const [currentSlide, setCurrentSlide] = useState(0)
  
    const [ref, slider] = useCarousel({
      slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    })
  
    const hasPrevious = currentSlide !== 0
    const hasNext = currentSlide < images.length - 1
  
    return (
      <Stack spacing="4" {...rootProps}>
        <Box
          position="relative"
          sx={{
            _hover: {
              '> button': {
                display: 'inline-flex',
              },
            },
          }}
        >
        <Carousel ref={ref}>
          {images.map((image, i) => (
            <CarouselSlide key={i}>
              <AspectRatio
                ratio={aspectRatio}
                transition="all 200ms"
                opacity={currentSlide === i ? 1 : 0.4}
                _hover={{ opacity: 1 }}
                backgroundColor={'#EEE'}
              >
                <Image 
                  src={image.src} 
                  objectFit="cover" 
                  alt={image.alt} 
                  fallback={<Skeleton />} 
                  loading={i === 0 ? "eager" : "lazy"} // Eager load the first image, lazy load the rest
                  onLoad={() => {/* handle the load event if necessary */}}
                />
              </AspectRatio>
            </CarouselSlide>
          ))}
        </Carousel>

          {hasPrevious && (
            <CarouselIconButton
              pos="absolute"
              left="3"
              top="50%"
              transform="translateY(-50%)"
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling up to parent elements
                slider.current?.prev();
              }}
              icon={<BsChevronLeft />}
              aria-label="Previous Slide"
            />
          )}
  
          {hasNext && (
            <CarouselIconButton
              pos="absolute"
              right="3"
              top="50%"
              transform="translateY(-50%)"
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling up to parent elements
                slider.current?.next();
              }}
              icon={<BsChevronRight />}
              aria-label="Next Slide"
            />
          )}
          <HStack position="absolute" width="full" justify="center" bottom="0" py="4">
            {images.length <= 5
                ? images.map((_, index) => (
                    <Circle key={index} size="2" bg={currentSlide === index ? 'white' : 'whiteAlpha.400'} />
                ))
                : [0, 1, 2, 3, 4].map(dotIndex => {
                    let isActive;
                    if (dotIndex === 0) {
                    isActive = currentSlide === 0; // First dot for the first image
                    } else if (dotIndex === 4) {
                    isActive = currentSlide === images.length - 1; // Last dot for the last image
                    } else if (dotIndex === 1) {
                    isActive = currentSlide === 1; // Second dot for the second image
                    } else if (dotIndex === 3) {
                    isActive = currentSlide === images.length - 2; // Fourth dot for the second-to-last image
                    } else {
                    isActive = currentSlide > 1 && currentSlide < images.length - 1; // Middle dot for the rest
                    }

                    return (
                    <Circle key={dotIndex} size="2" bg={isActive ? 'white' : 'whiteAlpha.400'} />
                    );
                })}
            </HStack>
        </Box>
      </Stack>
    )
  }
  
  const CarouselIconButton = (props: IconButtonProps) => (
    <IconButton
      display="none"
      fontSize="lg"
      isRound
      boxShadow="base"
      bg={useColorModeValue('white', 'gray.800')}
      _hover={{
        bg: useColorModeValue('gray.100', 'gray.700'),
      }}
      _active={{
        bg: useColorModeValue('gray.200', 'gray.600'),
      }}
      _focus={{ boxShadow: 'inerhit' }}
      _focusVisible={{ boxShadow: 'outline' }}
      onClick={(e) => {
        e.stopPropagation(); // This stops the click from propagating to parent elements
        if (props.onClick) {
          props.onClick(e); // If there is an onClick handler passed to the component, call it
        }
      }}
      {...props}
    />
  )