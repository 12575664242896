import { Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

const InvalidReservation = () => {

    const navigate = useNavigate();
    const returnToHome = async () => {
        navigate(`/`);
    }

    return (
        <Flex
            position={"relative"}
            width={"full"}
            data-id={"listing"}
            p={{ base: 2, md: 5 }}
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={'center'}
        >

            <Flex maxWidth='1440px' width={'100%'} p={2} textAlign={'left'} alignItems={'start'} flexDirection={'column'}>

                <Flex maxWidth='1440px' width={'100%'} p={2} textAlign={'left'} alignItems={'start'} flexDirection={'column'}>
                    <Text py={3}>The reservation you are looking for does not exist.</Text>
                    <Text py={3}>Please double check that you are using the link from your confirmation email from Del Mar Vacations, or reach out to Guest Services for further assistance.</Text>
                </Flex>

                <Button h={"50px"} mt={6} mb={6} onClick={returnToHome} colorScheme="dmOrange">
                    Return to Home
                </Button>

            </Flex>
        </Flex>
    )
}

export { InvalidReservation };

